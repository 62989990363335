import { Injectable } from '@angular/core';
import {TourService} from 'ngx-ui-tour-md-menu'
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class TourConfigService {

  constructor(private tourService:TourService,private _projectService:ProjectService) { }
  getTourData(isNotAdmin){
      if(isNotAdmin){
        this.tourService.initialize([
      
            {
              anchorId: 'HeaderSearch',
              content: 'This search bar lets you easily search for information about employees, teams, and settings all in one place.',
              title: 'Search anything',
              route:'/dashboard'
            },
            {
              anchorId: 'AdminProfile',
              content: 'You can go to the admin profile from here and adjust the settings.',
              title: 'Admin Profile',
              route:'/dashboard'
            },
            {
              anchorId: 'DashboardInforMativeCard',
              content: 'This is a brief section where you can find basic information about all employees, teams, and users, both online and offline.',
              title: 'Informative cards',
              route:'/dashboard'
            },
            {
              anchorId: 'mostUsedAppDashboard',
              content: 'This chart helps you find which apps your employees use the most based on hours spent.',
              title: 'Most used application chart',
              route:'/dashboard'
            },
            {
              anchorId: 'TimelineDashboard',
              content: 'Here, you can find out the work timeline of your employees and know when they have been actively working, were idle, or the system is closed.',
              title: "Employee's timeline",
              route:'/dashboard'
            },
            {
              anchorId: 'ScreenShotDashboard',
              content: "You can see screenshots that show the changes in a user's activity, including when they switch between applications, have idle periods, or admin requests screenshots.",
              title: "Employee's screenshots",
              route:'/dashboard',
              // disablePageScrolling: false
            },
            {
              anchorId: 'requestEmployeeActions',
              content: "This Section helps you with screenshot, screencast and setting options.",
              title: "Actions",
              route:'/employees'
            },
            // {
            //   anchorId: 'addNewTeam',
            //   content: "You can create new teams based with the help of this button.",
            //   title: "Add teams for projects",
            //   route:'/teams'
            // },
            {
              anchorId: 'ListOfTeam',
              content: " You can see all the teams you've created, see how many members each team has and choose to edit any specific team.",
              title: "List of all teams",
              route:'/teams'
            },
            {
              anchorId: 'ListOfEmployeeOfTeam',
              content: "This quick team preview helps you see what the employees are up to, and choose to request a screenshot or screencast right here.",
              title: "List of team employee",
              route:'/teams'
            },
          ],{
              enableBackdrop: true,
              disablePageScrolling: true
          });
              }else{
                this.tourService.initialize([
      
                    {
                      anchorId: 'HeaderSearch',
                      content: 'This search bar lets you easily search for information about employees, teams, and settings all in one place.',
                      title: 'Search anything',
                      route:'/dashboard'
                    },
                    {
                      anchorId: 'AdminProfile',
                      content: 'You can go to the admin profile from here and adjust the settings.',
                      title: 'Admin Profile',
                      route:'/dashboard'
                    },
                    {
                      anchorId: 'DashboardInforMativeCard',
                      content: 'This is a brief section where you can find basic information about all employees, teams, and users, both online and offline.',
                      title: 'Informative cards',
                      route:'/dashboard'
                    },
                    {
                      anchorId: 'mostUsedAppDashboard',
                      content: 'This chart helps you find which apps your employees use the most based on hours spent.',
                      title: 'Most used application chart',
                      route:'/dashboard'
                    },
                    {
                      anchorId: 'TimelineDashboard',
                      content: 'Here, you can find out the work timeline of your employees and know when they have been actively working, were idle, or the system is closed.',
                      title: "Employee's timeline",
                      route:'/dashboard'
                    },
                    {
                      anchorId: 'ScreenShotDashboard',
                      content: "You can see screenshots that show the changes in a user's activity, including when they switch between applications, have idle periods, or admin requests screenshots.",
                      title: "Employee's screenshots",
                      route:'/dashboard',
                      // disablePageScrolling: false
                    },
                    {
                      anchorId: 'addNewEmployee',
                      content: "You can add new employee by entering their details using this interface.",
                      title: "Add new employee",
                      route:'/employees'
                    },
                    {
                      anchorId: 'requestEmployeeActions',
                      content: "This Section helps you with screenshot, screencast and setting options.",
                      title: "Actions",
                      route:'/employees'
                    },
                    {
                      anchorId: 'addNewTeam',
                      content: "You can create new teams based with the help of this button.",
                      title: "Add teams for projects",
                      route:'/teams'
                    },
                    {
                      anchorId: 'ListOfTeam',
                      content: " You can see all the teams you've created, see how many members each team has and choose to edit any specific team.",
                      title: "List of all teams",
                      route:'/teams'
                    },
                    {
                      anchorId: 'ListOfEmployeeOfTeam',
                      content: "This quick team preview helps you see what the employees are up to, and choose to request a screenshot or screencast right here.",
                      title: "List of team employee",
                      route:'/teams'
                    },
                    {
                      anchorId: 'CustomizeOptionSetting',
                      content: "You can set up your preferences to enable or disable - screen capture, app change screenshots and webcam pictures.",
                      title: "Application settings",
                      route:'/employee/setting'
                    },
                    {
                      anchorId: 'CustomizeSettingConfiguration',
                      content: "Change the system configuration according to your needs which you can change in minutes.",
                      title: "Interval settings",
                      route:'/employee/setting'
                    },
                    {
                        anchorId: 'CustomizeSettingLinks',
                        content: "If you want to see information about updates and screen captures on your communication app, you can put the webhook link of that app and take it directly.",
                        title: "Discord hooks",
                        route:'/employee/setting'
                      }
                  ],{
                      enableBackdrop: true,
                  });
                      }
  }

  getApplicationUsageJsonData(){
  return   [
      {
          "appName": "Google Chrome",
          "usageTime": "838:59:59",
          "productive_time": "648:34:56",
          "idleTime": "210:03:58"
      },
      {
          "appName": "Visual Studio Code",
          "usageTime": "246:55:38",
          "productive_time": "203:15:45",
          "idleTime": "43:39:53"
      },
      {
          "appName": "Google-chrome",
          "usageTime": "165:54:07",
          "productive_time": "101:54:59",
          "idleTime": "63:59:08"
      },
      {
          "appName": "Code",
          "usageTime": "105:17:45",
          "productive_time": "45:13:20",
          "idleTime": "60:04:25"
      },
      {
          "appName": "Gnome-control-center",
          "usageTime": "103:45:31",
          "productive_time": "103:42:31",
          "idleTime": "00:03:00"
      },
      {
          "appName": "Supersee",
          "usageTime": "70:02:14",
          "productive_time": "-66:57:49",
          "idleTime": "137:00:03"
      },
      {
          "appName": "Discord",
          "usageTime": "51:28:04",
          "productive_time": "30:49:14",
          "idleTime": "20:38:50"
      },
      {
          "appName": "Chromium",
          "usageTime": "45:52:25",
          "productive_time": "28:29:11",
          "idleTime": "17:23:14"
      },
      {
          "appName": "Windows Explorer",
          "usageTime": "42:47:42",
          "productive_time": "16:53:29",
          "idleTime": "25:54:13"
      },
      {
          "appName": "Settings",
          "usageTime": "36:09:45",
          "productive_time": "36:09:45",
          "idleTime": "00:00:00"
      }
  ]
  }

  getTimelineJsonData(){
    return {
      "User1": {
          "sessions": [
              {
                  "appName": "Microsoft Teams",
                  "windowName": "Microsoft Teams",
                  "in": "2023-12-21 10:43:07",
                  "out": "2023-12-21 10:43:16",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Microsoft Teams",
                  "windowName": "Microsoft Teams",
                  "in": "2023-12-21 10:43:16",
                  "out": "2023-12-21 10:43:47",
                  "usage": "00:00:31",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Rao Helper Bot",
                  "in": "2023-12-21 10:43:47",
                  "out": "2023-12-21 10:48:57",
                  "usage": "00:03:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 10:48:57",
                  "out": "2023-12-21 10:49:57",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:49:57",
                  "out": "2023-12-21 10:50:37",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 10:50:37",
                  "out": "2023-12-21 10:54:47",
                  "usage": "00:02:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 10:54:47",
                  "out": "2023-12-21 10:55:27",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "YouTube",
                  "in": "2023-12-21 10:55:27",
                  "out": "2023-12-21 11:00:07",
                  "usage": "00:04:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:00:07",
                  "out": "2023-12-21 11:00:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Access Denied",
                  "in": "2023-12-21 11:00:17",
                  "out": "2023-12-21 11:01:17",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:01:17",
                  "out": "2023-12-21 11:03:47",
                  "usage": "00:00:30",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:03:47",
                  "out": "2023-12-21 11:07:37",
                  "usage": "00:03:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Log In ‹ Super See — WordPress",
                  "in": "2023-12-21 11:07:37",
                  "out": "2023-12-21 11:07:57",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:07:57",
                  "out": "2023-12-21 11:10:37",
                  "usage": "00:02:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Dashboard ‹ Super See — WordPress",
                  "in": "2023-12-21 11:10:37",
                  "out": "2023-12-21 11:13:07",
                  "usage": "00:00:30",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Super See — WordPress",
                  "in": "2023-12-21 11:13:07",
                  "out": "2023-12-21 11:15:07",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Super See — WordPress",
                  "in": "2023-12-21 11:15:07",
                  "out": "2023-12-21 11:16:57",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:16:57",
                  "out": "2023-12-21 11:17:17",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Super See — WordPress",
                  "in": "2023-12-21 11:17:17",
                  "out": "2023-12-21 11:17:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Downloads",
                  "in": "2023-12-21 11:17:37",
                  "out": "2023-12-21 11:18:07",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "os (1).js",
                  "in": "2023-12-21 11:18:07",
                  "out": "2023-12-21 11:18:57",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Progress",
                  "in": "2023-12-21 11:18:57",
                  "out": "2023-12-21 11:19:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Open",
                  "in": "2023-12-21 11:19:07",
                  "out": "2023-12-21 11:26:07",
                  "usage": "00:07:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:26:07",
                  "out": "2023-12-21 11:26:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Productivity Reports Analysis using Productivity Tracking Software",
                  "in": "2023-12-21 11:26:17",
                  "out": "2023-12-21 11:26:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:26:37",
                  "out": "2023-12-21 11:30:47",
                  "usage": "00:02:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:30:47",
                  "out": "2023-12-21 11:31:17",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Productivity Reports Analysis using Productivity Tracking Software",
                  "in": "2023-12-21 11:31:17",
                  "out": "2023-12-21 11:31:47",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:31:47",
                  "out": "2023-12-21 11:33:47",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:33:47",
                  "out": "2023-12-21 11:39:47",
                  "usage": "00:06:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Copy of Maintenance Website List Check points",
                  "in": "2023-12-21 11:39:47",
                  "out": "2023-12-21 11:41:57",
                  "usage": "00:00:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(Project 5) JavaScript Tutorial in Hindi for Beginners [Part 75]",
                  "in": "2023-12-21 11:41:57",
                  "out": "2023-12-21 11:43:47",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "\"Wp-permissions\" | Rao Information Technology",
                  "in": "2023-12-21 11:43:47",
                  "out": "2023-12-21 11:44:27",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(Project 5) JavaScript Tutorial in Hindi for Beginners [Part 75]",
                  "in": "2023-12-21 11:44:27",
                  "out": "2023-12-21 11:47:07",
                  "usage": "00:02:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "\"Wp-permissions\" | Rao Information Technology",
                  "in": "2023-12-21 11:47:07",
                  "out": "2023-12-21 11:47:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Untitled",
                  "in": "2023-12-21 11:47:17",
                  "out": "2023-12-21 11:47:47",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 11:47:47",
                  "out": "2023-12-21 11:47:57",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Log In ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 11:47:57",
                  "out": "2023-12-21 11:50:27",
                  "usage": "00:00:30",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Dashboard ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 11:50:27",
                  "out": "2023-12-21 11:52:27",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Dashboard ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 11:52:27",
                  "out": "2023-12-21 12:01:57",
                  "usage": "00:09:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 12:01:57",
                  "out": "2023-12-21 12:02:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Log In ‹ Super See — WordPress",
                  "in": "2023-12-21 12:02:07",
                  "out": "2023-12-21 12:02:37",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "Open File",
                  "in": "2023-12-21 12:02:37",
                  "out": "2023-12-21 12:06:37",
                  "usage": "00:04:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder | Free Attendance tool for Roll Call",
                  "in": "2023-12-21 12:06:37",
                  "out": "2023-12-21 12:18:57",
                  "usage": "00:10:20",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attendiligence ( Attendance + Intelligence ) | AI Solutions",
                  "in": "2023-12-21 12:18:57",
                  "out": "2023-12-21 12:20:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attendiligence ( Attendance + Intelligence ) | AI Solutions",
                  "in": "2023-12-21 12:20:57",
                  "out": "2023-12-21 12:22:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attendiligence ( Attendance + Intelligence ) | AI Solutions",
                  "in": "2023-12-21 12:22:57",
                  "out": "2023-12-21 12:33:37",
                  "usage": "00:10:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 12:33:37",
                  "out": "2023-12-21 12:35:57",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Email Log ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:35:57",
                  "out": "2023-12-21 12:37:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Email Log ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:37:57",
                  "out": "2023-12-21 12:39:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Email Log ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:39:57",
                  "out": "2023-12-21 12:41:37",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 12:41:37",
                  "out": "2023-12-21 12:43:37",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Email Log ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:43:37",
                  "out": "2023-12-21 12:45:47",
                  "usage": "00:02:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Pooja Mehta",
                  "in": "2023-12-21 12:45:47",
                  "out": "2023-12-21 12:50:37",
                  "usage": "00:04:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Email Log ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:50:37",
                  "out": "2023-12-21 12:56:47",
                  "usage": "00:04:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Themes ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:56:47",
                  "out": "2023-12-21 12:58:47",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Themes ‹ Attendiligence — WordPress",
                  "in": "2023-12-21 12:58:47",
                  "out": "2023-12-21 13:10:37",
                  "usage": "00:11:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Pooja Mehta",
                  "in": "2023-12-21 13:10:37",
                  "out": "2023-12-21 13:17:57",
                  "usage": "00:05:20",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:17:57",
                  "out": "2023-12-21 13:20:57",
                  "usage": "00:01:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:20:57",
                  "out": "2023-12-21 13:22:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:22:57",
                  "out": "2023-12-21 13:24:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:24:57",
                  "out": "2023-12-21 13:26:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:26:57",
                  "out": "2023-12-21 13:28:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:28:57",
                  "out": "2023-12-21 13:30:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:30:57",
                  "out": "2023-12-21 13:32:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:32:57",
                  "out": "2023-12-21 13:34:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:34:57",
                  "out": "2023-12-21 13:36:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:36:57",
                  "out": "2023-12-21 13:38:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:38:57",
                  "out": "2023-12-21 13:40:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:40:57",
                  "out": "2023-12-21 13:42:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:42:57",
                  "out": "2023-12-21 13:44:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:44:57",
                  "out": "2023-12-21 13:46:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:46:57",
                  "out": "2023-12-21 13:48:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:48:57",
                  "out": "2023-12-21 13:50:28",
                  "usage": "00:01:31",
                  "idle": "00:00:00"
              },
              {
                  "appName": "LockApp.exe",
                  "windowName": "Windows Default Lock Screen",
                  "in": "2023-12-21 13:51:36",
                  "out": "2023-12-21 13:52:06",
                  "usage": "00:00:00",
                  "idle": "00:00:30"
              },
              {
                  "appName": "LockApp.exe",
                  "windowName": "Windows Default Lock Screen",
                  "in": "2023-12-21 13:52:06",
                  "out": "2023-12-21 13:52:56",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:52:56",
                  "out": "2023-12-21 13:55:46",
                  "usage": "00:02:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Log In ‹ Attinder — WordPress",
                  "in": "2023-12-21 13:55:46",
                  "out": "2023-12-21 13:57:56",
                  "usage": "00:02:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 13:57:56",
                  "out": "2023-12-21 13:58:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 13:58:06",
                  "out": "2023-12-21 13:58:26",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 13:58:26",
                  "out": "2023-12-21 14:04:06",
                  "usage": "00:05:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 14:04:06",
                  "out": "2023-12-21 14:04:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Attinder — WordPress",
                  "in": "2023-12-21 14:04:16",
                  "out": "2023-12-21 14:10:16",
                  "usage": "00:06:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 14:10:16",
                  "out": "2023-12-21 14:12:26",
                  "usage": "00:00:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 14:12:26",
                  "out": "2023-12-21 14:14:26",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 14:14:26",
                  "out": "2023-12-21 14:16:26",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 14:16:26",
                  "out": "2023-12-21 14:17:26",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 14:17:26",
                  "out": "2023-12-21 14:29:36",
                  "usage": "00:10:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 14:29:36",
                  "out": "2023-12-21 14:30:06",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 14:30:06",
                  "out": "2023-12-21 14:35:16",
                  "usage": "00:03:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Rao Helper Bot",
                  "in": "2023-12-21 14:35:16",
                  "out": "2023-12-21 14:38:16",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Log In ‹ Pharmboost — WordPress",
                  "in": "2023-12-21 14:38:16",
                  "out": "2023-12-21 14:53:36",
                  "usage": "00:13:20",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "view-source:https://pharmboost.com",
                  "in": "2023-12-21 14:53:36",
                  "out": "2023-12-21 14:58:56",
                  "usage": "00:03:20",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 14:58:56",
                  "out": "2023-12-21 15:04:57",
                  "usage": "00:04:01",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "view-source:https://pharmboost.com",
                  "in": "2023-12-21 15:04:57",
                  "out": "2023-12-21 15:06:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "view-source:https://pharmboost.com",
                  "in": "2023-12-21 15:06:57",
                  "out": "2023-12-21 15:08:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "view-source:https://pharmboost.com",
                  "in": "2023-12-21 15:08:57",
                  "out": "2023-12-21 15:10:57",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "view-source:https://pharmboost.com",
                  "in": "2023-12-21 15:10:57",
                  "out": "2023-12-21 15:24:37",
                  "usage": "00:13:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 15:24:37",
                  "out": "2023-12-21 15:26:47",
                  "usage": "00:00:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 15:26:47",
                  "out": "2023-12-21 15:28:47",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 15:28:47",
                  "out": "2023-12-21 15:29:17",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 15:29:17",
                  "out": "2023-12-21 15:29:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 15:29:27",
                  "out": "2023-12-21 15:48:37",
                  "usage": "00:19:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 15:48:37",
                  "out": "2023-12-21 15:50:57",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 15:50:57",
                  "out": "2023-12-21 15:53:17",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 15:53:17",
                  "out": "2023-12-21 15:53:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 15:53:37",
                  "out": "2023-12-21 15:55:07",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 15:55:07",
                  "out": "2023-12-21 15:55:27",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Pharmboost — WordPress",
                  "in": "2023-12-21 15:55:27",
                  "out": "2023-12-21 15:58:07",
                  "usage": "00:00:40",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Patient Engagement Solution",
                  "in": "2023-12-21 15:58:07",
                  "out": "2023-12-21 15:58:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 15:58:17",
                  "out": "2023-12-21 15:58:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Patient Engagement Solution",
                  "in": "2023-12-21 15:58:27",
                  "out": "2023-12-21 16:08:37",
                  "usage": "00:08:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 16:08:37",
                  "out": "2023-12-21 16:10:37",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 16:10:37",
                  "out": "2023-12-21 16:18:17",
                  "usage": "00:05:40",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 16:18:17",
                  "out": "2023-12-21 16:23:57",
                  "usage": "00:03:40",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 16:23:57",
                  "out": "2023-12-21 16:33:27",
                  "usage": "00:07:30",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 16:33:27",
                  "out": "2023-12-21 16:39:47",
                  "usage": "00:04:20",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Plugins ‹ Attinder — WordPress",
                  "in": "2023-12-21 16:39:47",
                  "out": "2023-12-21 16:41:47",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Plugins ‹ Attinder — WordPress",
                  "in": "2023-12-21 16:41:47",
                  "out": "2023-12-21 16:50:27",
                  "usage": "00:06:40",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 16:50:27",
                  "out": "2023-12-21 16:52:27",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 16:52:27",
                  "out": "2023-12-21 16:54:27",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 16:54:27",
                  "out": "2023-12-21 17:00:17",
                  "usage": "00:03:50",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Contact Form ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:00:17",
                  "out": "2023-12-21 17:02:17",
                  "usage": "00:00:00",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Contact Form ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:02:17",
                  "out": "2023-12-21 17:05:27",
                  "usage": "00:01:10",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Contact Form ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:05:27",
                  "out": "2023-12-21 17:13:17",
                  "usage": "00:07:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:13:17",
                  "out": "2023-12-21 17:15:07",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:15:07",
                  "out": "2023-12-21 17:19:57",
                  "usage": "00:04:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:19:57",
                  "out": "2023-12-21 17:21:07",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:21:07",
                  "out": "2023-12-21 17:22:07",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:22:07",
                  "out": "2023-12-21 17:22:27",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 17:22:27",
                  "out": "2023-12-21 17:25:07",
                  "usage": "00:00:40",
                  "idle": "00:02:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 17:25:07",
                  "out": "2023-12-21 17:25:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:25:17",
                  "out": "2023-12-21 17:27:27",
                  "usage": "00:02:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 17:27:27",
                  "out": "2023-12-21 17:28:07",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:28:07",
                  "out": "2023-12-21 17:28:27",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Attinder",
                  "in": "2023-12-21 17:28:27",
                  "out": "2023-12-21 17:29:47",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:29:47",
                  "out": "2023-12-21 17:32:57",
                  "usage": "00:03:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:32:57",
                  "out": "2023-12-21 17:36:47",
                  "usage": "00:03:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:36:47",
                  "out": "2023-12-21 17:37:07",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:37:07",
                  "out": "2023-12-21 17:37:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 17:37:17",
                  "out": "2023-12-21 17:37:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:37:27",
                  "out": "2023-12-21 17:37:37",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Page “Attinder” ‹ Attinder — WordPress",
                  "in": "2023-12-21 17:37:37",
                  "out": "2023-12-21 17:38:07",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 17:38:07",
                  "out": "2023-12-21 17:38:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 17:38:17",
                  "out": "2023-12-21 17:38:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@himani",
                  "in": "2023-12-21 17:38:27",
                  "out": "2023-12-21 17:39:07",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              }
          ],
          "name": "User1"
      },
      "User2": {
          "sessions": [
              {
                  "appName": "LockApp.exe",
                  "windowName": "Windows Default Lock Screen",
                  "in": "2023-12-21 10:35:43",
                  "out": "2023-12-21 10:35:53",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "LockApp.exe",
                  "windowName": "Windows Default Lock Screen",
                  "in": "2023-12-21 10:35:53",
                  "out": "2023-12-21 10:38:23",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:38:23",
                  "out": "2023-12-21 10:40:03",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 10:40:03",
                  "out": "2023-12-21 10:40:43",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Google Chrome",
                  "in": "2023-12-21 10:40:43",
                  "out": "2023-12-21 10:40:53",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "xampp-control.exe",
                  "windowName": "XAMPP Control Panel v3.3.0   [ Compiled: Apr 6th 2021 ]",
                  "in": "2023-12-21 10:40:53",
                  "out": "2023-12-21 10:41:03",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:41:03",
                  "out": "2023-12-21 10:41:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Local Disk (C:)",
                  "in": "2023-12-21 10:41:13",
                  "out": "2023-12-21 10:41:33",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:41:33",
                  "out": "2023-12-21 10:42:49",
                  "usage": "00:01:16",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "404 Not Found",
                  "in": "2023-12-21 10:43:04",
                  "out": "2023-12-21 10:43:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "xampp-control.exe",
                  "windowName": "XAMPP Control Panel v3.3.0   [ Compiled: Apr 6th 2021 ]",
                  "in": "2023-12-21 10:43:14",
                  "out": "2023-12-21 10:43:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Program Manager",
                  "in": "2023-12-21 10:43:24",
                  "out": "2023-12-21 10:43:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "404 Not Found",
                  "in": "2023-12-21 10:43:34",
                  "out": "2023-12-21 10:43:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "htdocs",
                  "in": "2023-12-21 10:43:44",
                  "out": "2023-12-21 10:43:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Google Chrome",
                  "in": "2023-12-21 10:43:54",
                  "out": "2023-12-21 10:45:04",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "xampp-control.exe",
                  "windowName": "XAMPP Control Panel v3.3.0   [ Compiled: Apr 6th 2021 ]",
                  "in": "2023-12-21 10:45:04",
                  "out": "2023-12-21 10:45:24",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 10:45:24",
                  "out": "2023-12-21 11:07:25",
                  "usage": "00:22:01",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "xampp",
                  "in": "2023-12-21 11:07:25",
                  "out": "2023-12-21 11:07:55",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "Visual Studio Code",
                  "in": "2023-12-21 11:07:55",
                  "out": "2023-12-21 11:08:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Child Themes – Theme Handbook | Developer.WordPress.org",
                  "in": "2023-12-21 11:08:15",
                  "out": "2023-12-21 11:08:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:08:25",
                  "out": "2023-12-21 11:08:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Child Themes – Theme Handbook | Developer.WordPress.org",
                  "in": "2023-12-21 11:08:35",
                  "out": "2023-12-21 11:09:55",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:09:55",
                  "out": "2023-12-21 11:10:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Child Themes – Theme Handbook | Developer.WordPress.org",
                  "in": "2023-12-21 11:10:05",
                  "out": "2023-12-21 11:20:56",
                  "usage": "00:07:51",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:20:56",
                  "out": "2023-12-21 11:26:46",
                  "usage": "00:05:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 11:26:46",
                  "out": "2023-12-21 11:27:06",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:27:06",
                  "out": "2023-12-21 11:27:26",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "betheme-child",
                  "in": "2023-12-21 11:27:26",
                  "out": "2023-12-21 11:28:06",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:28:06",
                  "out": "2023-12-21 11:28:26",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:28:26",
                  "out": "2023-12-21 11:28:56",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:28:56",
                  "out": "2023-12-21 11:29:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:29:06",
                  "out": "2023-12-21 11:29:36",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:29:36",
                  "out": "2023-12-21 11:29:46",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:29:46",
                  "out": "2023-12-21 11:29:56",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● style.css",
                  "in": "2023-12-21 11:29:56",
                  "out": "2023-12-21 11:30:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:30:06",
                  "out": "2023-12-21 11:30:26",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● style.css",
                  "in": "2023-12-21 11:30:26",
                  "out": "2023-12-21 11:30:36",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:30:36",
                  "out": "2023-12-21 11:30:56",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:30:56",
                  "out": "2023-12-21 11:31:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● style.css",
                  "in": "2023-12-21 11:31:06",
                  "out": "2023-12-21 11:31:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Posts ‹ superseeblog — WordPress",
                  "in": "2023-12-21 11:31:16",
                  "out": "2023-12-21 11:32:06",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:32:06",
                  "out": "2023-12-21 11:32:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 11:32:16",
                  "out": "2023-12-21 11:32:26",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Themes ‹ superseeblog — WordPress",
                  "in": "2023-12-21 11:32:26",
                  "out": "2023-12-21 11:34:16",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:34:16",
                  "out": "2023-12-21 11:35:06",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:35:06",
                  "out": "2023-12-21 11:35:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "betheme",
                  "in": "2023-12-21 11:35:16",
                  "out": "2023-12-21 11:35:36",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:35:36",
                  "out": "2023-12-21 11:36:56",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:36:56",
                  "out": "2023-12-21 11:37:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 11:37:06",
                  "out": "2023-12-21 11:38:06",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:38:06",
                  "out": "2023-12-21 11:43:37",
                  "usage": "00:05:31",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:43:37",
                  "out": "2023-12-21 11:44:17",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:44:17",
                  "out": "2023-12-21 11:44:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:44:27",
                  "out": "2023-12-21 11:45:07",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:45:07",
                  "out": "2023-12-21 11:45:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:45:17",
                  "out": "2023-12-21 11:45:47",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:45:47",
                  "out": "2023-12-21 11:45:57",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:45:57",
                  "out": "2023-12-21 11:46:57",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:46:57",
                  "out": "2023-12-21 11:47:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:47:07",
                  "out": "2023-12-21 11:47:47",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:47:47",
                  "out": "2023-12-21 11:48:07",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:48:07",
                  "out": "2023-12-21 11:48:27",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:48:27",
                  "out": "2023-12-21 11:48:57",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:48:57",
                  "out": "2023-12-21 11:49:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:49:07",
                  "out": "2023-12-21 11:49:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:49:17",
                  "out": "2023-12-21 11:49:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:49:37",
                  "out": "2023-12-21 11:49:57",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "betheme",
                  "in": "2023-12-21 11:49:57",
                  "out": "2023-12-21 11:50:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:50:07",
                  "out": "2023-12-21 11:50:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:50:17",
                  "out": "2023-12-21 11:51:27",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:51:27",
                  "out": "2023-12-21 11:51:37",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Child Themes – Theme Handbook | Developer.WordPress.org",
                  "in": "2023-12-21 11:51:37",
                  "out": "2023-12-21 11:51:47",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:51:47",
                  "out": "2023-12-21 11:51:57",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:51:57",
                  "out": "2023-12-21 11:52:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 11:52:07",
                  "out": "2023-12-21 11:52:37",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:52:37",
                  "out": "2023-12-21 11:52:47",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:52:47",
                  "out": "2023-12-21 11:53:27",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:53:27",
                  "out": "2023-12-21 11:53:47",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "How to Fix the \"Sorry, You're Not Allowed to Preview Drafts\" Error » Rank Math",
                  "in": "2023-12-21 11:53:47",
                  "out": "2023-12-21 11:54:07",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 11:54:07",
                  "out": "2023-12-21 11:54:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:54:17",
                  "out": "2023-12-21 11:54:27",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 11:54:27",
                  "out": "2023-12-21 11:54:57",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:54:57",
                  "out": "2023-12-21 11:55:17",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "themes",
                  "in": "2023-12-21 11:55:17",
                  "out": "2023-12-21 11:55:47",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 11:55:47",
                  "out": "2023-12-21 11:58:37",
                  "usage": "00:02:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:58:37",
                  "out": "2023-12-21 11:59:07",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:59:07",
                  "out": "2023-12-21 11:59:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:59:17",
                  "out": "2023-12-21 11:59:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(88) How To Create A Child Theme For WordPress in Simple Steps | Hindi\\Urdu",
                  "in": "2023-12-21 11:59:37",
                  "out": "2023-12-21 11:59:47",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● functions.php",
                  "in": "2023-12-21 11:59:47",
                  "out": "2023-12-21 12:00:07",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 12:00:07",
                  "out": "2023-12-21 12:00:17",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "betheme-child",
                  "in": "2023-12-21 12:00:17",
                  "out": "2023-12-21 12:00:37",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 12:00:37",
                  "out": "2023-12-21 12:00:57",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 12:00:57",
                  "out": "2023-12-21 12:01:27",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 12:01:27",
                  "out": "2023-12-21 12:01:37",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 12:01:37",
                  "out": "2023-12-21 12:03:17",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 12:03:17",
                  "out": "2023-12-21 12:04:58",
                  "usage": "00:01:41",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WordPress › Error",
                  "in": "2023-12-21 12:04:58",
                  "out": "2023-12-21 12:06:38",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 12:06:38",
                  "out": "2023-12-21 12:06:48",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Program Manager",
                  "in": "2023-12-21 12:19:48",
                  "out": "2023-12-21 12:20:08",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Restore pages?",
                  "in": "2023-12-21 12:20:08",
                  "out": "2023-12-21 12:25:48",
                  "usage": "00:05:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 12:25:48",
                  "out": "2023-12-21 12:25:58",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Post “Blog” ‹ superseeblog — WordPress",
                  "in": "2023-12-21 12:25:58",
                  "out": "2023-12-21 13:27:40",
                  "usage": "00:58:42",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 13:27:40",
                  "out": "2023-12-21 13:30:40",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 13:30:40",
                  "out": "2023-12-21 13:33:40",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 13:33:40",
                  "out": "2023-12-21 13:36:40",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 13:36:40",
                  "out": "2023-12-21 13:39:40",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 13:39:40",
                  "out": "2023-12-21 13:45:30",
                  "usage": "00:02:50",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "(1) Discord | @himani",
                  "in": "2023-12-21 13:45:30",
                  "out": "2023-12-21 15:08:03",
                  "usage": "01:22:33",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Home",
                  "in": "2023-12-21 15:08:03",
                  "out": "2023-12-21 15:08:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "functions.php",
                  "in": "2023-12-21 15:08:13",
                  "out": "2023-12-21 15:09:23",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 15:09:23",
                  "out": "2023-12-21 15:09:33",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Implementing Productivity Monitoring: Do's & Don'ts",
                  "in": "2023-12-21 15:09:33",
                  "out": "2023-12-21 15:12:13",
                  "usage": "00:02:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 15:12:13",
                  "out": "2023-12-21 15:12:33",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Post “Blog” ‹ superseeblog — WordPress",
                  "in": "2023-12-21 15:12:33",
                  "out": "2023-12-21 15:13:43",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 15:13:43",
                  "out": "2023-12-21 15:13:53",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Blog – superseeblog",
                  "in": "2023-12-21 15:13:53",
                  "out": "2023-12-21 16:00:03",
                  "usage": "00:46:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "SnippingTool.exe",
                  "windowName": "Snipping Tool Overlay",
                  "in": "2023-12-21 16:00:03",
                  "out": "2023-12-21 16:00:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Rao Conduct (Project Management Tool)",
                  "in": "2023-12-21 16:00:13",
                  "out": "2023-12-21 16:36:24",
                  "usage": "00:36:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 16:36:24",
                  "out": "2023-12-21 16:36:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Post “Implementing Productivity Monitoring: Do’s & Don’ts Draft” ‹ Super See — WordPress",
                  "in": "2023-12-21 16:36:34",
                  "out": "2023-12-21 16:37:44",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "xampp-control.exe",
                  "windowName": "XAMPP Control Panel v3.3.0   [ Compiled: Apr 6th 2021 ]",
                  "in": "2023-12-21 16:37:44",
                  "out": "2023-12-21 16:37:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "htdocs",
                  "in": "2023-12-21 16:37:54",
                  "out": "2023-12-21 16:38:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Posts ‹ Super See — WordPress",
                  "in": "2023-12-21 16:38:04",
                  "out": "2023-12-21 17:04:14",
                  "usage": "00:26:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "SnippingTool.exe",
                  "windowName": "Snipping Tool Overlay",
                  "in": "2023-12-21 17:04:14",
                  "out": "2023-12-21 17:04:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "New notification",
                  "in": "2023-12-21 17:04:24",
                  "out": "2023-12-21 17:04:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "SnippingTool.exe",
                  "windowName": "Snipping Tool",
                  "in": "2023-12-21 17:04:34",
                  "out": "2023-12-21 17:04:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Super See — WordPress",
                  "in": "2023-12-21 17:04:44",
                  "out": "2023-12-21 17:16:24",
                  "usage": "00:11:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "style.css",
                  "in": "2023-12-21 17:16:24",
                  "out": "2023-12-21 17:17:54",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WP File Manager ‹ Super See — WordPress",
                  "in": "2023-12-21 17:17:54",
                  "out": "2023-12-21 17:28:45",
                  "usage": "00:10:51",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "Open File",
                  "in": "2023-12-21 17:28:45",
                  "out": "2023-12-21 17:29:05",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Implementing Productivity Monitoring: Do's & Don'ts Draft",
                  "in": "2023-12-21 17:29:05",
                  "out": "2023-12-21 17:37:15",
                  "usage": "00:08:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Visual Studio Code",
                  "windowName": "● style (1).css",
                  "in": "2023-12-21 17:37:15",
                  "out": "2023-12-21 17:37:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              }
          ],
          "name": "User2"
      },
      "User3": {
          "sessions": [
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #supersee | Rao Information Technology",
                  "in": "2023-12-21 10:40:15",
                  "out": "2023-12-21 10:41:05",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~",
                  "in": "2023-12-21 10:41:05",
                  "out": "2023-12-21 10:42:05",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:42:05",
                  "out": "2023-12-21 10:42:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:42:15",
                  "out": "2023-12-21 10:42:45",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 10:42:45",
                  "out": "2023-12-21 10:43:45",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:43:45",
                  "out": "2023-12-21 10:43:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Install and Use GNOME Tweak Tool in Ubuntu and Other Linux",
                  "in": "2023-12-21 10:43:55",
                  "out": "2023-12-21 10:44:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~",
                  "in": "2023-12-21 10:44:15",
                  "out": "2023-12-21 10:45:05",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "load_milvus.py",
                  "in": "2023-12-21 10:45:05",
                  "out": "2023-12-21 10:45:55",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Home",
                  "in": "2023-12-21 10:45:55",
                  "out": "2023-12-21 10:46:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:46:15",
                  "out": "2023-12-21 10:46:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 10:46:25",
                  "out": "2023-12-21 10:46:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 10:46:35",
                  "out": "2023-12-21 10:47:25",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 10:47:25",
                  "out": "2023-12-21 10:47:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:47:45",
                  "out": "2023-12-21 10:48:55",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:48:55",
                  "out": "2023-12-21 10:49:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 10:49:05",
                  "out": "2023-12-21 10:49:55",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:49:55",
                  "out": "2023-12-21 10:50:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:50:05",
                  "out": "2023-12-21 10:50:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 10:50:15",
                  "out": "2023-12-21 10:50:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 10:50:25",
                  "out": "2023-12-21 10:51:15",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:51:15",
                  "out": "2023-12-21 10:51:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:51:25",
                  "out": "2023-12-21 10:51:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:51:35",
                  "out": "2023-12-21 10:51:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 10:51:45",
                  "out": "2023-12-21 10:52:35",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 10:52:35",
                  "out": "2023-12-21 10:53:45",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~",
                  "in": "2023-12-21 10:53:45",
                  "out": "2023-12-21 10:56:15",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:56:15",
                  "out": "2023-12-21 10:56:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:56:25",
                  "out": "2023-12-21 10:57:55",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 10:57:55",
                  "out": "2023-12-21 10:58:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:58:05",
                  "out": "2023-12-21 10:58:35",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "Supersee",
                  "in": "2023-12-21 10:58:35",
                  "out": "2023-12-21 10:58:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "Pick a Font",
                  "in": "2023-12-21 10:58:55",
                  "out": "2023-12-21 10:59:25",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Home",
                  "in": "2023-12-21 10:59:25",
                  "out": "2023-12-21 10:59:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 10:59:45",
                  "out": "2023-12-21 11:00:05",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 11:00:05",
                  "out": "2023-12-21 11:02:15",
                  "usage": "00:02:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 11:02:15",
                  "out": "2023-12-21 11:03:05",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Downloads",
                  "in": "2023-12-21 11:03:05",
                  "out": "2023-12-21 11:03:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 11:03:25",
                  "out": "2023-12-21 11:03:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:03:35",
                  "out": "2023-12-21 11:03:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/WhiteSur-gtk-theme",
                  "in": "2023-12-21 11:03:45",
                  "out": "2023-12-21 11:03:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:03:55",
                  "out": "2023-12-21 11:04:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:04:05",
                  "out": "2023-12-21 11:04:55",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:04:55",
                  "out": "2023-12-21 11:05:25",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:05:25",
                  "out": "2023-12-21 11:05:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "gedit",
                  "in": "2023-12-21 11:05:35",
                  "out": "2023-12-21 11:05:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:05:45",
                  "out": "2023-12-21 11:11:15",
                  "usage": "00:05:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Downloads",
                  "in": "2023-12-21 11:11:15",
                  "out": "2023-12-21 11:11:55",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/Downloads/Projects/Lookscan/lookscan_interface_milvus",
                  "in": "2023-12-21 11:11:55",
                  "out": "2023-12-21 11:12:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "app.py (~/Downloads/Projects/Lookscan/Lookscan-interface)",
                  "in": "2023-12-21 11:12:15",
                  "out": "2023-12-21 11:12:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 11:12:25",
                  "out": "2023-12-21 11:12:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 11:12:35",
                  "out": "2023-12-21 11:14:55",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 11:14:55",
                  "out": "2023-12-21 11:15:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/Downloads/Projects/Lookscan/interface-lookscan-milvus",
                  "in": "2023-12-21 11:15:05",
                  "out": "2023-12-21 11:15:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Question Answering App",
                  "in": "2023-12-21 11:15:15",
                  "out": "2023-12-21 11:15:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "flask run",
                  "in": "2023-12-21 11:15:25",
                  "out": "2023-12-21 11:16:05",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Images",
                  "in": "2023-12-21 11:16:05",
                  "out": "2023-12-21 11:16:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "index.html",
                  "in": "2023-12-21 11:16:15",
                  "out": "2023-12-21 11:16:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~/Downloads/Projects/Lookscan/interface-lookscan-milvus",
                  "in": "2023-12-21 11:16:25",
                  "out": "2023-12-21 11:16:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Question Answering App",
                  "in": "2023-12-21 11:16:35",
                  "out": "2023-12-21 11:16:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "flask run",
                  "in": "2023-12-21 11:16:45",
                  "out": "2023-12-21 11:17:45",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:17:45",
                  "out": "2023-12-21 11:18:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "RaoInfotech/lookscan_interface_milvus",
                  "in": "2023-12-21 11:18:15",
                  "out": "2023-12-21 11:21:25",
                  "usage": "00:00:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 11:21:25",
                  "out": "2023-12-21 11:24:09",
                  "usage": "00:02:44",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:27:55",
                  "out": "2023-12-21 11:28:34",
                  "usage": "00:00:39",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Software",
                  "windowName": "Software",
                  "in": "2023-12-21 11:28:34",
                  "out": "2023-12-21 11:28:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "RaoInfotech/lookscan_interface_milvus",
                  "in": "2023-12-21 11:28:44",
                  "out": "2023-12-21 11:28:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Desktop",
                  "in": "2023-12-21 11:28:54",
                  "out": "2023-12-21 11:29:34",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "edit_cam_script.py",
                  "in": "2023-12-21 11:29:34",
                  "out": "2023-12-21 11:31:14",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:31:14",
                  "out": "2023-12-21 11:31:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "edit_cam_script.py",
                  "in": "2023-12-21 11:31:24",
                  "out": "2023-12-21 11:39:04",
                  "usage": "00:07:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:39:04",
                  "out": "2023-12-21 11:39:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Software",
                  "windowName": "Software",
                  "in": "2023-12-21 11:39:14",
                  "out": "2023-12-21 11:39:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "edit_cam_script.py (Working Tree) (edit_cam_script.py)",
                  "in": "2023-12-21 11:39:24",
                  "out": "2023-12-21 11:39:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 11:39:34",
                  "out": "2023-12-21 11:43:34",
                  "usage": "00:01:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "RaoInfotech/py-tkinter-gui-example",
                  "in": "2023-12-21 11:43:34",
                  "out": "2023-12-21 11:43:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "edit_cam_script.py (Working Tree) (edit_cam_script.py)",
                  "in": "2023-12-21 11:43:44",
                  "out": "2023-12-21 11:44:04",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:44:04",
                  "out": "2023-12-21 11:44:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 11:44:14",
                  "out": "2023-12-21 11:44:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 11:44:24",
                  "out": "2023-12-21 11:44:44",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:44:44",
                  "out": "2023-12-21 11:45:04",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Settings – Appearance",
                  "in": "2023-12-21 11:45:04",
                  "out": "2023-12-21 11:45:54",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:45:54",
                  "out": "2023-12-21 11:58:14",
                  "usage": "00:12:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 11:58:14",
                  "out": "2023-12-21 11:58:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:58:24",
                  "out": "2023-12-21 11:58:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-tweaks",
                  "windowName": "GNOME Tweaks",
                  "in": "2023-12-21 11:58:34",
                  "out": "2023-12-21 11:59:34",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "face_rec_milvus",
                  "in": "2023-12-21 11:59:34",
                  "out": "2023-12-21 11:59:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 11:59:44",
                  "out": "2023-12-21 12:00:14",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 12:00:14",
                  "out": "2023-12-21 12:00:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "add_milvus_data_autoid_false.py (~/Downloads/Projects/Lookscan/face_rec_milvus)",
                  "in": "2023-12-21 12:00:24",
                  "out": "2023-12-21 12:00:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:00:34",
                  "out": "2023-12-21 12:03:44",
                  "usage": "00:03:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 12:03:44",
                  "out": "2023-12-21 12:04:34",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 12:04:34",
                  "out": "2023-12-21 12:04:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:04:44",
                  "out": "2023-12-21 12:06:04",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "face_rec_milvus",
                  "in": "2023-12-21 12:06:04",
                  "out": "2023-12-21 12:06:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 12:06:14",
                  "out": "2023-12-21 12:06:34",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "face_rec_milvus",
                  "in": "2023-12-21 12:06:34",
                  "out": "2023-12-21 12:06:54",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "face_rec_images.py (~/Downloads/Projects/Lookscan/face_rec_milvus)",
                  "in": "2023-12-21 12:06:54",
                  "out": "2023-12-21 12:07:14",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:07:14",
                  "out": "2023-12-21 12:08:24",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 12:08:24",
                  "out": "2023-12-21 12:08:54",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 12:08:54",
                  "out": "2023-12-21 12:09:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "AI-Bot-Eye",
                  "in": "2023-12-21 12:09:04",
                  "out": "2023-12-21 12:09:24",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 12:09:24",
                  "out": "2023-12-21 12:09:54",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 12:09:54",
                  "out": "2023-12-21 12:10:34",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 12:10:34",
                  "out": "2023-12-21 12:11:04",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "add_multi_cam_script.py",
                  "in": "2023-12-21 12:11:04",
                  "out": "2023-12-21 12:12:14",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 12:12:14",
                  "out": "2023-12-21 12:12:34",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "edit_cam_script.py",
                  "in": "2023-12-21 12:12:34",
                  "out": "2023-12-21 12:13:24",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 12:13:24",
                  "out": "2023-12-21 12:13:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 12:13:34",
                  "out": "2023-12-21 12:14:14",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 12:14:14",
                  "out": "2023-12-21 12:14:34",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 12:14:34",
                  "out": "2023-12-21 12:17:44",
                  "usage": "00:03:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 12:17:44",
                  "out": "2023-12-21 12:20:44",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 12:20:44",
                  "out": "2023-12-21 12:26:04",
                  "usage": "00:05:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:26:04",
                  "out": "2023-12-21 12:29:04",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 12:29:04",
                  "out": "2023-12-21 12:29:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "face_rec_images.py (~/Downloads/Projects/Lookscan/face_rec_milvus)",
                  "in": "2023-12-21 12:29:14",
                  "out": "2023-12-21 12:29:34",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:29:34",
                  "out": "2023-12-21 12:31:54",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 12:31:54",
                  "out": "2023-12-21 12:32:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:32:04",
                  "out": "2023-12-21 12:34:04",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #ahmedabad-lookscan | Rao Information Technology",
                  "in": "2023-12-21 12:34:04",
                  "out": "2023-12-21 12:35:14",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:35:14",
                  "out": "2023-12-21 12:37:54",
                  "usage": "00:02:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 12:37:54",
                  "out": "2023-12-21 12:38:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 12:38:04",
                  "out": "2023-12-21 12:38:15",
                  "usage": "00:00:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "load_milvus.py",
                  "in": "2023-12-21 12:38:15",
                  "out": "2023-12-21 12:38:34",
                  "usage": "00:00:19",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 12:38:34",
                  "out": "2023-12-21 12:38:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:38:44",
                  "out": "2023-12-21 12:42:04",
                  "usage": "00:00:20",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:42:04",
                  "out": "2023-12-21 12:47:24",
                  "usage": "00:02:20",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:47:24",
                  "out": "2023-12-21 12:50:24",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 12:50:24",
                  "out": "2023-12-21 13:02:44",
                  "usage": "00:12:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "add_milvus_data_autoid_false.py",
                  "in": "2023-12-21 13:02:44",
                  "out": "2023-12-21 13:03:04",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 13:03:04",
                  "out": "2023-12-21 13:03:15",
                  "usage": "00:00:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 13:03:15",
                  "out": "2023-12-21 13:03:34",
                  "usage": "00:00:19",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:03:34",
                  "out": "2023-12-21 13:03:54",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 13:03:54",
                  "out": "2023-12-21 13:04:24",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:04:24",
                  "out": "2023-12-21 13:12:44",
                  "usage": "00:08:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "app.py",
                  "in": "2023-12-21 13:12:44",
                  "out": "2023-12-21 13:13:04",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #rao-chat | Rao Information Technology",
                  "in": "2023-12-21 13:13:04",
                  "out": "2023-12-21 13:13:54",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 13:13:54",
                  "out": "2023-12-21 13:14:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:14:04",
                  "out": "2023-12-21 13:17:24",
                  "usage": "00:00:20",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:17:24",
                  "out": "2023-12-21 13:20:24",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:20:24",
                  "out": "2023-12-21 13:21:44",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 13:21:44",
                  "out": "2023-12-21 13:21:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "face_rec_images.py (~/Downloads/Projects/Lookscan/face_rec_milvus)",
                  "in": "2023-12-21 13:21:54",
                  "out": "2023-12-21 13:22:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 13:22:04",
                  "out": "2023-12-21 13:22:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 13:22:14",
                  "out": "2023-12-21 13:26:44",
                  "usage": "00:01:30",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:26:44",
                  "out": "2023-12-21 13:29:44",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:29:44",
                  "out": "2023-12-21 13:32:44",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:32:44",
                  "out": "2023-12-21 13:35:44",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:35:44",
                  "out": "2023-12-21 13:38:44",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:38:44",
                  "out": "2023-12-21 13:41:44",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "The Knowledge Academy | LinkedIn",
                  "in": "2023-12-21 13:41:44",
                  "out": "2023-12-21 13:46:24",
                  "usage": "00:01:40",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "(1) Discord | @Rao Helper Bot",
                  "in": "2023-12-21 13:46:24",
                  "out": "2023-12-21 13:49:24",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "(1) Discord | @Rao Helper Bot",
                  "in": "2023-12-21 13:49:24",
                  "out": "2023-12-21 13:56:05",
                  "usage": "00:06:41",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "add_milvus_data_autoid_false.py",
                  "in": "2023-12-21 13:56:05",
                  "out": "2023-12-21 13:56:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Inbox (885)",
                  "in": "2023-12-21 13:56:15",
                  "out": "2023-12-21 13:56:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 13:56:25",
                  "out": "2023-12-21 13:56:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 13:56:35",
                  "out": "2023-12-21 13:56:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "config.json",
                  "in": "2023-12-21 13:56:45",
                  "out": "2023-12-21 13:57:25",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Inbox (885)",
                  "in": "2023-12-21 13:57:25",
                  "out": "2023-12-21 13:57:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 13:57:35",
                  "out": "2023-12-21 13:59:15",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 13:59:15",
                  "out": "2023-12-21 14:00:15",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 14:00:15",
                  "out": "2023-12-21 14:00:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Downloads",
                  "in": "2023-12-21 14:00:35",
                  "out": "2023-12-21 14:01:15",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:01:15",
                  "out": "2023-12-21 14:04:25",
                  "usage": "00:03:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 14:04:25",
                  "out": "2023-12-21 14:04:44",
                  "usage": "00:00:19",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:04:44",
                  "out": "2023-12-21 14:04:55",
                  "usage": "00:00:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 14:04:55",
                  "out": "2023-12-21 14:07:25",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:07:25",
                  "out": "2023-12-21 14:09:45",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 14:09:45",
                  "out": "2023-12-21 14:12:45",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 14:12:45",
                  "out": "2023-12-21 14:14:45",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:14:45",
                  "out": "2023-12-21 14:20:35",
                  "usage": "00:05:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Postman",
                  "in": "2023-12-21 14:20:35",
                  "out": "2023-12-21 14:20:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:20:55",
                  "out": "2023-12-21 14:21:55",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "rao@rao-H370AORUSGAMING3WIFI:~",
                  "in": "2023-12-21 14:21:55",
                  "out": "2023-12-21 14:22:45",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:22:45",
                  "out": "2023-12-21 14:23:35",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "lookscan-mobileapp-server",
                  "in": "2023-12-21 14:23:35",
                  "out": "2023-12-21 14:23:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements-milvus.txt",
                  "in": "2023-12-21 14:23:55",
                  "out": "2023-12-21 14:25:05",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 14:25:05",
                  "out": "2023-12-21 14:25:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:25:15",
                  "out": "2023-12-21 14:26:05",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 14:26:05",
                  "out": "2023-12-21 14:26:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:26:15",
                  "out": "2023-12-21 14:26:45",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Inbox (885)",
                  "in": "2023-12-21 14:26:45",
                  "out": "2023-12-21 14:32:35",
                  "usage": "00:05:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 14:32:35",
                  "out": "2023-12-21 14:34:35",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 14:34:35",
                  "out": "2023-12-21 14:34:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:34:55",
                  "out": "2023-12-21 14:35:25",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Pictures",
                  "in": "2023-12-21 14:35:25",
                  "out": "2023-12-21 14:35:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:35:35",
                  "out": "2023-12-21 14:38:45",
                  "usage": "00:03:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Rao Helper Bot",
                  "in": "2023-12-21 14:38:45",
                  "out": "2023-12-21 14:41:05",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:41:05",
                  "out": "2023-12-21 14:42:15",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 14:42:15",
                  "out": "2023-12-21 14:49:25",
                  "usage": "00:07:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:49:25",
                  "out": "2023-12-21 14:51:45",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 14:51:45",
                  "out": "2023-12-21 14:52:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 14:52:15",
                  "out": "2023-12-21 14:58:35",
                  "usage": "00:06:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 14:58:35",
                  "out": "2023-12-21 14:58:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "xdg-desktop-portal-gnome",
                  "windowName": "Open Files",
                  "in": "2023-12-21 14:58:45",
                  "out": "2023-12-21 14:58:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 14:58:55",
                  "out": "2023-12-21 15:00:25",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Home",
                  "in": "2023-12-21 15:00:25",
                  "out": "2023-12-21 15:06:05",
                  "usage": "00:05:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "requirements.txt",
                  "in": "2023-12-21 15:06:05",
                  "out": "2023-12-21 15:06:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Projects",
                  "in": "2023-12-21 15:06:15",
                  "out": "2023-12-21 15:06:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "add_milvus_data_autoid_false.py",
                  "in": "2023-12-21 15:06:25",
                  "out": "2023-12-21 15:07:15",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "face_rec_milvus",
                  "in": "2023-12-21 15:07:15",
                  "out": "2023-12-21 15:07:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Code",
                  "windowName": "face_rec_images.py",
                  "in": "2023-12-21 15:07:35",
                  "out": "2023-12-21 15:07:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Create Archive",
                  "in": "2023-12-21 15:07:55",
                  "out": "2023-12-21 15:08:35",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:08:35",
                  "out": "2023-12-21 15:17:45",
                  "usage": "00:09:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 15:17:45",
                  "out": "2023-12-21 15:18:55",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:18:55",
                  "out": "2023-12-21 15:21:55",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:21:55",
                  "out": "2023-12-21 15:24:55",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:24:55",
                  "out": "2023-12-21 15:27:55",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:27:55",
                  "out": "2023-12-21 15:30:55",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:30:55",
                  "out": "2023-12-21 15:42:35",
                  "usage": "00:08:40",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:42:35",
                  "out": "2023-12-21 15:50:55",
                  "usage": "00:08:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 15:50:55",
                  "out": "2023-12-21 15:51:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:51:15",
                  "out": "2023-12-21 15:54:45",
                  "usage": "00:03:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "software installation",
                  "in": "2023-12-21 15:54:45",
                  "out": "2023-12-21 15:54:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:54:55",
                  "out": "2023-12-21 15:56:15",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 15:56:15",
                  "out": "2023-12-21 15:56:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 15:56:35",
                  "out": "2023-12-21 15:59:05",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "How To Install MySQL on Ubuntu 22.04 | DigitalOcean",
                  "in": "2023-12-21 15:59:05",
                  "out": "2023-12-21 15:59:45",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 15:59:45",
                  "out": "2023-12-21 15:59:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "face_rec_milvus",
                  "in": "2023-12-21 15:59:55",
                  "out": "2023-12-21 16:00:25",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 16:00:25",
                  "out": "2023-12-21 16:00:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:00:45",
                  "out": "2023-12-21 16:05:15",
                  "usage": "00:04:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "localhost / localhost / lookscandb / Student | phpMyAdmin 5.2.1",
                  "in": "2023-12-21 16:05:15",
                  "out": "2023-12-21 16:06:55",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "recognized",
                  "in": "2023-12-21 16:06:55",
                  "out": "2023-12-21 16:07:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "localhost / localhost / lookscan / Student | phpMyAdmin 5.2.1",
                  "in": "2023-12-21 16:07:05",
                  "out": "2023-12-21 16:07:35",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "recognized",
                  "in": "2023-12-21 16:07:35",
                  "out": "2023-12-21 16:08:05",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:08:05",
                  "out": "2023-12-21 16:08:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "How To Install MySQL on Ubuntu 22.04 | DigitalOcean",
                  "in": "2023-12-21 16:08:25",
                  "out": "2023-12-21 16:09:55",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Downloads",
                  "in": "2023-12-21 16:09:55",
                  "out": "2023-12-21 16:10:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 16:10:05",
                  "out": "2023-12-21 16:11:05",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:11:05",
                  "out": "2023-12-21 16:11:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "database",
                  "in": "2023-12-21 16:11:25",
                  "out": "2023-12-21 16:11:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:11:35",
                  "out": "2023-12-21 16:11:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Downloads",
                  "in": "2023-12-21 16:11:45",
                  "out": "2023-12-21 16:11:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 16:11:55",
                  "out": "2023-12-21 16:12:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:12:05",
                  "out": "2023-12-21 16:13:15",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "database",
                  "in": "2023-12-21 16:13:15",
                  "out": "2023-12-21 16:13:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:13:25",
                  "out": "2023-12-21 16:15:25",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "database",
                  "in": "2023-12-21 16:15:25",
                  "out": "2023-12-21 16:17:25",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:17:25",
                  "out": "2023-12-21 16:17:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "ChatGPT",
                  "in": "2023-12-21 16:17:45",
                  "out": "2023-12-21 16:18:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:18:15",
                  "out": "2023-12-21 16:18:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "ChatGPT",
                  "in": "2023-12-21 16:18:35",
                  "out": "2023-12-21 16:18:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:18:45",
                  "out": "2023-12-21 16:19:35",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "ChatGPT",
                  "in": "2023-12-21 16:19:35",
                  "out": "2023-12-21 16:20:15",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:20:15",
                  "out": "2023-12-21 16:20:45",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "database",
                  "in": "2023-12-21 16:20:45",
                  "out": "2023-12-21 16:22:15",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:22:15",
                  "out": "2023-12-21 16:24:35",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "ChatGPT",
                  "in": "2023-12-21 16:24:35",
                  "out": "2023-12-21 16:25:15",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:25:15",
                  "out": "2023-12-21 16:25:55",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "ChatGPT",
                  "in": "2023-12-21 16:25:55",
                  "out": "2023-12-21 16:26:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:26:05",
                  "out": "2023-12-21 16:27:05",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "sudo mysql",
                  "in": "2023-12-21 16:27:05",
                  "out": "2023-12-21 16:27:35",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 16:27:35",
                  "out": "2023-12-21 16:27:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:27:55",
                  "out": "2023-12-21 16:29:25",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Install XAMPP on Ubuntu 22.04 LTS Jammy Linux",
                  "in": "2023-12-21 16:29:25",
                  "out": "2023-12-21 16:29:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:29:35",
                  "out": "2023-12-21 16:30:45",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 16:30:45",
                  "out": "2023-12-21 16:30:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "My Drive",
                  "in": "2023-12-21 16:30:55",
                  "out": "2023-12-21 16:31:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:31:15",
                  "out": "2023-12-21 16:31:55",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:31:55",
                  "out": "2023-12-21 16:32:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:32:05",
                  "out": "2023-12-21 16:32:35",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:32:35",
                  "out": "2023-12-21 16:32:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:32:45",
                  "out": "2023-12-21 16:33:25",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:33:25",
                  "out": "2023-12-21 16:33:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:33:45",
                  "out": "2023-12-21 16:34:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:34:15",
                  "out": "2023-12-21 16:34:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:34:25",
                  "out": "2023-12-21 16:34:45",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:34:45",
                  "out": "2023-12-21 16:35:45",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:35:45",
                  "out": "2023-12-21 16:35:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:35:55",
                  "out": "2023-12-21 16:36:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:36:15",
                  "out": "2023-12-21 16:36:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": "lookscan.sql (~/Downloads)",
                  "in": "2023-12-21 16:36:35",
                  "out": "2023-12-21 16:36:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:36:55",
                  "out": "2023-12-21 16:37:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "sudo mysql",
                  "in": "2023-12-21 16:37:05",
                  "out": "2023-12-21 16:37:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:37:15",
                  "out": "2023-12-21 16:39:35",
                  "usage": "00:02:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Org.gnome.Nautilus",
                  "windowName": "Lookscan",
                  "in": "2023-12-21 16:39:35",
                  "out": "2023-12-21 16:39:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": ".env (~/Downloads/Projects/Lookscan/lookscan-mobileapp-server)",
                  "in": "2023-12-21 16:39:55",
                  "out": "2023-12-21 16:40:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:40:05",
                  "out": "2023-12-21 16:46:25",
                  "usage": "00:06:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 16:46:25",
                  "out": "2023-12-21 16:46:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:46:35",
                  "out": "2023-12-21 16:47:15",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 16:47:15",
                  "out": "2023-12-21 16:47:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "Postman",
                  "in": "2023-12-21 16:47:35",
                  "out": "2023-12-21 16:47:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:47:45",
                  "out": "2023-12-21 16:52:05",
                  "usage": "00:04:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gedit",
                  "windowName": ".env (~/Downloads/Projects/Lookscan/lookscan-mobileapp-server)",
                  "in": "2023-12-21 16:52:05",
                  "out": "2023-12-21 16:52:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | @Vivek Malvi",
                  "in": "2023-12-21 16:52:25",
                  "out": "2023-12-21 16:52:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:52:35",
                  "out": "2023-12-21 16:57:15",
                  "usage": "00:04:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 16:57:15",
                  "out": "2023-12-21 16:57:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Gnome-terminal",
                  "windowName": "sudo mysql",
                  "in": "2023-12-21 16:57:35",
                  "out": "2023-12-21 16:57:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:57:45",
                  "out": "2023-12-21 16:58:55",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "MySQL SHOW USERS: List All Users in a MySQL Database",
                  "in": "2023-12-21 16:58:55",
                  "out": "2023-12-21 16:59:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:59:05",
                  "out": "2023-12-21 16:59:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 16:59:25",
                  "out": "2023-12-21 16:59:55",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 16:59:55",
                  "out": "2023-12-21 17:02:35",
                  "usage": "00:02:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 17:02:35",
                  "out": "2023-12-21 17:03:25",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 17:03:25",
                  "out": "2023-12-21 17:03:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:03:35",
                  "out": "2023-12-21 17:03:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "python",
                  "in": "2023-12-21 17:03:45",
                  "out": "2023-12-21 17:05:45",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "discord",
                  "windowName": "@Vivek Malvi",
                  "in": "2023-12-21 17:05:45",
                  "out": "2023-12-21 17:05:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:05:55",
                  "out": "2023-12-21 17:08:55",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "localhost / localhost / lookscan | phpMyAdmin 5.2.1",
                  "in": "2023-12-21 17:08:55",
                  "out": "2023-12-21 17:09:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:09:05",
                  "out": "2023-12-21 17:13:35",
                  "usage": "00:04:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #rao-chat | Rao Information Technology",
                  "in": "2023-12-21 17:13:35",
                  "out": "2023-12-21 17:14:25",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 17:14:25",
                  "out": "2023-12-21 17:14:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #rao-chat | Rao Information Technology",
                  "in": "2023-12-21 17:14:35",
                  "out": "2023-12-21 17:14:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Postman",
                  "windowName": "Upload Images",
                  "in": "2023-12-21 17:14:45",
                  "out": "2023-12-21 17:14:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:14:55",
                  "out": "2023-12-21 17:15:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "python",
                  "in": "2023-12-21 17:15:15",
                  "out": "2023-12-21 17:16:45",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:16:45",
                  "out": "2023-12-21 17:18:35",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "• Discord | #rajkot-office | Rao Monitor",
                  "in": "2023-12-21 17:18:35",
                  "out": "2023-12-21 17:21:55",
                  "usage": "00:03:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:21:55",
                  "out": "2023-12-21 17:25:05",
                  "usage": "00:00:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:25:05",
                  "out": "2023-12-21 17:25:45",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "(mysql.connector.errors.InterfaceError) 2013: Lost connection to MySQL server during query",
                  "in": "2023-12-21 17:25:45",
                  "out": "2023-12-21 17:26:35",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:26:35",
                  "out": "2023-12-21 17:28:15",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "how to run nginx and apache together",
                  "in": "2023-12-21 17:28:15",
                  "out": "2023-12-21 17:28:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Anydesk",
                  "windowName": "AnyDesk",
                  "in": "2023-12-21 17:28:25",
                  "out": "2023-12-21 17:29:05",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "How can I run both nginx and Apache together on Ubuntu?",
                  "in": "2023-12-21 17:29:05",
                  "out": "2023-12-21 17:32:45",
                  "usage": "00:00:40",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "How can I run both nginx and Apache together on Ubuntu?",
                  "in": "2023-12-21 17:32:45",
                  "out": "2023-12-21 17:35:45",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google-chrome",
                  "windowName": "How can I run both nginx and Apache together on Ubuntu?",
                  "in": "2023-12-21 17:35:45",
                  "out": "2023-12-21 17:38:45",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              }
          ],
          "name": "User3"
      },
      "User4": {
          "sessions": [
              {
                  "appName": "Discord",
                  "windowName": "Discord Updater",
                  "in": "2023-12-21 09:43:02",
                  "out": "2023-12-21 09:45:50",
                  "usage": "00:02:48",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 09:45:50",
                  "out": "2023-12-21 09:46:00",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Downloads",
                  "in": "2023-12-21 09:46:00",
                  "out": "2023-12-21 09:51:30",
                  "usage": "00:02:30",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 09:51:30",
                  "out": "2023-12-21 09:54:30",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 09:54:30",
                  "out": "2023-12-21 09:57:30",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 09:57:30",
                  "out": "2023-12-21 10:00:30",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 10:00:30",
                  "out": "2023-12-21 10:38:41",
                  "usage": "00:35:11",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 10:38:41",
                  "out": "2023-12-21 11:11:51",
                  "usage": "00:33:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 11:11:51",
                  "out": "2023-12-21 11:12:11",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ WappMaster",
                  "in": "2023-12-21 11:12:11",
                  "out": "2023-12-21 11:15:51",
                  "usage": "00:03:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:15:51",
                  "out": "2023-12-21 11:16:01",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 11:16:01",
                  "out": "2023-12-21 11:16:51",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:16:51",
                  "out": "2023-12-21 11:17:01",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 11:17:01",
                  "out": "2023-12-21 11:22:21",
                  "usage": "00:05:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:22:21",
                  "out": "2023-12-21 11:22:31",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 11:22:31",
                  "out": "2023-12-21 11:44:52",
                  "usage": "00:22:21",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "Friends",
                  "in": "2023-12-21 11:44:52",
                  "out": "2023-12-21 11:45:22",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 11:45:22",
                  "out": "2023-12-21 11:45:32",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 11:45:32",
                  "out": "2023-12-21 11:45:52",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 11:45:52",
                  "out": "2023-12-21 11:50:02",
                  "usage": "00:04:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 11:50:02",
                  "out": "2023-12-21 11:50:12",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 11:50:12",
                  "out": "2023-12-21 11:58:52",
                  "usage": "00:08:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 11:58:52",
                  "out": "2023-12-21 11:59:02",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 11:59:02",
                  "out": "2023-12-21 12:01:03",
                  "usage": "00:02:01",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 12:01:03",
                  "out": "2023-12-21 12:01:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:01:13",
                  "out": "2023-12-21 12:05:13",
                  "usage": "00:04:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:05:13",
                  "out": "2023-12-21 12:05:23",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Meet",
                  "in": "2023-12-21 12:05:23",
                  "out": "2023-12-21 12:09:03",
                  "usage": "00:03:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 12:09:03",
                  "out": "2023-12-21 12:09:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 12:09:13",
                  "out": "2023-12-21 12:15:23",
                  "usage": "00:06:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 12:15:23",
                  "out": "2023-12-21 12:15:33",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:15:33",
                  "out": "2023-12-21 12:15:53",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 12:15:53",
                  "out": "2023-12-21 12:16:03",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:16:03",
                  "out": "2023-12-21 12:16:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 12:16:13",
                  "out": "2023-12-21 12:16:23",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:16:23",
                  "out": "2023-12-21 12:28:14",
                  "usage": "00:11:51",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:28:14",
                  "out": "2023-12-21 12:28:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Highlight new feature in Android",
                  "in": "2023-12-21 12:28:24",
                  "out": "2023-12-21 12:31:25",
                  "usage": "00:03:01",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 12:31:25",
                  "out": "2023-12-21 12:33:05",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:33:05",
                  "out": "2023-12-21 12:36:25",
                  "usage": "00:00:20",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Google Meet",
                  "in": "2023-12-21 12:36:25",
                  "out": "2023-12-21 12:38:15",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:38:15",
                  "out": "2023-12-21 12:38:45",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "SuperSee",
                  "in": "2023-12-21 12:38:45",
                  "out": "2023-12-21 12:41:45",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:41:45",
                  "out": "2023-12-21 12:42:45",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:42:45",
                  "out": "2023-12-21 12:44:25",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 12:44:25",
                  "out": "2023-12-21 12:44:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:44:35",
                  "out": "2023-12-21 12:46:05",
                  "usage": "00:01:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 12:46:05",
                  "out": "2023-12-21 12:46:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:46:25",
                  "out": "2023-12-21 12:48:55",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 12:48:55",
                  "out": "2023-12-21 12:49:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:49:05",
                  "out": "2023-12-21 12:58:05",
                  "usage": "00:09:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:58:05",
                  "out": "2023-12-21 12:58:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 12:58:15",
                  "out": "2023-12-21 12:58:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 12:58:25",
                  "out": "2023-12-21 12:58:46",
                  "usage": "00:00:21",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 12:58:46",
                  "out": "2023-12-21 12:59:06",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Control Panel",
                  "in": "2023-12-21 12:59:06",
                  "out": "2023-12-21 13:00:06",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Wappmaster Setup 1.6.0 (1).exe",
                  "in": "2023-12-21 13:00:06",
                  "out": "2023-12-21 13:01:06",
                  "usage": "00:01:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 13:01:06",
                  "out": "2023-12-21 13:01:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Recent download history",
                  "in": "2023-12-21 13:01:16",
                  "out": "2023-12-21 13:01:26",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 13:01:26",
                  "out": "2023-12-21 13:01:56",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:01:56",
                  "out": "2023-12-21 13:02:36",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 13:02:36",
                  "out": "2023-12-21 13:02:46",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster Software – Figma",
                  "in": "2023-12-21 13:02:46",
                  "out": "2023-12-21 13:02:56",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Microsoft Edge",
                  "windowName": "Figma and 5 more pages",
                  "in": "2023-12-21 13:02:56",
                  "out": "2023-12-21 13:03:16",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster Software – Figma",
                  "in": "2023-12-21 13:03:16",
                  "out": "2023-12-21 13:11:36",
                  "usage": "00:08:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 13:11:36",
                  "out": "2023-12-21 13:11:46",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:11:46",
                  "out": "2023-12-21 13:17:56",
                  "usage": "00:06:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 13:17:56",
                  "out": "2023-12-21 13:18:06",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Notepad",
                  "windowName": "*Untitled",
                  "in": "2023-12-21 13:18:06",
                  "out": "2023-12-21 13:18:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:18:16",
                  "out": "2023-12-21 13:19:06",
                  "usage": "00:00:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 13:19:06",
                  "out": "2023-12-21 13:19:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:19:16",
                  "out": "2023-12-21 13:19:26",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 13:19:26",
                  "out": "2023-12-21 13:19:36",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:19:36",
                  "out": "2023-12-21 13:25:16",
                  "usage": "00:02:40",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:25:16",
                  "out": "2023-12-21 13:28:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:28:16",
                  "out": "2023-12-21 13:31:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:31:16",
                  "out": "2023-12-21 13:34:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:34:16",
                  "out": "2023-12-21 13:37:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:37:16",
                  "out": "2023-12-21 13:40:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:40:16",
                  "out": "2023-12-21 13:42:16",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 13:42:16",
                  "out": "2023-12-21 13:45:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 13:45:16",
                  "out": "2023-12-21 13:48:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 13:48:16",
                  "out": "2023-12-21 13:51:16",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 13:51:16",
                  "out": "2023-12-21 13:55:06",
                  "usage": "00:03:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 13:55:06",
                  "out": "2023-12-21 13:56:07",
                  "usage": "00:01:01",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 13:56:07",
                  "out": "2023-12-21 13:57:57",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 13:57:57",
                  "out": "2023-12-21 14:00:27",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 14:00:27",
                  "out": "2023-12-21 14:00:47",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:00:47",
                  "out": "2023-12-21 14:30:57",
                  "usage": "00:30:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 14:30:57",
                  "out": "2023-12-21 14:31:07",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:31:07",
                  "out": "2023-12-21 14:41:18",
                  "usage": "00:07:11",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:41:18",
                  "out": "2023-12-21 14:44:18",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:44:18",
                  "out": "2023-12-21 14:47:08",
                  "usage": "00:02:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 14:47:08",
                  "out": "2023-12-21 14:47:18",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:47:18",
                  "out": "2023-12-21 14:47:58",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 14:47:58",
                  "out": "2023-12-21 14:48:28",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:48:28",
                  "out": "2023-12-21 14:55:08",
                  "usage": "00:06:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 14:55:08",
                  "out": "2023-12-21 14:55:28",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 14:55:28",
                  "out": "2023-12-21 15:00:18",
                  "usage": "00:04:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 15:00:18",
                  "out": "2023-12-21 15:00:28",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 15:00:28",
                  "out": "2023-12-21 15:09:59",
                  "usage": "00:09:31",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 15:09:59",
                  "out": "2023-12-21 15:10:09",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 15:10:09",
                  "out": "2023-12-21 15:14:19",
                  "usage": "00:04:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 15:14:19",
                  "out": "2023-12-21 15:14:29",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 15:14:29",
                  "out": "2023-12-21 15:41:40",
                  "usage": "00:27:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 15:41:40",
                  "out": "2023-12-21 15:41:50",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 15:41:50",
                  "out": "2023-12-21 15:48:00",
                  "usage": "00:06:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 15:48:00",
                  "out": "2023-12-21 15:51:00",
                  "usage": "00:03:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 15:51:00",
                  "out": "2023-12-21 15:52:40",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "My Account | User Settings",
                  "in": "2023-12-21 15:52:40",
                  "out": "2023-12-21 15:53:04",
                  "usage": "00:00:24",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "download-free-photos-from-freepik_956981-645.jpg (351×626)",
                  "in": "2023-12-21 15:53:04",
                  "out": "2023-12-21 15:53:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "Open",
                  "in": "2023-12-21 15:53:14",
                  "out": "2023-12-21 15:53:44",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Downloads",
                  "in": "2023-12-21 15:53:44",
                  "out": "2023-12-21 15:53:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "PhotosApp.exe",
                  "windowName": "download-free-photos-from-freepik_956981-645.avif ‎- Photos",
                  "in": "2023-12-21 15:53:54",
                  "out": "2023-12-21 15:54:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "Profiles | User Settings",
                  "in": "2023-12-21 15:54:04",
                  "out": "2023-12-21 15:54:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 15:54:14",
                  "out": "2023-12-21 15:54:54",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "Profiles | User Settings",
                  "in": "2023-12-21 15:54:54",
                  "out": "2023-12-21 15:55:34",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 15:55:34",
                  "out": "2023-12-21 15:55:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Meet",
                  "in": "2023-12-21 15:55:44",
                  "out": "2023-12-21 16:08:20",
                  "usage": "00:12:36",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 16:08:20",
                  "out": "2023-12-21 16:08:30",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 16:08:30",
                  "out": "2023-12-21 16:08:40",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 16:08:40",
                  "out": "2023-12-21 16:13:10",
                  "usage": "00:01:30",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Meet",
                  "in": "2023-12-21 16:13:10",
                  "out": "2023-12-21 16:21:20",
                  "usage": "00:05:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster Software – Figma",
                  "in": "2023-12-21 16:21:20",
                  "out": "2023-12-21 16:24:20",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster Software – Figma",
                  "in": "2023-12-21 16:24:20",
                  "out": "2023-12-21 16:24:40",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 16:24:40",
                  "out": "2023-12-21 16:28:10",
                  "usage": "00:00:30",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 16:28:10",
                  "out": "2023-12-21 16:31:10",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 16:31:10",
                  "out": "2023-12-21 16:32:01",
                  "usage": "00:00:51",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 16:32:01",
                  "out": "2023-12-21 16:32:20",
                  "usage": "00:00:19",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 16:32:20",
                  "out": "2023-12-21 16:32:30",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 16:32:30",
                  "out": "2023-12-21 16:54:31",
                  "usage": "00:22:01",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Riddhi Gohel",
                  "in": "2023-12-21 16:54:31",
                  "out": "2023-12-21 16:54:51",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 16:54:51",
                  "out": "2023-12-21 17:03:51",
                  "usage": "00:09:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 17:03:51",
                  "out": "2023-12-21 17:04:01",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 17:04:01",
                  "out": "2023-12-21 17:10:31",
                  "usage": "00:06:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 17:10:31",
                  "out": "2023-12-21 17:10:41",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Discord",
                  "windowName": "@Heer Soni",
                  "in": "2023-12-21 17:10:41",
                  "out": "2023-12-21 17:11:11",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 17:11:11",
                  "out": "2023-12-21 17:11:21",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 17:11:21",
                  "out": "2023-12-21 17:11:31",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "New Tab",
                  "in": "2023-12-21 17:11:31",
                  "out": "2023-12-21 17:18:41",
                  "usage": "00:07:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 17:18:41",
                  "out": "2023-12-21 17:18:51",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 17:18:51",
                  "out": "2023-12-21 17:19:21",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 17:19:21",
                  "out": "2023-12-21 17:19:31",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 17:19:31",
                  "out": "2023-12-21 17:20:01",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Task Switching",
                  "in": "2023-12-21 17:20:01",
                  "out": "2023-12-21 17:20:11",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WappMaster – Figma",
                  "in": "2023-12-21 17:20:11",
                  "out": "2023-12-21 17:20:31",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 17:20:31",
                  "out": "2023-12-21 17:20:41",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 17:20:41",
                  "out": "2023-12-21 17:24:51",
                  "usage": "00:01:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 17:24:51",
                  "out": "2023-12-21 17:27:01",
                  "usage": "00:02:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster",
                  "in": "2023-12-21 17:27:01",
                  "out": "2023-12-21 17:27:11",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "▶ Final Design update (21-12-2023)",
                  "in": "2023-12-21 17:27:11",
                  "out": "2023-12-21 17:34:21",
                  "usage": "00:04:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "CICO",
                  "in": "2023-12-21 17:34:21",
                  "out": "2023-12-21 17:37:21",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              }
          ],
          "name": "User4"
      },
      "User5": {
          "sessions": [
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:01:34",
                  "out": "2023-12-21 11:02:14",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Microsoft Edge",
                  "windowName": "",
                  "in": "2023-12-21 11:02:14",
                  "out": "2023-12-21 11:02:44",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Chrome Web Store",
                  "in": "2023-12-21 11:02:44",
                  "out": "2023-12-21 11:02:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:02:54",
                  "out": "2023-12-21 11:03:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Home",
                  "in": "2023-12-21 11:03:04",
                  "out": "2023-12-21 11:13:04",
                  "usage": "00:10:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:13:04",
                  "out": "2023-12-21 11:13:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Discord | @Mohit Shah",
                  "in": "2023-12-21 11:13:14",
                  "out": "2023-12-21 11:14:54",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 11:14:54",
                  "out": "2023-12-21 11:15:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Untitled document",
                  "in": "2023-12-21 11:15:04",
                  "out": "2023-12-21 11:15:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Supersee",
                  "windowName": "SuperSee Widget",
                  "in": "2023-12-21 11:15:14",
                  "out": "2023-12-21 11:15:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Quote Instagram Story Modern Minimalist Template",
                  "in": "2023-12-21 11:15:24",
                  "out": "2023-12-21 11:17:14",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:17:14",
                  "out": "2023-12-21 11:18:24",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 11:18:24",
                  "out": "2023-12-21 11:18:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:18:34",
                  "out": "2023-12-21 11:18:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:18:44",
                  "out": "2023-12-21 11:18:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 11:18:54",
                  "out": "2023-12-21 11:19:14",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:19:14",
                  "out": "2023-12-21 11:19:34",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:19:34",
                  "out": "2023-12-21 11:19:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 11:19:44",
                  "out": "2023-12-21 11:19:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:19:54",
                  "out": "2023-12-21 11:20:14",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:20:14",
                  "out": "2023-12-21 11:20:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:20:24",
                  "out": "2023-12-21 11:20:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 11:20:34",
                  "out": "2023-12-21 11:21:04",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:21:04",
                  "out": "2023-12-21 11:21:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Application Frame Host",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:21:14",
                  "out": "2023-12-21 11:21:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:21:24",
                  "out": "2023-12-21 11:21:34",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:21:34",
                  "out": "2023-12-21 11:21:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 11:21:44",
                  "out": "2023-12-21 11:21:54",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:21:54",
                  "out": "2023-12-21 11:22:04",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Application Frame Host",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:22:04",
                  "out": "2023-12-21 11:22:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:22:14",
                  "out": "2023-12-21 11:22:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Carousel posts",
                  "in": "2023-12-21 11:22:24",
                  "out": "2023-12-21 11:23:04",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:23:04",
                  "out": "2023-12-21 11:23:14",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Action center",
                  "in": "2023-12-21 11:23:14",
                  "out": "2023-12-21 11:23:24",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Carousel posts",
                  "in": "2023-12-21 11:23:24",
                  "out": "2023-12-21 11:25:54",
                  "usage": "00:02:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Downloads",
                  "in": "2023-12-21 11:25:54",
                  "out": "2023-12-21 11:26:14",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Quote Instagram Story Modern Minimalist Template",
                  "in": "2023-12-21 11:26:14",
                  "out": "2023-12-21 11:32:25",
                  "usage": "00:06:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:32:25",
                  "out": "2023-12-21 11:32:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Discord | @Mohit Shah",
                  "in": "2023-12-21 11:32:35",
                  "out": "2023-12-21 11:32:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:32:55",
                  "out": "2023-12-21 11:33:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Discord | @Mohit Shah",
                  "in": "2023-12-21 11:33:05",
                  "out": "2023-12-21 11:37:15",
                  "usage": "00:01:10",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Quote Instagram Story Modern Minimalist Template",
                  "in": "2023-12-21 11:37:15",
                  "out": "2023-12-21 11:48:55",
                  "usage": "00:11:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Search application",
                  "windowName": "Search",
                  "in": "2023-12-21 11:48:55",
                  "out": "2023-12-21 11:49:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:49:15",
                  "out": "2023-12-21 11:49:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:49:25",
                  "out": "2023-12-21 11:49:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Application Frame Host",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:49:35",
                  "out": "2023-12-21 11:49:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 11:49:45",
                  "out": "2023-12-21 11:50:05",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:50:05",
                  "out": "2023-12-21 11:50:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:50:25",
                  "out": "2023-12-21 11:50:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:50:35",
                  "out": "2023-12-21 11:50:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Application Frame Host",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:50:55",
                  "out": "2023-12-21 11:51:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:51:05",
                  "out": "2023-12-21 11:51:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 11:51:15",
                  "out": "2023-12-21 11:51:35",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:51:35",
                  "out": "2023-12-21 11:52:05",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:52:05",
                  "out": "2023-12-21 11:52:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 11:52:15",
                  "out": "2023-12-21 11:52:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:52:25",
                  "out": "2023-12-21 11:52:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:52:35",
                  "out": "2023-12-21 11:53:15",
                  "usage": "00:00:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 11:53:15",
                  "out": "2023-12-21 11:53:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:53:25",
                  "out": "2023-12-21 11:53:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:53:35",
                  "out": "2023-12-21 11:53:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:53:45",
                  "out": "2023-12-21 11:53:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 11:53:55",
                  "out": "2023-12-21 11:54:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 11:54:05",
                  "out": "2023-12-21 11:54:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 11:54:15",
                  "out": "2023-12-21 11:54:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:54:25",
                  "out": "2023-12-21 11:54:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 11:54:35",
                  "out": "2023-12-21 11:54:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "Open",
                  "in": "2023-12-21 11:54:45",
                  "out": "2023-12-21 11:55:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Action center",
                  "in": "2023-12-21 11:55:15",
                  "out": "2023-12-21 11:55:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 11:55:25",
                  "out": "2023-12-21 11:55:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Discord | @Mohit Shah",
                  "in": "2023-12-21 11:55:35",
                  "out": "2023-12-21 12:05:45",
                  "usage": "00:10:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Volume Control",
                  "in": "2023-12-21 12:05:45",
                  "out": "2023-12-21 12:05:55",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Story.",
                  "in": "2023-12-21 12:05:55",
                  "out": "2023-12-21 12:07:55",
                  "usage": "00:02:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 12:07:55",
                  "out": "2023-12-21 12:08:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "PhotosApp.exe",
                  "windowName": "choose-chrome-exe.png ‎- Photos",
                  "in": "2023-12-21 12:08:05",
                  "out": "2023-12-21 12:08:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 12:08:15",
                  "out": "2023-12-21 12:08:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 12:08:25",
                  "out": "2023-12-21 12:10:05",
                  "usage": "00:01:40",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 12:10:05",
                  "out": "2023-12-21 12:10:25",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 12:10:25",
                  "out": "2023-12-21 12:10:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 12:10:35",
                  "out": "2023-12-21 12:10:55",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenSketch.exe",
                  "windowName": "Snip & Sketch",
                  "in": "2023-12-21 12:10:55",
                  "out": "2023-12-21 12:11:05",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Rao Conduct (Project Management Tool)",
                  "in": "2023-12-21 12:11:05",
                  "out": "2023-12-21 12:15:05",
                  "usage": "00:04:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Wappmaster",
                  "windowName": "WappMaster- v1.5.5",
                  "in": "2023-12-21 12:15:05",
                  "out": "2023-12-21 12:15:15",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Action center",
                  "in": "2023-12-21 12:15:15",
                  "out": "2023-12-21 12:15:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "File Picker UI Host",
                  "windowName": "Save As",
                  "in": "2023-12-21 12:15:25",
                  "out": "2023-12-21 12:15:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "File Explorer",
                  "in": "2023-12-21 12:15:35",
                  "out": "2023-12-21 12:17:25",
                  "usage": "00:01:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 12:17:25",
                  "out": "2023-12-21 12:22:35",
                  "usage": "00:05:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 12:22:35",
                  "out": "2023-12-21 12:22:45",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 12:22:45",
                  "out": "2023-12-21 12:25:45",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Documentation",
                  "in": "2023-12-21 12:25:45",
                  "out": "2023-12-21 12:26:55",
                  "usage": "00:01:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 12:26:55",
                  "out": "2023-12-21 12:27:15",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "• Discord | @Mohit Shah",
                  "in": "2023-12-21 12:27:15",
                  "out": "2023-12-21 12:37:45",
                  "usage": "00:07:30",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "WApp-Master Story.",
                  "in": "2023-12-21 12:37:45",
                  "out": "2023-12-21 12:48:35",
                  "usage": "00:07:50",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Carousel posts",
                  "in": "2023-12-21 12:48:35",
                  "out": "2023-12-21 12:51:35",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Carousel posts",
                  "in": "2023-12-21 12:51:35",
                  "out": "2023-12-21 12:54:35",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Carousel posts",
                  "in": "2023-12-21 12:54:35",
                  "out": "2023-12-21 13:02:45",
                  "usage": "00:08:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Pictures",
                  "in": "2023-12-21 13:02:45",
                  "out": "2023-12-21 13:03:15",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Meta Business Suite",
                  "in": "2023-12-21 13:03:15",
                  "out": "2023-12-21 13:12:15",
                  "usage": "00:09:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "Extract Compressed (Zipped) Folders",
                  "in": "2023-12-21 13:12:15",
                  "out": "2023-12-21 13:12:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Discord | @Mohit Shah",
                  "in": "2023-12-21 13:12:25",
                  "out": "2023-12-21 13:39:46",
                  "usage": "00:24:21",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Post “5 Actionable Strategies to Drive Traffic To Your Pharmacy Website Organically” ‹ Rao Information Technology — WordPress",
                  "in": "2023-12-21 13:39:46",
                  "out": "2023-12-21 13:42:46",
                  "usage": "00:00:00",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Edit Post “5 Actionable Strategies to Drive Traffic To Your Pharmacy Website Organically” ‹ Rao Information Technology — WordPress",
                  "in": "2023-12-21 13:42:46",
                  "out": "2023-12-21 13:44:06",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 13:44:06",
                  "out": "2023-12-21 13:44:16",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "5 Actionable Strategies to Drive Traffic To Your Pharmacy Website Organically | Rao Information Technology",
                  "in": "2023-12-21 13:44:16",
                  "out": "2023-12-21 13:55:25",
                  "usage": "00:11:09",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Home | Rao Information Technology",
                  "in": "2023-12-21 16:08:13",
                  "out": "2023-12-21 16:19:13",
                  "usage": "00:11:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Realtek HD Audio Manager",
                  "windowName": "The current connected device is :",
                  "in": "2023-12-21 16:19:13",
                  "out": "2023-12-21 16:19:33",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "PPC Management Price: How Much Should You Pay? (+ 3 Options)",
                  "in": "2023-12-21 16:19:33",
                  "out": "2023-12-21 16:20:03",
                  "usage": "00:00:30",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Realtek HD Audio Manager",
                  "windowName": "Dell Audio",
                  "in": "2023-12-21 16:20:03",
                  "out": "2023-12-21 16:20:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Rao Conduct (Project Management Tool)",
                  "in": "2023-12-21 16:20:13",
                  "out": "2023-12-21 16:23:03",
                  "usage": "00:02:50",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Explorer",
                  "windowName": "",
                  "in": "2023-12-21 16:23:03",
                  "out": "2023-12-21 16:23:13",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Action center",
                  "in": "2023-12-21 16:23:13",
                  "out": "2023-12-21 16:23:23",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "The reasons why a pharmacy mobile app solution is necessary for your pharmacy | Rao Information Technology",
                  "in": "2023-12-21 16:23:23",
                  "out": "2023-12-21 16:38:34",
                  "usage": "00:15:11",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 16:38:34",
                  "out": "2023-12-21 16:38:44",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "The reasons why a pharmacy mobile app solution is necessary for your pharmacy | Rao Information Technology",
                  "in": "2023-12-21 16:38:44",
                  "out": "2023-12-21 16:52:15",
                  "usage": "00:13:31",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Date and Time Information",
                  "in": "2023-12-21 16:52:15",
                  "out": "2023-12-21 16:52:25",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Patient Engagement Solution",
                  "in": "2023-12-21 16:52:25",
                  "out": "2023-12-21 17:01:25",
                  "usage": "00:09:00",
                  "idle": "00:00:00"
              },
              {
                  "appName": "ScreenClippingHost.exe",
                  "windowName": "Screen Snipping",
                  "in": "2023-12-21 17:01:25",
                  "out": "2023-12-21 17:01:35",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Rao Conduct (Project Management Tool)",
                  "in": "2023-12-21 17:01:35",
                  "out": "2023-12-21 17:10:56",
                  "usage": "00:09:21",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Volume Control",
                  "in": "2023-12-21 17:10:56",
                  "out": "2023-12-21 17:12:16",
                  "usage": "00:01:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "SEM Agency",
                  "in": "2023-12-21 17:12:16",
                  "out": "2023-12-21 17:12:36",
                  "usage": "00:00:20",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Volume Control",
                  "in": "2023-12-21 17:12:36",
                  "out": "2023-12-21 17:12:46",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "SEM Agency",
                  "in": "2023-12-21 17:12:46",
                  "out": "2023-12-21 17:17:06",
                  "usage": "00:01:20",
                  "idle": "00:03:00"
              },
              {
                  "appName": "Google Chrome",
                  "windowName": "Rao Conduct (Project Management Tool)",
                  "in": "2023-12-21 17:17:06",
                  "out": "2023-12-21 17:21:16",
                  "usage": "00:04:10",
                  "idle": "00:00:00"
              },
              {
                  "appName": "Windows Shell Experience Host",
                  "windowName": "Volume Control",
                  "in": "2023-12-21 17:21:16",
                  "out": "2023-12-21 17:21:26",
                  "usage": "00:00:10",
                  "idle": "00:00:00"
              }
          ],
          "name": "User5"
      }
  }
  }

  getEmployeeListjson(){
    return [
      {
          "id": 1000,
          "name": "User1",
          "url": "",
          "description": "",
          "link": "https:\/\/api.supersee.raoinfo.tech\/author\/akshay-linux\/",
          "slug": "akshay-linux",
          "avatar_urls": {
              "24": "https:\/\/secure.gravatar.com\/avatar\/83862d1e9449aee54ad8bb3a11632bbe?s=24&d=mm&r=g",
              "48": "https:\/\/secure.gravatar.com\/avatar\/83862d1e9449aee54ad8bb3a11632bbe?s=48&d=mm&r=g",
              "96": "https:\/\/secure.gravatar.com\/avatar\/83862d1e9449aee54ad8bb3a11632bbe?s=96&d=mm&r=g"
          },
          "meta": {
              "ss_device_token": "",
              "callingid": "ss16265699de147a4c",
              "team_lead": [],
              "team_manager": [],
              "team_developer": [],
              "settings": {
                  "ss_screen_capture_time": "00:30:00",
                  "ss_idle_time": "00:03:00",
                  "ss_auto_send_summry_time": "18:00:00",
                  "ss_appchange_interval": "00:01:30",
                  "ss_image_messages_discord_url": "https:\/\/discord.com\/api\/webhooks\/1140877148350074890\/lMj_qJSBoXPsEuDs8f7H80AdrvZw7g311x_gvRAXWIW5W_5eC64E1BaOJqCGfrjSiyZj",
                  "ss_status_messages_discord_url": "https:\/\/discord.com\/api\/webhooks\/1140877148350074890\/lMj_qJSBoXPsEuDs8f7H80AdrvZw7g311x_gvRAXWIW5W_5eC64E1BaOJqCGfrjSiyZj",
                  "ss_summary_messages_discord_url": "https:\/\/discord.com\/api\/webhooks\/1140877148350074890\/lMj_qJSBoXPsEuDs8f7H80AdrvZw7g311x_gvRAXWIW5W_5eC64E1BaOJqCGfrjSiyZj",
                  "ss_appchange": "true",
                  "ss_periodic": "true",
                  "ss_webcam": "true",
                  "ss_personalizeSettings": "true"
              },
              "last_screenshot": "",
              "last_application_name": ""
          },
          "role": [
              "employee"
          ],
          "_links": {
              "self": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users\/162"
                  }
              ],
              "collection": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users"
                  }
              ]
          }
      },
      {
          "id": 1001,
          "name": "User2",
          "url": "",
          "description": "",
          "link": "https:\/\/api.supersee.raoinfo.tech\/author\/ashish-linux\/",
          "slug": "ashish-linux",
          "avatar_urls": {
              "24": "https:\/\/secure.gravatar.com\/avatar\/f4aca4a970b7ce4b5ed0464c86f9ba7c?s=24&d=mm&r=g",
              "48": "https:\/\/secure.gravatar.com\/avatar\/f4aca4a970b7ce4b5ed0464c86f9ba7c?s=48&d=mm&r=g",
              "96": "https:\/\/secure.gravatar.com\/avatar\/f4aca4a970b7ce4b5ed0464c86f9ba7c?s=96&d=mm&r=g"
          },
          "meta": {
              "ss_device_token": "",
              "callingid": "ss16165699b9507eb2",
              "team_lead": [],
              "team_manager": [],
              "team_developer": [],
              "settings": [],
              "last_screenshot": "",
              "last_application_name": ""
          },
          "role": [
              "employee"
          ],
          "_links": {
              "self": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users\/161"
                  }
              ],
              "collection": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users"
                  }
              ]
          }
      },
      {
          "id": 1002,
          "name": "User3",
          "url": "",
          "description": "",
          "link": "https:\/\/api.supersee.raoinfo.tech\/author\/bhushan-kalwani\/",
          "slug": "bhushan-kalwani",
          "avatar_urls": {
              "24": "https:\/\/secure.gravatar.com\/avatar\/212e92857745de44831042d566c3a6fa?s=24&d=mm&r=g",
              "48": "https:\/\/secure.gravatar.com\/avatar\/212e92857745de44831042d566c3a6fa?s=48&d=mm&r=g",
              "96": "https:\/\/secure.gravatar.com\/avatar\/212e92857745de44831042d566c3a6fa?s=96&d=mm&r=g"
          },
          "meta": {
              "ss_device_token": "",
              "callingid": "ss13465463e6d744bf",
              "team_lead": [],
              "team_manager": [
                  185176
              ],
              "team_developer": [],
              "settings": {
                  "ss_image_messages_discord_url": "undefined",
                  "ss_status_messages_discord_url": "undefined",
                  "ss_summary_messages_discord_url": "undefined",
                  "ss_appchange": "undefined",
                  "ss_periodic": "undefined",
                  "ss_webcam": "undefined",
                  "ss_personalizeSettings": "false"
              },
              "last_screenshot": "",
              "last_application_name": ""
          },
          "role": [
              "employee",
              "manager"
          ],
          "_links": {
              "self": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users\/134"
                  }
              ],
              "collection": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users"
                  }
              ]
          }
      },
      {
          "id": 1003,
          "name": "User4",
          "url": "",
          "description": "",
          "link": "https:\/\/api.supersee.raoinfo.tech\/author\/dhaval-dashadia\/",
          "slug": "dhaval-dashadia",
          "avatar_urls": {
              "24": "https:\/\/secure.gravatar.com\/avatar\/e8f50239c1b82c79be1593fd2fe4d66f?s=24&d=mm&r=g",
              "48": "https:\/\/secure.gravatar.com\/avatar\/e8f50239c1b82c79be1593fd2fe4d66f?s=48&d=mm&r=g",
              "96": "https:\/\/secure.gravatar.com\/avatar\/e8f50239c1b82c79be1593fd2fe4d66f?s=96&d=mm&r=g"
          },
          "meta": {
              "ss_device_token": "",
              "callingid": "ss90651bdaaee5480",
              "team_lead": [],
              "team_manager": [],
              "team_developer": [
                  184348
              ],
              "settings": {
                  "ss_screen_capture_time": "00:05:00",
                  "ss_idle_time": "00:03:00",
                  "ss_auto_send_summry_time": "10:10:00",
                  "ss_appchange_interval": "00:00:14",
                  "ss_appchange": "true",
                  "ss_periodic": "true",
                  "ss_personalizeSettings": "true"
              },
              "last_screenshot": "",
              "last_application_name": ""
          },
          "role": [
              "employee"
          ],
          "_links": {
              "self": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users\/90"
                  }
              ],
              "collection": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users"
                  }
              ]
          }
      },
      {
          "id": 1004,
          "name": "User5",
          "url": "",
          "description": "",
          "link": "https:\/\/api.supersee.raoinfo.tech\/author\/dhaval-test\/",
          "slug": "dhaval-test",
          "avatar_urls": {
              "24": "https:\/\/secure.gravatar.com\/avatar\/086cd0b8e6b742b7aa86fdf96acb118c?s=24&d=mm&r=g",
              "48": "https:\/\/secure.gravatar.com\/avatar\/086cd0b8e6b742b7aa86fdf96acb118c?s=48&d=mm&r=g",
              "96": "https:\/\/secure.gravatar.com\/avatar\/086cd0b8e6b742b7aa86fdf96acb118c?s=96&d=mm&r=g"
          },
          "meta": {
              "ss_device_token": "",
              "callingid": "ss156655b4b9f1f1a7",
              "team_lead": [],
              "team_manager": [],
              "team_developer": [],
              "settings": {
                  "ss_image_messages_discord_url": "undefined",
                  "ss_status_messages_discord_url": "undefined",
                  "ss_summary_messages_discord_url": "undefined",
                  "ss_appchange": "undefined",
                  "ss_periodic": "undefined",
                  "ss_webcam": "undefined",
                  "ss_personalizeSettings": "false"
              },
              "last_screenshot": "",
              "last_application_name": ""
          },
          "role": [
              "employee",
              "manager"
          ],
          "_links": {
              "self": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users\/156"
                  }
              ],
              "collection": [
                  {
                      "href": "https:\/\/api.supersee.raoinfo.tech\/wp-json\/wp\/v2\/users"
                  }
              ]
          }
      }]
  }

  getTeamProjectJsonData(){
  return  [
      {
          "id": 242466,
          "date": "2023-11-29T12:38:43",
          "date_gmt": "2023-11-29T07:08:43",
          "guid": {
              "rendered": "https://api.supersee.raoinfo.tech/team/test-team/"
          },
          "modified": "2023-11-29T12:38:43",
          "modified_gmt": "2023-11-29T07:08:43",
          "slug": "test-team",
          "status": "publish",
          "type": "teams",
          "link": "https://api.supersee.raoinfo.tech/team/test-team/",
          "title": {
              "rendered": "Project1"
          },
          "author": 1,
          "featured_media": 0,
          "template": "",
          "meta": {
              "team_lead": "~96~",
              "team_manager": "~94~",
              "team_developer": ""
          },
          "_links": {
              "self": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams/242466"
                  }
              ],
              "collection": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams"
                  }
              ],
              "about": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/types/teams"
                  }
              ],
              "author": [
                  {
                      "embeddable": true,
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/users/1"
                  }
              ],
              "wp:attachment": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/media?parent=242466"
                  }
              ],
              "curies": [
                  {
                      "name": "wp",
                      "href": "https://api.w.org/{rel}",
                      "templated": true
                  }
              ]
          }
      },
      {
          "id": 190152,
          "date": "2023-11-16T19:40:11",
          "date_gmt": "2023-11-16T14:10:11",
          "guid": {
              "rendered": "https://api.supersee.raoinfo.tech/team/test-project/"
          },
          "modified": "2023-11-25T13:27:15",
          "modified_gmt": "2023-11-25T07:57:15",
          "slug": "test-project",
          "status": "publish",
          "type": "teams",
          "link": "https://api.supersee.raoinfo.tech/team/test-project/",
          "title": {
              "rendered": "Project2"
          },
          "author": 1,
          "featured_media": 0,
          "template": "",
          "meta": {
              "team_lead": "~113~",
              "team_manager": "~94~",
              "team_developer": "~144~94~127~"
          },
          "_links": {
              "self": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams/190152"
                  }
              ],
              "collection": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams"
                  }
              ],
              "about": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/types/teams"
                  }
              ],
              "author": [
                  {
                      "embeddable": true,
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/users/1"
                  }
              ],
              "wp:attachment": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/media?parent=190152"
                  }
              ],
              "curies": [
                  {
                      "name": "wp",
                      "href": "https://api.w.org/{rel}",
                      "templated": true
                  }
              ]
          }
      },
      {
          "id": 185176,
          "date": "2023-11-10T12:15:39",
          "date_gmt": "2023-11-10T06:45:39",
          "guid": {
              "rendered": "https://api.supersee.raoinfo.tech/team/trafie/"
          },
          "modified": "2023-11-10T12:15:39",
          "modified_gmt": "2023-11-10T06:45:39",
          "slug": "trafie",
          "status": "publish",
          "type": "teams",
          "link": "https://api.supersee.raoinfo.tech/team/trafie/",
          "title": {
              "rendered": "Project3"
          },
          "author": 1,
          "featured_media": 0,
          "template": "",
          "meta": {
              "team_lead": "~98~",
              "team_manager": "~134~",
              "team_developer": "~99~135~"
          },
          "_links": {
              "self": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams/185176"
                  }
              ],
              "collection": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams"
                  }
              ],
              "about": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/types/teams"
                  }
              ],
              "author": [
                  {
                      "embeddable": true,
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/users/1"
                  }
              ],
              "wp:attachment": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/media?parent=185176"
                  }
              ],
              "curies": [
                  {
                      "name": "wp",
                      "href": "https://api.w.org/{rel}",
                      "templated": true
                  }
              ]
          }
      },
      {
          "id": 184348,
          "date": "2023-11-10T10:43:16",
          "date_gmt": "2023-11-10T05:13:16",
          "guid": {
              "rendered": "https://api.supersee.raoinfo.tech/team/supersee/"
          },
          "modified": "2023-12-14T12:18:51",
          "modified_gmt": "2023-12-14T06:48:51",
          "slug": "supersee",
          "status": "publish",
          "type": "teams",
          "link": "https://api.supersee.raoinfo.tech/team/supersee/",
          "title": {
              "rendered": "Project4"
          },
          "author": 1,
          "featured_media": 0,
          "template": "",
          "meta": {
              "team_lead": "~96~",
              "team_manager": "~94~",
              "team_developer": "~90~151~88~127~"
          },
          "_links": {
              "self": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams/184348"
                  }
              ],
              "collection": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/teams"
                  }
              ],
              "about": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/types/teams"
                  }
              ],
              "author": [
                  {
                      "embeddable": true,
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/users/1"
                  }
              ],
              "wp:attachment": [
                  {
                      "href": "https://api.supersee.raoinfo.tech/wp-json/wp/v2/media?parent=184348"
                  }
              ],
              "curies": [
                  {
                      "name": "wp",
                      "href": "https://api.w.org/{rel}",
                      "templated": true
                  }
              ]
          }
      }
  ]
  }
}
