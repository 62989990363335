<mat-card class="screenshot-main-card">
  <div class="row ss-border-row">
    <div class="col s12 m4">
      <h2>Screenshots</h2>
    </div>
    <div class="col s12 m4" style="display: flex; justify-content: flex-end;flex-grow: 1;">
      <!-- <div class="search-bar">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
            fill="#B3B3B3"
          />
        </svg>
        <input matInput placeholder="Search here" />
      </div> -->

    </div>
    <div class="col s12 m4 ss-datepicker-contain">
      <mat-form-field class="date-range-picker-form-field ">

        <!-- <mat-form-field appearance="fill" class="col s2 m2 l2" style="width: 280px;"> -->
        <!-- <mat-label>Enter a date range</mat-label> -->

        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [value]="startDate" placeholder="Start date" (dateChange)="onStartDateChange($event)" />
          <input matEndDate [value]="endDate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
          <input matStartDate placeholder="Start date" />
          <input matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

  </div>
  <div class="container-fluid">
    <div *ngIf="loading">
      <div class="row">
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userSSList && userSSList.length; else elseBlock">
      <div class="col s6 m6 l6 xl4" *ngFor="let ss of userSSList; let i = index">
        <ng-container>
          <mat-card class="ss-card">
            <div class="row" style="margin-bottom: 16px">
              <div class="col s12" style="position: relative">
                <a class="screenshot_box waves-effect waves-light" href="{{ ss.large }}" data-fancybox="gallery">
                  <img src="{{ ss.large }}" alt="screenshot image" class="responsive-img ss-img" />
                </a>
                <div class="absolute-badge">
                  {{ trimName(ss.app_name | slice:0:30) }}
                </div>
              </div>
            </div>
            <div class="row avtar-badge-container">
              <div class="col s12 m6 l7 xl6 ss-card-profile-col" [routerLink]="['/user-details/', ss.user_id ]">
                <div>
                  <user-profile [user]="getUserfromUserList(ss.user_id)" [isName]="false"
                    [userImageClass]="userImageClass"></user-profile>
                </div>
                <div class="user-time">
                  <h4 class="user-name text-wrap">{{ getUserName(ss.user_id) }}</h4>
                  <!-- @if (!imageInfo.value.isShowTime) {
                    <p>
                      {{ imageInfo.value.captured_at }}
                    </p>
                    }
                    @if (imageInfo.value.isShowTime) {
                    <p>
                      {{ imageInfo.value.captured_time }}
                    </p>
                    } -->

                  @if (!checkTimeForImage(ss.captured_time)) {
                  <p>{{ ss.captured_at }}</p>
                  }
                  @if (checkTimeForImage(ss.captured_time)) {
                  <p>
                    {{ ss.captured_time }}
                  </p>
                  }
                </div>
              </div>
              <div class="col s12 m6 l5 xl6 badge-container">
                <div class="badge">{{ ss.type }}</div>
                <!-- <button mat-button [matMenuTriggerFor]="menu" style="min-width: auto;"><span class="material-symbols-outlined">
                more_vert
                </span></button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item class="dropdown-menu-button"><span class="material-symbols-outlined">
                    delete
                    </span>delete</button>
                
                </mat-menu> -->
              </div>
            </div>
          </mat-card>

          <!-- <div class="card">
          <div class="card-image">
            <div class="chip application-chip">
              {{ ss.app_name}}
            </div>
            <a class="screenshot_box waves-effect waves-light" href="{{ ss.large }}" data-fancybox="gallery">
              <img src="{{ ss.large }}" alt="screenshot image" class="responsive-img" />
            </a>
          </div>
          <div class="card-content">
            <div class="row" style="cursor: pointer; margin-bottom: 0px">
              <div class="col s7">
                <user-profile [user]="getUserfromUserList(ss.user_id)"></user-profile>
              </div>
              <div class="col s5" style="text-align: right;">
                <span *ngIf="ss.timeAgo" style="font-size: 16px;">{{ ss.captured_at | timeago : true }}</span>
                <span *ngIf="!ss.timeAgo" style="font-size: 16px;">{{ ss.captured_at }}</span><br>
                <b><span style="font-size: 12px;">{{ss.type}}</span></b>
              </div>
            </div>
          </div>
        </div> -->
        </ng-container>
      </div>
    </div>
    <ng-template #elseBlock [ngStyle]="{ display: !loading ? 'block' : 'none' }">
      <div class="empty_list z-depth-2">
        <img src="../../../assets/images/empty_list.png" alt="empty list image" />
      </div>
    </ng-template>

    <div *ngIf="userSSList && userSSList.length">
      <mat-paginator [length]="totalSS" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
        (page)="onPageChange($event)" #EmpPaginator>
      </mat-paginator>
    </div>
  </div>
</mat-card>