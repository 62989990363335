<div style="text-align: start">
  <!-- Display thumbnail -->
  <div (click)="tooltipShow = true; clickCount = 0" style="width: auto; display: flex; align-items: center;">
    <!-- <ngx-avatars name="{{ user.name }}" size="{{sizeAvatar}}"></ngx-avatars> -->
    <img [src]="user?.avatar_urls[96]" class="{{userImageClass}}" alt="Contact Person" />
    <span class="user-name text-wrap" *ngIf="isName == undefined || isName == true">{{user.name}}</span> 
  </div>
</div>
<ng-template #toolTipRef>
  <div style="max-width: 400px" class="userinfo-tooltip">
    <div class="user_avatar_box">
      <!-- Display large image -->
      <img [src]="user.avatar_urls[96]" />
      <!-- User Status -->
      <span
        *ngIf="!user.status || user.status === 'not-using'"
        class="status"
        style="background-color: orange"
      ></span>
      <span
        *ngIf="user.status && user.status === 'online'"
        class="status_block"
        class="status"
        style="background-color: green"
      ></span>
      <span
        *ngIf="
          user.status && user.status !== 'online' && user.status !== 'not-using'
        "
        class="status"
        style="background-color: red"
      ></span>
    </div>
    <div class="user_details_box">
      <div class="user-info">
        <span style="float: left; font-size: 20px; font-weight: bold;">
          {{ user.name }}
        </span>
        <span class="offline-ago"
          *ngIf="
            user.status && user.status !== 'online' && user.status !== 'not-using'
          "
        >
          {{ user.status | timeago : true }}
        </span>
      </div>
      <div class="row">
        <div class="col 4">
          <button
            class="btn"
            style="
              background: rgba(242, 242, 242, 0.5);
              color: black;
              display: flex;
              align-items: center;
              box-shadow: none;
            "
            (click)="openScreencastActionModel(user)"
          >
            <span
              class="material-symbols-outlined"
              id="screencast_request"
              [ngClass]="
                user.status && user.status == 'online' ? 'online' : 'offline'
              "
              matTooltip="Screenshot Request">screenshot_monitor</span
            >
          </button>
        </div>
        <div class="col 4">
          <button class="btn" [disabled]="user.status != 'online'" (click)="user.status && user.status == 'online' ? screenshotRequest(user.id, user.status, user.name): ''">
            <i
              [ngClass]="
                user.status && user.status == 'online' ? 'online' : 'offline'
              "
              matTooltip="Screencast Request"
              class="material-symbols-outlined"
              >live_tv</i
            >
          </button>
        </div>
        <div class="col 4">
          <button
            class="btn"
            style="
              background: rgba(242, 242, 242, 0.5);
              color: black;
              display: flex;
              align-items: center;
              box-shadow: none;
            "
            (click)="$event.stopPropagation(); userInfo()"
          >
            <span
              matTooltip="Employee Profile"
              [routerLink]="['/user-details/', user.id]"
              [ngClass]="'online'"
              class="material-symbols-outlined"
              >info</span
            >
          </button>
        </div>
      </div>
      <span>App Used : {{user.meta.last_application_name}}</span>
    </div>
  </div>
</ng-template>
