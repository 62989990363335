import {
  Component,
  OnInit,
  ChangeDetectorRef,
  SimpleChange,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "../../Services/login.service";
import { config } from "../../config";
//import { Socket } from "ngx-socket-io";
import { Observable, Observer, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import * as _ from "lodash";
import { TooltipPosition } from "@angular/material/tooltip";
import { element } from "protractor";
import { timeStamp } from "console";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { ProjectService } from "src/app/Services/project.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DisplayToastComponent } from "src/app/DisplayToast/display-toast/display-toast.component";
import { SharedService } from "src/app/Services/sharedService";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { MatPaginator } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";

declare var $;

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-screen-shot-viwer",
  templateUrl: "./screen-shot-viwer.component.html",
  styleUrls: ["./screen-shot-viwer.component.css"],
  providers: [DatePipe],

})
export class ScreenShotViwerComponent implements OnInit {

  @ViewChild("EmpPaginator", { static: false }) EmpPaginator: MatPaginator;

  userId;
  pageSize = 25;
  offset = 1;
  today = new Date();
  userSSList;
  totalSS = 0;
  userSSListDuplicate: any;
  filePath = config.baseMediaUrl;
  // private _docSub: Subscription;
  paginationArray = [];
  user: any;
  p: number = 1;
  maxDate = moment();
  statusObservable: Subscription;
  userStatus: any;
  loading = false;
  @Output() childEvent = new EventEmitter();
  private dbPath = localStorage.getItem('dbPath');

  @Input("userDetails") userDetails;
  @Input("startDate") startDateFilter: any;
  @Input("endDate") endDateFilter: any;
  @Input("status") status: any;

  requested: boolean = false;
  userList: any;
  durationInSeconds = 3;
  snackbarClass = "snackbarClass";
  usersRef: AngularFireList<User> = null;
  Status = "offline";
  filterOnData: any = [];
  screenShotDataList: any;
  showingErrorImage: any = false;
  timeAgo: true;
  startDate: any;
  endDate: any = new Date();
  userImageClass = 'avatar-img';
  userDataSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private _projectService: ProjectService,
    private snackBar: MatSnackBar,
    // private socket: Socket,
    private _change: ChangeDetectorRef,
    private db: AngularFireDatabase,
    private _sharedService: SharedService,
    private _messagingService: MessagingServiceService,
    private datePipe: DatePipe,
    private customtimeAgoPipe: CustomTimeAgoPipe
  ) {
    _messagingService.showNotification();
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    );
    const sub = this.route.params.subscribe((user) => {
      this.userId = user.userId;
    });
    this.getstatus();

    //this.socket.removeAllListeners();
  }

  ngOnChanges(changes: SimpleChange) {
    let change: any = changes;

    if (change.endDateFilter) {

      if (this.startDateFilter !== null && this.endDateFilter !== null) {
        // let newFilterData = this.filterScreenShots(
        //   this.startDateFilter,
        //   this.endDateFilter
        // );
        // this.userSSList = newFilterData;
      }
    }

    if (change.userDetails && change.userDetails.currentValue) {
      this.userId = change.userDetails.currentValue.id;
      this.user = change.userDetails.currentValue.name;
      this.getSSlistUser();
    }
  }
  onStartDateChange(event) {
    let date = event.value;
    this.startDate = date;

    // this.startDate = this.datePipe.transform(date, "yyyy-MM-dd");
  }

  onEndDateChange(event) {
    let date = event.value;
    this.endDate = date;
    // this.endDate = this.datePipe.transform(date, "yyyy-MM-ddTHH:mm:ss");
    if (this.startDate && this.endDate) {
      // let newFilterData = this.filterScreenShots(
      //   this.startDate,
      //   this.endDate
      // );
      // this.userSSList = newFilterData;
      this.pageSize = 25;
      this.offset = 1;
      if (this.EmpPaginator) {
        this.EmpPaginator.firstPage()
      }
      this.getSSlistUser();

    }
  }
  ngOnInit() {
    this.getUserList();
    this.getUserDetails();
    this.offset = this.EmpPaginator["_pageIndex"] + 1;

    // this.socket.on("getStatus", (id) => {
    //   console.log("id in screenshot viewer ===>", id);
    //   this.checkStatus(id);
    // });

    // console.log(this.socket.subscribersCounter);
    // this.socket.on("getRequestedScreenShot", (id) => {
    //   console.log("user id" , id);
    //   if (id && id.imagePath && id.imagePath.split("/")[0] == this.userId) {
    //     console.log("id", id.imagePath.split("/")[0]);
    //     console.log("this.userSSList", this.userSSList);
    //     let screenshot = {
    //       name: this.getFileName(id.imagePath),
    //       image: id.imagePath,
    //       datetime: this.getFileNameTime(id.imagePath),
    //       isNew: true,
    //     };
    //     this.requested = false;

    //     this.userSSList.unshift(screenshot);
    //     this.userSSList[1].isNew = false;
    //     const dup = JSON.parse(JSON.stringify(this.userSSList));
    //     this.userSSListDuplicate = dup;
    //     console.log("get updated screen shot", id);
    //     // setTimeout(() => {
    //     //   this.removeSpecialSS();
    //     // }, 5000);
    //   } else {
    //     if (id.video) {
    //       console.log("video received");
    //       // this.outputEvent.emit(id);
    //       this.childEvent.emit(id);
    //     } else {
    //       console.log("screenshot received but id not same");
    //     }
    //   }
    // });
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  getUserList() {
    this.loading = true;
    this._projectService.getUserList(0, 0).subscribe((user: any) => {
      this.userList = user;
      this.loading = false;
    });
  }

  getUserfromUserList(userInfo) {
    // console.log("userInfo :: ==>" , userInfo);
    let selectedUser = this.userList.filter((ele: any) => {
      return ele.id == parseInt(userInfo);
    });
    // console.log("selected user:" , selectedUser);
    return selectedUser[0];
  }

  getUserName(userId) {
    let selectedUser = this.userList.filter((ele: any) => {
      if (ele.id == parseInt(userId)) {
        return ele.name;
      }
    });
    return selectedUser[0].name;
  }

  getUserDetails() {
    this._projectService.getEmployeeData(this.userId).subscribe((data: any) => {
    });
  }
  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData()
  }
  getstatus() {
    this.usersRef = this.db.list(this.dbPath);
    // this.getAll().snapshotChanges().pipe(
    //   map(changes =>
    //     changes.map(c =>
    //       ({ key: c.payload.key, ...c.payload.val() })
    //     )
    //   )
    // )
    this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((o) => {
        if (o.id == this.userId) {
          if (o.status == "online") {
            this.Status = "online";
          } else {
            this.Status = "offline";
          }
        }
        // if (
        //   o.ssRequest !== false &&
        //   o.ssRequest !== null &&
        //   o.ssRequest !== undefined &&
        //   o.ssRequest !== true &&
        //   o.isreceive == true
        // ) {
        //   // this._projectService.updateToastData({ data: JSON.stringify(o.ssRequest),name:o.email})
        //   let data = {
        //     imgURL: o.ssRequest["imgURL"],
        //     createdAt: o.ssRequest["createdAt"],
        //     name: o.email,
        //   };

        //   this.db.database
        //     .ref(`${this.dbPath}`)
        //     .child(`${o.id}`)
        //     .update({ isreceive: false });
        //   console.log(o, o.ssRequest, "ooo");
        //   this._sharedService.showToast(data);
        // }
        if (o.id === this.userId && (this.offset == 0 || this.offset == 1) && (this.datePipe.transform(new Date(), "yyyy-MM-dd") == this.datePipe.transform(this.endDate, "yyyy-MM-dd"))) {

          let jsonData = {};
          if (
            o.ssRequest != false &&
            o.ssRequest != null &&
            o.isreceive == false
          ) {
            jsonData = {
              "large": o.ssRequest["largeImg"] ? o.ssRequest["largeImg"] : o.ssRequest["imgURL"],
              "thumbnail": o.ssRequest["imgURL"],
              "captured_at": this.customtimeAgoPipe.transform(o.ssRequest["createdAt"]),
              "user_id": o.id,
              "type": "admin-request",
              "app_name": o.ssRequest["applicationName"],
            }
            if (!this.userSSList.includes(jsonData)) {
              this.userSSList.unshift(jsonData)
            }
          }
          if (
            o.ssPeriodic != false &&
            o.ssPeriodic != null &&
            o.isreceive == false
          ) {
            jsonData = {
              "large": o.ssPeriodic["largeImg"] ? o.ssPeriodic["largeImg"] : o.ssPeriodic["imgURL"],
              "thumbnail": o.ssPeriodic["imgURL"],
              "captured_at": this.customtimeAgoPipe.transform(o.ssPeriodic["createdAt"]),
              "user_id": o.id,
              "type": "Periodic",
              "app_name": o.ssPeriodic["applicationName"]
            }
            if (!this.userSSList.includes(jsonData)) {
              this.userSSList.unshift(jsonData)
            }
          }
          if (
            o.ssAppChanged != false &&
            o.ssAppChanged != null &&
            o.isreceive == false
          ) {
            jsonData = {
              "large": o.ssAppChanged["largeImg"] ? o.ssAppChanged["largeImg"] : o.ssAppChanged["imgURL"],
              "thumbnail": o.ssAppChanged["imgURL"],
              "captured_at": this.customtimeAgoPipe.transform(o.ssAppChanged["createdAt"]),
              "user_id": o.id,
              "type": "app-changed",
              "app_name": o.ssAppChanged["applicationName"]
            }
            if (!this.userSSList.includes(jsonData)) {
              this.userSSList.unshift(jsonData)
            }
          }

          //this.getSSlistUser();

        }
      });
    });
  }

  getSSlistUser() {
    this.loading = true;
    // this._projectService.getAnalysisCategories().subscribe(
    //   (res: any) => {
    // console.log("res of photo ==>", res);

    // let resData = res.filter((data: any) => {
    //   return data.name == "Screenshots";
    // });
    // console.log(resData[0].id, "resData");
    this._projectService.getSingleSS(this.userId, this.pageSize, this.offset, this.datePipe.transform(this.startDate, "yyyy-MM-dd") + 'T00:00:00', this.datePipe.transform(this.endDate, "yyyy-MM-dd") + 'T23:59:59').subscribe(
      (res: any) => {
        // console.log("res of photo ==>", res);
        this.userSSList = undefined;
        this.userSSList = [];
        if (res.length == 0) {
          this.loading = false;
          this.showingErrorImage = true;
        }
        let userData: any = res.body;
        for (let i = 0; i <= userData.length - 1; i++) {
          let imageData = userData[i];
          ///console.log(userData[i] , "user imagedata")
          for (let key in imageData) {
            if (key != "_links") {
              if (moment().unix() - moment(imageData[key]['captured_at'], "YYYY-MM-DD HH:mm:ss").unix() < 86400) {
                imageData[key]['timeAgo'] = true;
              }
              else {
                imageData[key]['timeAgo'] = false;
              }
              imageData[key].captured_time = imageData[key].captured_at;
              imageData[key].captured_at = this.customtimeAgoPipe.transform(imageData[key].captured_at);
              this.userSSList.push(imageData[key]);
              this.filterOnData.push(imageData[key]);
            }
          }
          for (let i = 0; i <= imageData.length; i++) {
          }
        }
        this.loading = false;
        if (!this.userSSList.length) {
          this.loading = false;
          this.showingErrorImage = true;
        }
        //  this.userSSList = res;
        this.requested = false;
        this.totalSS = res.headers.get('X-WP-Total');
      },
      (err) => {
        this.userSSList = [];
        this.loading = false;
        this.showingErrorImage = true;
      }
    );
    //this.PhotoList = res["files"].reverse();
    //   },
    //   (err) => {
    //     this.loading = false;

    //     console.log("err of group ==>", err);
    //   }
    // );

    // this.loginService.getSSListOfUser(this.userId).subscribe(
    //   (ssList: any) => {
    //     console.log("SS list =====>", ssList);
    //     let screenShotList = [];

    //     ssList.files.forEach((element) => {
    //       // console.log(" ScreenShots ", element);
    //       let screenshot = {
    //         name: this.getFileName(element),
    //         image: element,
    //         datetime: this.getFileNameTime(element),
    //       };

    //       screenShotList.push(screenshot);
    //     });

    //     // Sort the array based on datetime
    //     screenShotList = screenShotList.sort(this.compareDatetime);

    //     console.log("SS list =====>", screenShotList);

    //     this.userSSList = screenShotList.reverse();
    //     const dup = JSON.parse(JSON.stringify(this.userSSList));
    //     this.userSSListDuplicate = dup;

    //     let i = 1;
    //     while (i <= Math.ceil(this.userSSList.length / 10)) {
    //       this.paginationArray.push(i);
    //       i++;
    //     }

    //     console.log(Math.ceil(this.userSSList.length / 10));
    //     this.loading = false;
    //   },
    //   (error) => {
    //     this.loading = false;
    //     console.log(error);
    //   }
    // );
  }
  displayUserName(id) {
    let resData = this.userList.filter((data: any) => {
      return data.id == id;
    });
    return resData.length ? resData[0].name : "";
  }
  compareDatetime(a, b) {
    return a.datetime - b.datetime;
  }

  getFileName(ss, index?) {
    // console.log("ss ======>", ss, index);
    if (ss && ss.path) ss = ss.path;
    let name = ss.split("/")[1];
    return `${name.split("-")[4]}:${name.split("-")[5]}  ${name.split("-")[1]
      }/${name.split("-")[2]}/${name.split("-")[3]}`;
  }

  getFileNameTime(ss) {
    // console.log("ss ======>", ss);
    if (ss && ss.path) ss = ss.path;
    let name = ss.split("/")[1];

    let date = `${name.split("-")[2]}/${name.split("-")[1]}/${name.split("-")[3]
      }`;

    let time = `${name.split("-")[4]}:${name.split("-")[5]}`;

    let timeStamp = new Date(`${date} ${time}`);

    return timeStamp;
  }

  requestScreenShot(userId) {
    // this.loginService.requestScreenShot({ user: userId });\
    let data = {
      appName: "Requesting Screenshot",
      name: this.user,
    };
    this._sharedService.showToast(data);
    this.db.database
      .ref(`${this.dbPath}`)
      .child(`${userId}`)
      .update({ ssRequest: null });
    this.requested = true;

    // setTimeout(() => {
    //   this.requested = false;
    // }, 1000);
  }

  removeSpecialSS() {
    if (this.userSSList[0].path) {
      let path = this.userSSList[0].path;
      this.userSSList.splice(0, 1);
      this.userSSList.unshift(path);
      const dup = JSON.parse(JSON.stringify(this.userSSList));
      this.userSSListDuplicate = dup;
    }
  }

  selectDate(event: any) {
    if (event.end) {
      let startDate = event.start._d;
      let endDate = event.end._d;
      // console.log("eeven t======>", event.end._d, event.start._d);
      let date = [];
      while (moment(startDate) <= moment(endDate)) {
        startDate = moment(startDate).utcOffset("+5:30").format("YYYY-MM-DD");
        let reverse = startDate.split("-").reverse().join("-");
        date.push(reverse);
        startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
      }
      this.filterScreenShot({ dateRange: date });
      // console.log(date);
    }
  }
  filterScreenShot(params) {
    let filteredSS = [];
    _.forEach(this.userSSListDuplicate, (singleSS, ssIndex) => {
      _.forEach(params.dateRange, (singleDate, dateIndex) => {
        // console.log(singleSS.includes(singleDate));
        if (singleSS.includes(singleDate)) {
          filteredSS.push(singleSS);
        }
      });
    });

    this.userSSList = filteredSS;
    let i = 1;
    while (i <= Math.ceil(this.userSSList.length / 10)) {
      this.paginationArray.push(i);
      i++;
    }
  }

  clearDatePicker() {
    this.userSSList = this.userSSListDuplicate;
    let i = 1;
    while (i <= Math.ceil(this.userSSList.length / 10)) {
      this.paginationArray.push(i);
      i++;
    }
    // console.log($('#dateRangePicker').val());
    $("#dateRangePicker").val(null);
  }

  handlePage(event) {
  }

  userName(event) {
    (event);
    this.user = event;
  }

  checkStatus(params) {
    if (params.user == this.userId && params.status) {
      this.userStatus = params.status;
    } else {
      this.userStatus = "offline";
    }
    // this._change.detectChanges();
  }

  filterScreenShots(startDate: any, endDate: any) {
    let filteredData: any = [];
    const startDateTimestamp = new Date(startDate).getDate();
    const endDateTimestamp = new Date(endDate).getDate();
    filteredData = this.filterOnData.filter((ele) => {
      const capturedAtTimestamp = new Date(ele["captured_at"]).getDate();
      if (
        capturedAtTimestamp >= startDateTimestamp &&
        capturedAtTimestamp <= endDateTimestamp
      ) {
        return ele;
      }
    });
    return filteredData;
  }

  onPageChange(event) {
    this.pageSize = this.EmpPaginator["_pageSize"];
    this.offset = this.EmpPaginator["_pageIndex"] + 1;
    this.getSSlistUser();
  }

  convertLowerCase(str) {
    return str.toLowerCase()
  }

  trimName(name) {
    const trimmedName = name.length <= 20 ? name : name.slice(0, 20) + (name.length > 20 ? '...' : '');
    return trimmedName;
  }

  checkTimeForImage(time: string) {
    const inputTime = new Date(time);
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - inputTime.getTime();
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);
    return timeDifferenceInMinutes > 10;
  }
}
