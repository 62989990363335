import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../Services/project.service';
import swal from 'sweetalert2';
import { ActivatedRoute, CanDeactivate, Router, UrlTree } from '@angular/router';
import { DatePipe, ViewportScroller } from '@angular/common';
import { SharedService } from 'src/app/Services/sharedService';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogTemplateComponent } from 'src/app/dialog-template/dialog-template/dialog-template.component';
import { AddEmailComponent } from 'src/app/addEmail/add-email/add-email.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { time24FormatValidator } from 'src/app/utils/time24.validator';
@Component({
  selector: 'app-user-setting-panel',
  templateUrl: './user-setting-panel.component.html',
  styleUrls: ['./user-setting-panel.component.css'],
  providers: [DatePipe],
})

export class UserSettingPanelComponent {

  isFieldDisabled = true;
  globalSettings: FormGroup
  anotherSettings;
  totalUser = 0;
  availableUser = 0;
  useUser = 0;
  isAdmin = true;
  globalSettingsData: any
  userId;
  isPersonalized = true;
  isPeriodic = true;
  isAppChange = true;
  userName = '';
  quotaStatus = '';
  quota_expired_date;
  isSearchDiv = false;
  searchData = [
  ]
  addEmailArr = [];
  availableData = [];
  filterSearchData = this.searchData;
  txtSettings = ' settings';
  constructor(
    public formBuilder: FormBuilder,
    public _projectService: ProjectService,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private _sharedServices: SharedService,
    private scroller: ViewportScroller,
    private dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.getDevList();
    this.getUSer()
    this._route.params.subscribe((params) => {

      this.userId = params.userId
    })
  }
  onGroupSelect() {
    this.availableData = this.globalSettings.get('summary_Search').value;
  }
  deleteSummaryItem(user) {

    const summarySearchControl = this.globalSettings.get('summary_Search').value;

    const index = summarySearchControl.indexOf(user);

    if (index !== -1) {
      summarySearchControl.splice(index, 1);
      this.availableData = summarySearchControl;
      this.globalSettings.get('summary_Search').patchValue(summarySearchControl);
    }
  }
  deleteSummaryAddEmailItem(user) {

    const index = this.addEmailArr.indexOf(user);

    if (index !== -1) {
      this.addEmailArr.splice(index, 1);
    }
  }
  addNewEmail() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.minWidth = 400;

    const dialogRef = this.dialog.open(AddEmailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        if (this.addEmailArr.length > 0) {
          const index = this.addEmailArr.indexOf(result);

          if (index == -1) {
            this.addEmailArr.push(result)
          }
        } else {
          this.addEmailArr.push(result)
        }
      }
    })
  }
  getDevList() {
    this._projectService
      .getUserList(1, 100)
      .subscribe((user: any) => {
        this.searchData = [];
        this.searchData = user;
        this.filterSearchData = this.searchData;
      });
  }
  onKey(value?: any) {
    this.isSearchDiv = true;
    this.searchData = this.filterSearchData;
    let svalue = value.toUpperCase();
    this.searchData = this.searchData.filter((ele) => {
      return ele.email.toUpperCase().includes(svalue);
    });
    if (value == "") {
      this.isSearchDiv = false;
      this.searchData = this.filterSearchData;
    }
  }
  selectSummaryEmail(strEmail) {
    this.globalSettings.get('summary_Search').setValue(strEmail);
    this.isSearchDiv = false;
  }
  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      console.log(user.role)
      const roleArr = user.role;

      let tlRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("lead"));
      })
      if (tlRole.length == 0) {
        if (this.userId) {
          this.getUserDetails();
        } else {
          this.getGlobalSettings()
        }
        this.createGlobalSettingsForm()
        this._sharedServices.settingScroller().subscribe((result: any) => {
          this.scroller.scrollToAnchor(result);
        });
      } else {
        this._router.navigateByUrl('dashboard');
        return;
      }
    }
  }
  getUserDetails() {
    this._projectService.getEmployeeData(this.userId).subscribe(
      (data: any) => {
        this.userName = data.name + "'s";
        if (Array.isArray(data.meta.settings)) {
          if (!data.meta.settings.length) {
            this.getGlobalSettings()
          } else {
            this.anotherSettings = data.meta.settings;
            this.globalSettingsData = data.meta.settings;
            this.createGlobalSettingsForm()
          }
        } else {
          this.anotherSettings = data.meta.settings;
          this.globalSettingsData = data.meta.settings;
          this.createGlobalSettingsForm()
        }
      },
      (err) => {
        this._router.navigateByUrl('dashboard');
        this._snackBar.open(err.error.message, 'Undo', {
          duration: 3000
        })

      }
    );
  }

  isPeriodicToggleChange(event) {
    const ssScreenCaptureTimeControl = this.globalSettings.get('ss_screen_capture_time');
    if (!event.checked) {
      this.isPeriodic = true;
      ssScreenCaptureTimeControl.disable();
    } else {
      this.isPeriodic = false;
      ssScreenCaptureTimeControl.enable();
    }
  }
  isAppChangeToggleChange(event) {
    const ss_appchange_interval = this.globalSettings.get('ss_appchange_interval');
    if (!event.checked) {
      this.isAppChange = true;
      ss_appchange_interval.disable();
    } else {
      this.isAppChange = false;
      ss_appchange_interval.enable();
    }
  }
  isPersonalizesToggleChange(event) {

    const ssScreenCaptureTimeControl = this.globalSettings.get('ss_screen_capture_time');
    const ss_idle_time = this.globalSettings.get('ss_idle_time');
    const ss_auto_send_summry_time = this.globalSettings.get('ss_auto_send_summry_time');
    const ss_appchange_interval = this.globalSettings.get('ss_appchange_interval');

    const ss_image_messages_discord_url = this.globalSettings.get('ss_image_messages_discord_url');
    const ss_status_messages_discord_url = this.globalSettings.get('ss_status_messages_discord_url');
    const ss_summary_messages_discord_url = this.globalSettings.get('ss_summary_messages_discord_url');

    const ss_periodic = this.globalSettings.get('ss_periodic');
    const ss_appchange = this.globalSettings.get('ss_appchange');
    const ss_webcam = this.globalSettings.get('ss_webcam');
    const ss_silent = this.globalSettings.get('ss_silent');
    const ss_strict_time_tracking = this.globalSettings.get('ss_strict_time_tracking');

    if (!event.checked) {
      this.isPersonalized = true;
      ssScreenCaptureTimeControl.disable();
      ss_idle_time.disable();
      ss_auto_send_summry_time.disable();
      ss_appchange_interval.disable();

      ss_image_messages_discord_url.disable();
      ss_status_messages_discord_url.disable();
      ss_summary_messages_discord_url.disable();

      ss_periodic.disable();
      ss_appchange.disable();
      ss_webcam.disable();
      ss_silent.disable();
      ss_strict_time_tracking.disable();

    } else {
      this.isPersonalized = false;
      if (this.isPeriodic) {
        ssScreenCaptureTimeControl.disable();
      } else {
        ssScreenCaptureTimeControl.enable();
      }
      if (this.isAppChange) {
        ss_appchange_interval.disable();
      } else {
        ss_appchange_interval.enable();
      }
      ss_idle_time.enable();
      ss_auto_send_summry_time.enable();

      ss_image_messages_discord_url.enable();
      ss_status_messages_discord_url.enable();
      ss_summary_messages_discord_url.enable();

      ss_periodic.enable();
      ss_appchange.enable();
      ss_webcam.enable();
      ss_silent.enable();
      ss_strict_time_tracking.enable();
    }

  }
  // FORM CONTROL

  createGlobalSettingsForm() {
    if (this.userId) {

      let setting = (this.globalSettingsData && this.globalSettingsData.ss_personalizeSettings !== undefined)
        ? this.globalSettingsData.ss_personalizeSettings.toLowerCase() == 'true'
        : false
      if (setting) {
        this.isPersonalized = false;
      } else {
        this.isPersonalized = true;
      }
    } else {
      this.isPersonalized = false;
    }

    let periodic = (this.globalSettingsData && this.globalSettingsData.ss_periodic !== undefined)
      ? this.globalSettingsData.ss_periodic.toLowerCase() == 'true'
      : false;
    if (periodic) {
      this.isPeriodic = false;
    } else {
      this.isPeriodic = true;
    }

    let appChange = (this.globalSettingsData && this.globalSettingsData.ss_appchange !== undefined)
      ? this.globalSettingsData.ss_appchange.toLowerCase() == 'true'
      : false;
    if (appChange) {
      this.isAppChange = false;
    } else {
      this.isAppChange = true;
    }

    this.globalSettings = this.formBuilder.group({
      ss_screen_capture_time: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_screen_capture_time) ? this.globalSettingsData.ss_screen_capture_time : '',
        disabled: this.isPersonalized || this.isPeriodic,
      }, [this.timeValidator, time24FormatValidator()]),
      ss_idle_time: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_idle_time) ? this.globalSettingsData.ss_idle_time : '',
        disabled: this.isPersonalized
      }, [this.timeValidator, time24FormatValidator()]),
      ss_auto_send_summry_time: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_auto_send_summry_time) ? this.globalSettingsData.ss_auto_send_summry_time : '',
        disabled: this.isPersonalized
      }),
      ss_appchange_interval: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_appchange_interval) ? this.globalSettingsData.ss_appchange_interval : '',
        disabled: this.isPersonalized || this.isAppChange
      }, [this.timeValidator, time24FormatValidator()]),

      ss_image_messages_discord_url: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_image_messages_discord_url) ? this.globalSettingsData.ss_image_messages_discord_url : '',
        disabled: this.isPersonalized
      }),
      ss_status_messages_discord_url: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_status_messages_discord_url) ? this.globalSettingsData.ss_status_messages_discord_url : '',
        disabled: this.isPersonalized
      }),
      ss_summary_messages_discord_url: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_summary_messages_discord_url) ? this.globalSettingsData.ss_summary_messages_discord_url : '',
        disabled: this.isPersonalized
      }),

      ss_periodic: new FormControl({
        value: (this.globalSettingsData && this.globalSettingsData.ss_periodic !== undefined)
          ? this.globalSettingsData.ss_periodic.toLowerCase() == 'true'
          : false,
        disabled: this.isPersonalized
      }
      ),
      ss_appchange: new FormControl({
        value:
          (this.globalSettingsData && this.globalSettingsData.ss_appchange !== undefined)
            ? this.globalSettingsData.ss_appchange.toLowerCase() == 'true'
            : false,
        disabled: this.isPersonalized
      }
      ),
      ss_webcam: new FormControl(
        {
          value:
            (this.globalSettingsData && this.globalSettingsData.ss_webcam !== undefined)
              ? this.globalSettingsData.ss_webcam.toLowerCase() == 'true'
              : false,
          disabled: this.isPersonalized
        }
      ),
      ss_silent: new FormControl(
        {
          value: (this.globalSettingsData && this.globalSettingsData.ss_silent !== undefined)
            ? this.globalSettingsData.ss_silent.toLowerCase() == 'true'
            : false,
          disabled: this.isPersonalized

        }
      ),
      ss_strict_time_tracking: new FormControl(
        {
          value: (this.globalSettingsData && this.globalSettingsData.ss_strict_time_tracking !== undefined)
            ? this.globalSettingsData.ss_strict_time_tracking.toLowerCase() == 'true'
            : true,
          disabled: this.isPersonalized
        }
      ),
      ss_personalizeSettings: new FormControl(
        (this.globalSettingsData && this.globalSettingsData.ss_personalizeSettings !== undefined)
          ? this.globalSettingsData.ss_personalizeSettings.toLowerCase() == 'true'
          : false
      ),
      summary_Interval: new FormControl('00:20:00'
      ),
      summary_Search: new FormControl('')
    })
    this.isPersonalized = (this.globalSettingsData && this.globalSettingsData.ss_personalizeSettings !== undefined)
      ? this.globalSettingsData.ss_personalizeSettings.toLowerCase() == 'true'
      : false
  }

  strictTimeWarning(event: any) {
    console.log("strict time event: ", event)
    if (!event.checked) {
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Disabling this setting will allow users to manually adjust their time entries. This may result in inaccurate time tracking.',
      })
    }
    else {
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Enabling this setting will Logout user automatically if user changes the time manually.',
      })
    }
  }

  // API CALL

  timeValidator(timeValue) {
    const value = timeValue.value;
    const [hours, minutes] = value.split(':').map(Number);
    const totalNumberOfMinutes = (hours * 60) + minutes;
    return totalNumberOfMinutes < 1 || value == '' ? { "invalidTime": true } : null;
  }

  // validator for 30 seconds
  secondValidator(timeValue) {
    const value = timeValue.value;
    const [hours, minutes, seconds] = value.split(':').map(Number);
    const totalNumberOfMinutes = (hours * 60 * 60) + (minutes * 60) + seconds;
    return totalNumberOfMinutes < 30 ? { "invalidTime": true } : null;
  }

  submitGlobalConfig() {
    if (this.globalSettings.invalid) {
      this.globalSettings.markAllAsTouched()
      swal.fire({
        icon: 'error',
        title: 'Please Fill information validly.',
        timer: 2000,
        showConfirmButton: false,
      })
      return
    } else {
      this.globalSettings.value.ss_screen_capture_time = !this.globalSettings.get('ss_screen_capture_time').disabled ? this.globalSettings.value.ss_screen_capture_time.split(":").length == 2 ? this.globalSettings.value.ss_screen_capture_time + ":00" : this.globalSettings.value.ss_screen_capture_time : '';
      this.globalSettings.value.ss_idle_time = !this.globalSettings.get('ss_idle_time').disabled ? this.globalSettings.value.ss_idle_time.split(":").length == 2 ? this.globalSettings.value.ss_idle_time + ":00" : this.globalSettings.value.ss_idle_time : '';
      this.globalSettings.value.ss_appchange_interval = !this.globalSettings.get('ss_appchange_interval').disabled ? this.globalSettings.value.ss_appchange_interval.split(":").length == 2 ? this.globalSettings.value.ss_appchange_interval + ":00" : this.globalSettings.value.ss_appchange_interval : '';
      let settingVal;
      const ssScreenCaptureTimeControl = this.globalSettings.get('ss_personalizeSettings');

      if (this.userId && ssScreenCaptureTimeControl.value == false || ssScreenCaptureTimeControl.value == 'false') {
        if (this.anotherSettings.ss_personalizeSettings) {
          this.anotherSettings['ss_personalizeSettings'] = 'false';
          settingVal = this.anotherSettings;
        } else {
          settingVal = this.anotherSettings;
        }
      } else {
        settingVal = this.globalSettings.value;
        console.log("settingVal", settingVal)

        if (this.isAppChange) {
          settingVal['ss_appchange_interval'] = this.globalSettings.value.ss_appchange_interval ? this.globalSettings.value.ss_appchange_interval : this.anotherSettings.ss_appchange_interval ? this.anotherSettings.ss_appchange_interval : '';
        }
        if (this.isPeriodic) {
          settingVal['ss_screen_capture_time'] = this.globalSettings.value.ss_screen_capture_time ? this.globalSettings.value.ss_screen_capture_time : this.anotherSettings.ss_screen_capture_time ? this.anotherSettings.ss_screen_capture_time : '';
        }
        console.log(this.isAppChange, "isapp")
      }
      this._projectService.setGlobalSettings(settingVal, this.userId).subscribe((res: any) => {

        if (this.userId) {
          this.getUserDetails();
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Personalize Settings Updated Successfully',
            timer: 2000,
            showConfirmButton: false,
          })
        } else {
          this.getGlobalSettings()
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Global Settings Updated Successfully',
            timer: 2000,
            showConfirmButton: false,
          })
        }

        // this.getGlobalSettings()
      }, error => {
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message,
          timer: 2000,
          showConfirmButton: false,
        })
      })
    }
  }

  formatTime() {


  }

  getGlobalSettings() {
    this._projectService.getGlobalSettings().subscribe((res: any) => {
      this.globalSettingsData = res;
      this.anotherSettings = res;
      this.createGlobalSettingsForm()
    })
  }

  getUSer() {
    let roleArr = [];
    this._projectService.getUser().subscribe((data: any) => {
      localStorage.setItem('user', JSON.stringify(data))
      let isExpired = this._sharedServices.checkPlanExpired(data)
      if (isExpired) {
        return this._router.navigateByUrl('/plan-expired');
      }
      roleArr = data.role;
      let quota_Status = data.meta.quota_status;
      if (quota_Status == 'active' || quota_Status == 'Active' || quota_Status == 'trial' || quota_Status == 'Trial') {
        this.quotaStatus = 'active';
        if (data.meta.expires_at) {
          let day_left = this.getRemainingDate(data.meta.expires_at)
          if (day_left < 0) {
            this.quotaStatus = 'expired';
          }
        }
      }
      if (quota_Status == 'trial-expired' || quota_Status == 'Trial-expired' || quota_Status == 'expired' || quota_Status == 'Expired') {
        this.quotaStatus = 'expired';
      }
      if (data.meta.expires_at) {
        this.quota_expired_date = this.formattedDate(data.meta.expires_at);
      }
      this.totalUser = data.meta.quota_allowed < 0 ? 0 : data.meta.quota_allowed;
      this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
      this.useUser = (data.meta.quota_allowed - this.availableUser) < 0 ? 0 : (data.meta.quota_allowed - this.availableUser)
      this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;

      let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if (adminRole.length == 0) {
        this.isAdmin = false;
      } else {
        this.isAdmin = true;
      }
    })
  }

  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  formattedDate(expiresAt): string {
    const parsedDate = new Date(expiresAt);
    const formattedDate = this.datePipe.transform(parsedDate, 'dd MMM, yyyy');

    return formattedDate || '';
  }
  getRemainingDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    let daysRemaining = Math.round(daysDifference);
    return daysRemaining;
  }
}
@Injectable({
  providedIn: 'root'
})
export class FormGuard implements CanDeactivate<UserSettingPanelComponent> {
  constructor(public _dialog: MatDialog) {
  }
  canDeactivate(
    component: UserSettingPanelComponent
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.globalSettings.dirty) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.autoFocus = true;

      dialogConfig.minWidth = 400;

      const dialogRef = this._dialog.open(DialogTemplateComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          if (component.userId) {
            component.getUserDetails();
          } else {
            component.getGlobalSettings()
          }
          component.createGlobalSettingsForm()
        } else {
          component.submitGlobalConfig();
        }

      });
      return false;
    } else {
      return true;
    }
  }
}