<div class="row" *ngIf="isDashboard" style="padding: 0px 35px; margin: 0px;">
  <div *ngIf="!isDataWebsiteUsage">
    <div class="col s12 m12 l12 extraLarge6" *ngIf="ArrFirstlabels.length">
      <div *ngFor="let item of ArrFirstlabels;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div id="progress_bar" class="progressDiv addMargin"
              [tooltipDirective]="{ appName: item.appName, star_date: starDate, end_date: endDate, type: 'app'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor1[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l12" *ngIf="!ArrFirstlabels.length">
      <div class="row chartLabel">
        <div class="col s12" style="text-align: center;">No Application Usage - 00:00h</div>
      </div>
    </div>
    <div class="col s12 m12 l12 extraLarge6">
      <div *ngFor="let item of ArrSecondlabels;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div class="progressDiv addMargin" id="progress_bar"
              [tooltipDirective]="{ appName: item.appName, star_date: starDate, end_date: endDate, type: 'app'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor2[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isDataWebsiteUsage">
    <div class="col s12 m12 l12 extraLarge6" *ngIf="ArrFirstLabelsWebsiteUsageChart.length">
      <div *ngFor="let item of ArrFirstLabelsWebsiteUsageChart;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div id="progress_bar" class="progressDiv addMargin"
              [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate, type: 'website'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor1[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l12" *ngIf="!ArrFirstLabelsWebsiteUsageChart.length">
      <div class="row chartLabel">
        <div class="col s12" style="text-align: center;">No Website Usage - 00:00h</div>
      </div>
    </div>
    <div class="col s12 m12 l12 extraLarge6">
      <div *ngFor="let item of ArrSecondLabelsWebsiteUsageChart;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div class="progressDiv addMargin" id="progress_bar"
              [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate, type: 'website'}" placement="bottom"
              delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor2[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row chart-label-container" *ngIf="!isDashboard" style="width: 100%;">
  <div *ngIf="isDataWebsiteUsage">
    <div class="col xl12 s12 m12" *ngIf="displayForWebsiteUsageChart.length">
      <div [ngClass]="{'pieChart-lables': gridTemplate}">
        <div *ngFor="let item of displayForWebsiteUsageChart;let index = index"
          [ngClass]="{'chartLabelsForGrid': gridTemplate, 'chartLabelVal' : !gridTemplate}"
          [style.width]="'calc(100% / '+ grid +')'">
          <div class="row chart-label-contain">
            <div class="col xl2 s2 m2 chart-label-dot-contain">
              <div
                [ngStyle]="{'width': '14px','background-color': BarColor[index],'height': '14px','text-align': 'end','border-radius':'50%'}">
              </div>
            </div>
            <div class="col xl6 s6 m6 app-name" matTooltip="{{item.appName}}" [ngStyle]="{'color':BarColor[index]}">
              {{item.appName}}
            </div>
            <div class="col xl4 s4 m4 hours">
              {{timeToHours(item.usageTime)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12" *ngIf="!displayForWebsiteUsageChart.length">
      <div class="row chartLabelVal">
        <div class="col s2">
          <div
            [ngStyle]="{'width': '20px','background-color':' #f1f1f4','height': '20px','text-align': 'end','border-radius':'50%'}">
          </div>
        </div>
        <div class="col s10" style="color: #8B8BA7;">No Website Usage</div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDataWebsiteUsage">
    <div class="col xl12 s12 m12" *ngIf="display.length">
      <div [ngClass]="{'pieChart-lables': gridTemplate}">
        <div *ngFor="let item of display;let index = index"
          [ngClass]="{'chartLabelsForGrid': gridTemplate, 'chartLabelVal' : !gridTemplate}"
          [style.width]="'calc(100% / '+ grid +')'">
          <div class="row chart-label-contain">
            <div class="col xl2 s2 m2 chart-label-dot-contain">
              <div
                [ngStyle]="{'width': '14px','background-color': BarColor[index],'height': '14px','text-align': 'end','border-radius':'50%'}">
              </div>
            </div>
            <div class="col xl6 s6 m6 app-name" matTooltip="{{item.appName}}" [ngStyle]="{'color':BarColor[index]}">
              {{item.appName}}
            </div>
            <div class="col xl4 s4 m4 hours">
              {{timeToHours(item.usageTime)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12" *ngIf="!display.length">
      <div class="row chartLabelVal">
        <div class="col xl2 s2 m2">
          <div
            [ngStyle]="{'width': '20px','background-color':' #f1f1f4','height': '20px','text-align': 'end','border-radius':'50%'}">
          </div>
        </div>
        <div class="col xl10 s10 m10" style="color: #8B8BA7;">No used application</div>
      </div>
    </div>
  </div>
</div>