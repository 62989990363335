import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import * as Chart from "chart.js";
import { ProjectService } from "../Services/project.service";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.css"],
})
export class ChartComponent implements OnInit {
  @ViewChild("myChart", { static: true })
  myChart: ElementRef<HTMLCanvasElement>;
  chart: Chart;
  @Input("data") data;
  @Input("labels") labels;
  @Input("total") total;
  constructor(private renderer: Renderer2,private _projectService:ProjectService) {
    this._projectService.BarChartData.subscribe((value) => {        
      this.data = JSON.parse(value.data);
      this.labels = JSON.parse(value.label);
      this.total = value.total;
      this.updateChart();
    });
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
   
  //  if (this.data && this.data.length && this.labels && this.labels.length)
      this.createBarChart();
    
  }

  createBarChart() {
    const canvas = this.renderer.createElement("canvas");

    if (this.total) {
      this.renderer.appendChild(
        document.getElementById("chartContainer2"),
        canvas
      );
    } else {
      this.renderer.appendChild(
        document.getElementById("chartContainer"),
        canvas
      );
    }

    // const backgroundColors = this.generateRandomColors(this.data.length);

    const ctx = canvas.getContext("2d");
    let ticks = {};
    let backgroundColors = [];
    if (this.total) {
      ticks = {
        beginAtZero: true,
        max: this.total,
      };
      backgroundColors = ["#607d8b", "#cfd8dc"];
    } else {
      ticks = {
        beginAtZero: true,
      };

      backgroundColors = [
        "#ef9a9a",
        "#f48fb1",
        "#ce93d8",
        "#b39ddb",
        "#9fa8da",
        "#90caf9",
        "#81d4fa",
        "#80deea",
        "#80cbc4",
      ];
    }


    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            borderWidth: 1,
            backgroundColor: backgroundColors,
            borderColor: ["rgba(255, 99, 132, 0)", "rgba(54, 162, 235, 0)"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: this.total ? true : false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: ticks,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
  updateChart() {
    if(this.data.length > 0){
      this.chart.data.labels = this.labels;
      this.chart.data.datasets[0].data = this.data;
    }else{
      this.chart.data.labels = ["No used application"];
      this.chart.data.datasets[0].data = [100];
    }
    this.chart.update();
  }
  // generateRandomColors(count: number): string[] {
  //   const colors: string[] = [];
  //   const letters = "0123456789ABCDEF";

  //   for (let i = 0; i < count; i++) {
  //     let color = "#";

  //     for (let j = 0; j < 6; j++) {
  //       color += letters[Math.floor(Math.random() * letters.length)];
  //     }

  //     console.log(this.generateRandomColors(this.data.length));
  //     color = this.hexToRGBA(color, 0.2)
  //     colors.push(color);
  //   }

  //   return colors;
  // }

  // hexToRGBA(hex: string, opacity: number): string {
  //   hex = hex.replace("#", "");
  //   const r = parseInt(hex.substring(0, 2), 16);
  //   const g = parseInt(hex.substring(2, 4), 16);
  //   const b = parseInt(hex.substring(4, 6), 16);
  //   return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  // }
}
