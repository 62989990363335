<policy-header *ngIf="!isLogin" />
<div class="container-fluid">
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1 text-center">About Us</h1>
        </div>
    </div>
    <hr>
    <div class="row m-0 aboutus-content">
        <div class="col s12">
            <p class="text my-0-75">
                Supersee is an innovative solution developed by <b class="bold-text">Rao Information Technology Pvt
                    Ltd</b>, a leader in
                innovative tech solutions designed to enhance workplace productivity and security. Supersee is more
                than just an employee monitoring tool; it's a comprehensive platform built to empower businesses with
                real-time insights, ensuring that teams are operating at their best.
            </p>
            <p class="text my-0-75">
                At Supersee, we recognize the need for businesses to maintain a productive and secure work
                environment, especially in today’s work culture. Our platform provides advanced monitoring capabilities
                that allow businesses to oversee employee activities, optimize workflows, and protect sensitive
                information. Whether your team is working in the office or remotely, Supersee offers the visibility
                and control needed to drive performance and ensure compliance.
            </p>
            <p class="text my-0-75">
                With Supersee, companies can streamline their operations, improve efficiency, and foster a culture of
                accountability. SuperSee’s productivity tracking features and powerful analytics make it easy for
                managers to track progress, identify bottlenecks, and support their teams in reaching their full
                potential.
            </p>
            <p class="text my-0-75">
                Join the growing number of businesses that trust Supersee to enhance their operations. Experience the
                future of employee monitoring and discover how we can help you achieve greater success with cutting-edge
                technology.
            </p>
        </div>
    </div>
    <!-- <div class="row m-0">
        <div class="col s12 m6 flex items-center justify-center grid-image">
            <img src="../../assets/Application Usage Analysis.jpg" alt="">
        </div>
        <div class="col s12 m6 flex items-center justify-center grid-image">
            <img src="../../assets/Automatic time tracking.jpg" alt="">
        </div>
        <div class="col s12 m6 flex items-center justify-center grid-image">
            <img src="../../assets/Flexible screenshot options.jpg" alt="">
        </div>
        <div class="col s12 m6 flex items-center justify-center grid-image">
            <img src="../../assets/Optimized for Multi-Device Monitoring.jpg" alt="">
        </div>
    </div> -->
</div>