<div class="container-fluid">

  <div class="row" *ngIf="data.type == 'timeLog'">
    <div class="col s11" style="text-align: left;">
      <span mat-dialog-title style="margin-bottom: 0px;"><span style="font-weight: 700;color: #607d8b;">Date</span>
        {{ data.date }}
      </span>
      <span mat-dialog-title style="margin-bottom: 0px;"><b style="color: #607d8b;font-weight: 700;">Hours: </b>
        {{ data.working_hours }}
      </span>
    </div>
    <div class="col s1">
      <i class="material-icons" style="cursor: pointer; color: #607d8b;margin-top: 20px;" (click)="onCancel()">close</i>
    </div>
    <div class="col s12">
      <div mat-dialog-content>
        <div class="col-12">
          <table>
            <tr class="center-align">
              <th>Clock In</th>
              <th>Clock Out</th>
            </tr>
            <tr class="text-center" *ngFor="let time of data.timeLog">
              <td>{{ time.in }}</td>
              <td>{{ time.out }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>

  <!-- appInfo -->
  <div class="row" *ngIf="data.type == 'appInfo'" style="margin: 10px 0px;">
    <div class="col s11">
      <h1 mat-dialog-title><span style="color: #607d8b;font-weight: 700;">Application: </span> {{ data.appName }}</h1>
    </div>

    <div class="col s1">
      <i class="material-icons" style="cursor: pointer; color: #607d8b;" (click)="onCancel()">close</i>
    </div>

    <div class="col s12">
      <button mat-mini-fab class="deep-purple" (click)="onPrevious()" [disabled]="counter===0" style="float: left;">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span style="text-align: center;">{{data.appInfo && data.appInfo[counter].date}}</span>
      <button mat-mini-fab class="deep-purple" (click)="onNext()" [disabled]="data.appInfo && counter == data.appInfo.length - 1" style="float: right;">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div class="col s12" *ngFor="let info of data.appInfo; let i=index">
      <div mat-dialog-content *ngIf="i===counter">
        <div class="mat-table-content">
          <table class="mt-1" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDataUsed($event)">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header="{{column}}" [innerHTML]='column === "windowName" ? "Screen" : column === "usage" ? "Working" : column === "idle" ? "Not Working"
                : column === "in" ? "In Time" : column === "out" ? "Out Time" : ""'>
                </th>
              </tr>
              <tr>
                <td mat-cell *matCellDef="let emp"
                  [innerHTML]="column === 'idealTime' || column === 'usageTime' ? getUsageTime(emp) : emp[column]">
                </td>
              </tr>
            </ng-container>
            <!-- <ng-container matColumnDef="empty-row">
              <mat-cell *matCellDef="let emp">No Data</mat-cell>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
          </table>
        </div>
        <!-- <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100,500]" showFirstLastButtons></mat-paginator> -->
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="row" *ngIf="data.type == 'timeline'" style="margin: 10px 0px;">
    <div class="col s11">
      <h1 mat-dialog-title><span style="color: #607d8b;font-weight: 700;">Working for: </span> {{ data.in }} - {{
        data.out }} </h1>
    </div>

    <div class="col s1">
      <i class="material-icons" style="cursor: pointer; color: #607d8b;" (click)="onCancel()">close</i>
    </div>

    <div class="col s12">
      <div mat-dialog-content>
        <div class="mat-table-content">
          <table class="mt-1" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDataUsed($event)">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header="{{column}}"
                  [innerHTML]='column === "windowName" ? "Screen" : column === "usage" ? "Working" : column === "idle" ? "Not Working"
                    : column === "in" ? "In Time" : column === "out" ? "Out Time" : column === "appName"? "App Name" : ""'>
                </th>
              </tr>
              <tr>
                <td mat-cell *matCellDef="let emp"
                  [innerHTML]="column === 'idealTime' || column === 'usageTime' ? getUsageTime(emp) : emp[column]">
                </td>
              </tr>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
          </table>
        </div>
        <!-- <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100,500]" showFirstLastButtons></mat-paginator> -->
        <!-- </div> -->
      </div>
    </div>

  </div>
</div>