<!-- <section class="screen_shot_viwer_list"> -->

<div class="ps-2 pe-2 pt-3 pb-5">
  <mat-card>
    <mat-card-content>
      <div class="row" [hidden]="!userLog.length && usedFor !== 'timeLogs'">
        <div class="col xl12 l12 m12 s12" [hidden]="!userLog.length">
          <!-- <div class="mat-elevation-z8"> -->
          <table class="pb-2" mat-table matPaginator [dataSource]="dataSource" matSort
            (matSortChange)="sortDataLogs($event)">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">
                <b>Date</b>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.log_difference[0].date }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="difference">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="difference">
                <b>Hours Worked</b>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.log_difference[0].difference != 'aN:aN:aN' ?  element.log_difference[0].difference :
                '08:04:32' }}
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="inActivityTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="inActivityTime">
                <b>Inactivity time</b>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.inActivityTime }}
              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="action" style="text-align: right;">
              <th mat-header-cell *matHeaderCellDef><b>Action</b></th>
              <td mat-cell *matCellDef="let element; let i = index" (click)="showLogs(i, 'timeLog')">
                <a class="waves-effect waves-light">View more</a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator #paginator1 (page)="onPaginateChange($event)" [pageSize]="50">
          </mat-paginator>
        </div>
      </div>

      <div [hidden]="userLog.length" *ngIf="usedFor === 'timeLogs'" class="empty_list z-depth-2">
        <img src="assets/images/empty_list.png" alt="empty list image" />
      </div>

      <div class="row" *ngIf="usedFor === 'appUsages'">
        <div class="col m1">

        </div>
        <div class="col s12 m10" *ngIf="ischartDataLoaded && this.usedApp.length" style="background-color: red;">
          <app-chart [data]="pieChartData" [labels]="pieChartLabels">
          </app-chart>
        </div>
      </div>

      <hr *ngIf="usedFor === 'appUsages' && this.usedApp.length">

      <div class="row" [hidden]="!usedApp.length && usedFor !== 'appUsages'">
        <div class="col xl12 l12 m12 s12" [hidden]="!usedApp.length">
          <table class="pb-2" mat-table matPaginator [dataSource]="dataSource2" matSort
            (matSortChange)="sortDataUsed($event)">

            <ng-container matColumnDef="appName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="appName">
                <b>Application</b>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.appName }}</td>
            </ng-container>

            <ng-container matColumnDef="openCounts">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="openCounts">
                <b>Opens</b>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.openCounts }}</td>
            </ng-container>

            <ng-container matColumnDef="usageTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="usageTime">
                <b>Usage</b>
              </th>
              <td mat-cell *matCellDef="let element">{{ getUsageTime(element.usageTime) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="idealTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="idealTime">
                <b>Inactivity</b>
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.idealTime"> {{ getUsageTime(element.idealTime) }} </span>
                <span *ngIf="!element.idealTime"> {{ getUsageTime(element.idealTime) }} </span>
              </td>
            </ng-container>


            <ng-container matColumnDef="action2">
              <th mat-header-cell *matHeaderCellDef><b>Action</b></th>
              <td mat-cell *matCellDef="let element; let i = index" (click)="showLogs(i, 'appInfo')">
                <a class=" waves-effect waves-light">View more</a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
          </table>
          <mat-paginator #paginator2 showFirstLastButtons paginatorPageSize (page)="onPaginateChange($event)"
            [pageSize]="50" [length]="usedApp.length">
          </mat-paginator>


        </div>
      </div>
      <div [hidden]="usedApp.length" *ngIf="usedFor === 'appUsages'" class="empty_list z-depth-2">
        <img src="assets/images/empty_list.png" alt="empty list image" />
      </div>

    </mat-card-content>
  </mat-card>






  <app-loader *ngIf="loading"></app-loader>

  <!-- </section> -->
</div>