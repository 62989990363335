<!-- <ul id="dropdown1" class="dropdown-content deep-purple lighten-3">
  <li (click)="logoutCall()"><a>
      <span class="material-symbols-outlined dropdown-ico left" style="position: relative;top: 0px;margin-right: 10px;">
        keyboard_return
      </span>
      Logout</a>
  </li>
  <li class="divider"></li>
</ul>
<nav class="navbar-fixed deep-purple">
  <div class="nav-wrapper">
    <a [class.disabled]="iscontent" id="supersee-tab" href="#" class="brand-logo ss-logo">
      <img src="assets/logo.png" height="45" class="img-responsive" />
      Super<b>See</b>
    </a>
    <ul id="nav-mobile" class="right hide-on-med-and-down">
      <li class="tab {{tab.isActive ? 'active': ''}}" *ngFor="let tab of tabs">
        <a class="linksHeader" [class.disabled]="iscontent"
          [ngClass]="eventurl && eventurl.includes(tab.route) ? 'active' : ''" [routerLink]="tab.route">
          <span class="material-symbols-outlined left">{{tab.icon}}</span>{{tab.name}}
        </a>
      </li>
      <li>
        <a class="dropdown-trigger" [class.disabled]="iscontent" href="#!" data-target="dropdown1">
          <span class="material-symbols-outlined left">
            person_pin_circle
          </span>
          User
          <i class="material-icons right">arrow_drop_down</i>
        </a>
      </li>
      <li>
        <a class="dropdown-trigger" [routerLink]="['/employee/setting']">
          <span class="material-symbols-outlined left">
            settings
          </span>
          
        </a>
      </li>
    </ul>
  </div>
</nav> -->
<!-- new design -->
<div class="new-header-main-design">
  <div class="row">
    <div
      class="col s10 m11 l8 xl9 d-flex align-items-center"
      style="position: relative"
    >
      <div class="menu-icon" (click)="toggleSideMenu()" style="cursor: pointer;" [ngStyle]="{display: sideMenuMode == 'over' ? 'flex' : 'none'}">
        <span class="material-symbols-outlined" id="hamburger_menu">
          menu
        </span>
      </div>
      
      <div class="search-div" tourAnchor="HeaderSearch">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
            fill="#B3B3B3"
          />
        </svg>

        <input
          matInput
          #search
          (keyup)="onKey(search.value)"
          placeholder="Search Here"
          (click)="
           searchClick()
          "
          style="margin-left: 7px;"
        />
        <!-- <div class="all-search">
          <p>All</p>
          <span class="material-symbols-outlined"> expand_more </span>
          <div class="all-search-dropdown-div">
            <div class="row">
              <div
                class="col s12 all-search-dropdown-div-contain dropdown-hover"
              >
                <span class="material-symbols-outlined"> dashboard </span>
                Dashboard
              </div>
              <div
                class="col s12 all-search-dropdown-div-contain dropdown-hover"
              >
                <span class="material-symbols-outlined"> person </span>
                Employee
              </div>
              <div
                class="col s12 all-search-dropdown-div-contain dropdown-hover"
              >
                <span class="material-symbols-outlined"> groups </span>
                Teams
              </div>
              <div
                class="col s12 all-search-dropdown-div-contain dropdown-hover"
              >
                <span class="material-symbols-outlined"> Settings </span>
                Settings
              </div>
            </div>
          </div>
        </div> -->
        <div class="searchbar-panel-div" *ngIf="isSearchDiv">
          <div class="row">
            <div *ngFor="let section of searchData">
              <div>
                <div class="col s12"  *ngIf="section.section_type != 'Employee' && section.section_type != 'Teams' ? section.items.length > 0 : section.items.length > 0 || section.data.length > 0 ">
                  <h6>{{ section.section_type }}</h6>
                </div>
      
                <div class="col s12" *ngIf="section.section_type == 'Employee' && isEmployee">
                  <div
                  class="col s12 dropdown-hover"
                  *ngFor="let data of section.data"
                >
                <span (click)="userDetail(data.id)">
                  <user-profile [user]="data" userImageClass="avatar-img"></user-profile>
                </span>

                <!-- {{data.name}} -->
                </div>
                </div>
                <div class="col s12" *ngIf="section.section_type == 'Teams' && isEmployee">
                  <div
                  class="row dropdown-hover"
                  *ngFor="let data of section.data"
                >
                <div  class="col s12" style="text-transform: capitalize; font-size: 17px;display: inline-flex;padding-left: 36px" (click)="openTeamPage(data)">
                  <ngx-avatars name="{{ data.title.rendered }}" size="36"></ngx-avatars>
                 <span class="team_title"> {{
                  data.title.rendered
                }}</span> 
              </div>
                <!-- {{data.title.rendered}} -->
                <!-- <user-profile [routerLink]="['/user-details/', data.id]" [user]="data" userImageClass="avatar-img"></user-profile> -->

                <!-- {{data.name}} -->
                </div>
                </div>
                <div
                  class="col s12 dropdown-hover"
                  *ngFor="let sectionItems of section.items"
                >
                  <div
                    *ngIf="sectionItems.redirect && sectionItems.redirect!='/teams'"
                    [routerLink]="[sectionItems.redirect]" (click)="isSearchDiv = false;"
                  >
                    <p>
                      <span class="material-symbols-outlined">{{
                        sectionItems.icon
                      }}</span>
                      {{ sectionItems.action_name }}
                    </p>
                  </div>
                  <div
                  *ngIf="sectionItems.redirect && sectionItems.redirect=='/teams'"
                  
                >
                  <p (click)="moveTeamList()">
                    <span class="material-symbols-outlined">{{
                      sectionItems.icon
                    }}</span>
                    {{ sectionItems.action_name }}
                  </p>
                </div>
                  <div *ngIf="sectionItems.action && sectionItems.action_type == 'team'" (click)="openModal(sectionItems.action_type, teamModal)">
                    <p>
                      <span class="material-symbols-outlined">{{sectionItems.icon}}</span>
                      {{sectionItems.action_name}}
                    </p>
                  </div>
                  <div *ngIf="sectionItems.action && sectionItems.action_type == 'employee'" (click)="openModal(sectionItems.action_type, employeeModal)">
                    <p>
                      <span class="material-symbols-outlined">{{sectionItems.icon}}</span>
                      {{sectionItems.action_name}}
                    </p>
                  </div>
                  <div *ngIf="sectionItems.action && sectionItems.action_type == 'dashboard'" (click)="scrollToDashboardSection(sectionItems.target)">
                    <p>
                      <span class="material-symbols-outlined">{{sectionItems.icon}}</span>
                      {{sectionItems.action_name}}
                    </p>
                  </div>
                  <div *ngIf="sectionItems.action && sectionItems.action_type == 'Settings'" (click)="scrollToSettingSection(sectionItems.target)">
                    <p>
                      <span class="material-symbols-outlined">{{sectionItems.icon}}</span>
                      {{sectionItems.action_name}}
                    </p>
                  </div>
                  <div *ngIf="!sectionItems.redirect && !sectionItems.action">
                    <p>
                      <span class="material-symbols-outlined">{{
                        sectionItems.icon
                      }}</span>
                      {{ sectionItems.action_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col s2 m1 l4 xl3 d-flex align-items-center"
      style="position: relative; justify-content: end"
    >
      <!-- <button mat-icon-button>
        <span class="material-symbols-outlined"> help </span>
      </button>

      <button
        mat-icon-button
        class="notify-button"
        (click)="
          isNotification = !isNotification;
          isProfile = false;
          isSearchDiv = false
        "
        style="cursor: pointer"
      >
        <span class="material-symbols-outlined"> notifications </span>
        <div class="notify-badge">6</div>
      </button> -->
      <div class="notificationbar-div" *ngIf="isNotification">
        <div class="row">
          <div class="col s9">
            <h4>Notification</h4>
          </div>
          <div class="col s3 badge-div">
            <div class="notification-badge">8 New</div>
          </div>
        </div>
        <div class="row dropdown-hover">
          <div class="col s2">
            <div class="avatar-profile">
              <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            </div>
          </div>
          <div class="col s7">
            <h6>Congratulation Flora! 🎉</h6>
            <p>Won the monthly best seller badge</p>
          </div>
          <div class="col s3 badge-div">
            <p>Today</p>
          </div>
        </div>
        <div class="row dropdown-hover">
          <div class="col s2">
            <div class="avatar-profile">
              <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            </div>
          </div>
          <div class="col s7">
            <h6>Congratulation Flora! 🎉</h6>
            <p>Won the monthly best seller badge</p>
          </div>
          <div class="col s3 badge-div">
            <p>Today</p>
          </div>
        </div>
        <div class="row dropdown-hover">
          <div class="col s2">
            <div class="avatar-profile">
              <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            </div>
          </div>
          <div class="col s7">
            <h6>Congratulation Flora! 🎉</h6>
            <p>Won the monthly best seller badge</p>
          </div>
          <div class="col s3 badge-div">
            <p>Today</p>
          </div>
        </div>
        <div class="row dropdown-hover">
          <div class="col s2">
            <div class="avatar-profile">
              <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            </div>
          </div>
          <div class="col s7">
            <h6>Congratulation Flora! 🎉</h6>
            <p>Won the monthly best seller badge</p>
          </div>
          <div class="col s3 badge-div">
            <p>Today</p>
          </div>
        </div>
        <div class="row dropdown-hover">
          <div class="col s2">
            <div class="avatar-profile">
              <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            </div>
          </div>
          <div class="col s7">
            <h6>Congratulation Flora! 🎉</h6>
            <p>Won the monthly best seller badge</p>
          </div>
          <div class="col s3 badge-div">
            <p>Today</p>
          </div>
        </div>
      </div>
      <div class="profile-section" tourAnchor="AdminProfile">
        <div class="avtar-contain" >
          <div
            class="avatar-profile"
            (click)="
              isProfile = !isProfile;
              isSearchDiv = false;
              isNotification = false
            "
          >
          <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
            <!-- <div class="online-user"></div> -->
          </div>
        </div>
        <div class="" style="margin: 0">
          <h4>{{ adminData.user_display_name }}</h4>
          <p class="avtar-email">{{ adminData.user_email }}</p>
        </div>
        <div
          class="col s1 expand-icon"
          style="padding: 0; margin: 0; cursor: pointer"
          (click)="
            isProfile = !isProfile; isSearchDiv = false; isNotification = false
          "
        >
          <span class="material-symbols-outlined"> expand_more </span>
        </div>

        <div class="profilebar-div" *ngIf="isProfile"> 
          <div class="row" [routerLink]="['/user-profile-info']" (click)="isProfile = false" style="cursor: pointer;">
            <div class="col s3">
              <div class="avatar-profile">                
                <ngx-avatars name="{{ adminData.user_display_name }}" size="36"></ngx-avatars>
              </div>
            </div>
            <div class="col s9">
              <h5 class="admin-name">{{ adminData.user_display_name }}</h5>
              <h6>{{adminData.role.length ? adminData.role[0] == 'employee' ? adminData.role[1] : adminData.role[0] == 'company' ? 'admin' :  adminData.role[0] : ''}}</h6>
            </div>
          </div>
          <!-- <div class="row dropdown-hover">
            <div class="col s12">
              <p>
                <span class="material-symbols-outlined"> person </span>
                Profile
              </p>
            </div>
          </div> -->
          <!--   -->
          <!-- <div class="row dropdown-hover">
            <div class="col s12">
              <p>
                <span class="material-symbols-outlined"> person </span>
                FAQ
              </p>
            </div>
          </div> -->
          <div class="row dropdown-hover" style="border-bottom: 1px solid #80808021;" (click)="startTour()">
            <div class="col s12">
              <p><span class="material-symbols-outlined"> tour </span> <span style="font-size: 16px;">Take tour again</span></p>
            </div>
          </div>  
          <div class="row dropdown-hover" style="border-bottom: 1px solid #80808021;" (click)="moveToDownload()">
            <div class="col s12">
              <p><span class="material-symbols-outlined"> download </span> <span style="font-size: 16px;">Download</span></p>
            </div>
          </div>    
          <div class="row dropdown-hover" style="border-bottom: 1px solid #80808021;" (click)="moveToPaymentHistory()" *ngIf="isAdmin">
            <div class="col s12">
              <p><span class="material-symbols-outlined"> payment </span> <span style="font-size: 16px;">Billing</span></p>
            </div>
          </div>        
          <div class="row dropdown-hover" style="border-bottom: none" (click)="logoutCall()">
            <div class="col s12">
              <p><span class="material-symbols-outlined"> logout </span> <span style="font-size: 16px;">Logout</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div #teamModal class="modal">
  <app-add-project *ngIf="isTeamModal"></app-add-project>
</div>
<div #employeeModal class="modal">
  <app-create-user [isEditable]="{isEdit: false}"></app-create-user>
</div>