<div class="container-fluid">
  <div class="row employee-list-container">

    <div class="" style="padding: 0px !important;">
      <h1>
        {{getHeaderTitle()}}</h1>
    </div>

    <div class="user_filter_with_three_option">

      <div class="user_filter">

        <div class="dateTimeSection" id="application-usage-section">
          <span class="personalize-toggle">
            <mat-slide-toggle color="primary" [(ngModel)]="isApplicationUsage"
              (change)="isApplicationUsageToggleChange($event)">
            </mat-slide-toggle>
          </span>
          Include Application Usage
        </div>

        <div class="dateTimeSection">
          <button mat-raised-button class="employee-add-btn" (click)="downloadAction()">
            Export
          </button>
        </div>
      </div>

    </div>

    <div class="user_filter" style="padding: 0px !important;">

      <div class="dateTimeSection" id="day-wise-section">
        Day wise
        <span class="personalize-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="isUser" (change)="isPersonalizesToggleChange($event)">
          </mat-slide-toggle>
        </span>
        User wise
      </div>

      <div class="dateTimeSection" id="employee-selection-section" *ngIf="isUser">
        <mat-form-field class="timeline-datepicker">
          <mat-label>Select Employee</mat-label>
          <mat-select (selectionChange)="onUserSelectionChange()" [(ngModel)]="selectedUser"
            [compareWith]="compareUsers">
            <input mat-input type="text" class="search_input" #searchInp (keyup)="searchEmployee(searchInp.value)"
              placeholder="Search employee" />
            @for (emp of employeeList; track $index) {
            <mat-option [value]="emp.id" (click)="employeeSelected(emp)">{{emp.name | titlecase}}</mat-option>
            } @empty {
            <mat-option>No employee available</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="dateTimeSection" id="date-range-picker-section" *ngIf="isUser">
        <mat-form-field class="date-range-picker-form-field">
          <mat-date-range-input [rangePicker]="picker" [max]="todayDate">
            <input matStartDate [value]="startDate" placeholder="Start date" (dateChange)="onStartDateChange($event)" />
            <input matEndDate [value]="endDate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="dateTimeSection" id="single-date-picker-section" *ngIf="!isUser">
        <i class="material-icons arrow_icons picker_icon"
          (click)="stackDatePrevNextAction(true)">keyboard_arrow_left</i>
        <mat-form-field class="timeline-datepicker" style="border-radius: 0px !important;">
          <input matInput [matDatepicker]="pickerdate" [value]="selectDate" [max]="todayDate"
            style="border-radius: 0px !important;" (dateChange)="onSelectDateChange($event)" />
          <mat-datepicker-toggle matIconSuffix [for]="pickerdate"></mat-datepicker-toggle>
          <mat-datepicker #pickerdate></mat-datepicker>
        </mat-form-field>
        <i class="material-icons arrow_icons picker_icon" (click)="stackDatePrevNextAction(false)"
          [ngStyle]="{'color': isIconDisable() ? 'lightgrey':'black'}">keyboard_arrow_right</i>
      </div>


    </div>

  </div>

  <div class="list-parts">
    <table matSort (matSortChange)="sortData($event)" class="highlight centered" style="padding: 0px 32px" #dataTable>
      <thead class="">
        <tr class="table-head">
          <ng-container *ngFor="let header of headers">
            <th *ngIf="header.visible" [mat-sort-header]="header.key">
              {{ header.label }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="!loadWaiter">
        <tr *ngFor="let val of timelineList;let currentRow = index">
          <ng-container *ngFor="let field of headers">
            <td *ngIf="field.visible">{{ getFieldValue(val, field.key, currentRow) }}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <div>
      <div *ngIf="loadWaiter">
        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="6"
          [theme]="{ 'border-radius': '2', height: '50px', width: '100%' }"></ngx-skeleton-loader>
      </div>
      <div *ngIf="!loadWaiter && timelineList && !timelineList.length" style="width: 100%">
        <div class="empty_list z-depth-2">
          <img src="assets/images/empty_list.png" alt="empty list image" />
        </div>
      </div>
    </div>
  </div>
</div>