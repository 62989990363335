import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config } from "../config";
// import { Socket } from 'ngx-socket-io';
import { Observable, observable, Subject } from "rxjs";
import { GoogleAuthProvider } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
@Injectable({
  providedIn: "root",
})
export class LoginService {

  private updateStatus = new Subject<any>();

  observeStatusChanged(): Observable<any> {
    return this.updateStatus.asObservable();
  }

  tickStatusChanged(params) {
    this.updateStatus.next({ user: params.userId, status: params.status });
  }

  constructor(private http: HttpClient, public afAuth: AngularFireAuth) {

  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }
  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider);
  }

  requestScreenShot(details) {
    //this.socket.emit("requestScreenShot", details);
  }

  doLogin(user) {
    return this.http.post(
      config.baseUrl +
      `jwt-auth/v1/token`,
      {
        'username': user.username,
        'password': user.password
      }
    );
  }

  addUser(createUser) {
    const roles = 'employee'
    createUser['roles'] = roles;

    if (createUser.roles2 != null || createUser.roles2 != '' || createUser.roles2 != undefined) {
      if (createUser.roles2) {
        if (createUser.roles2 !== 'employee') {
          createUser['roles'] = roles + ',' + createUser.roles2;
        }
      }
    }
    delete createUser['roles2'];

    return this.http.post(config.baseUrl + "wp/v2/users", createUser);
  }

  getUser(id) {
    if (id) {
      return this.http.get(config.baseUrl + `wp/v2/users/${id}`);
    }
  }

  getUserList(admin) {
    return this.http.get(
      config.baseUrl + "wp/v2/users?roles=employee&per_page=200&offset=0"
    );
  }

  getSSListOfUser(userId) {
    return this.http.get(config.baseUrl + "/user/admin/getFiles/" + userId);
  }

  getLogsById(params) {
    let user = params.userId;

    return this.http.get(
      config.baseUrl + "wp/v2/timesheet?status=private&author=" + user
    );
  }

  getUserStatus(params) {

    return this.http.get(
      config.baseUrl + "/user/admin/user-status?user=" + params.userId
    );
  }

  getUserStatusV2(params) {

    return this.http.get(
      config.baseUrl + "/user/v2/admin/user-status?user=" + params.userId
    );
  }

  requestScreenCast(details) {
    // this.socket.emit("requestScreenCast", details);
  }

  requestScreenCastOff(details) {
    // this.socket.emit("requestScreenCastOff", details);
  }
}
