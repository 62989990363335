import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../config';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private _http: HttpClient) { }
  getGroups(params){
  	return this._http.get(config.baseUrl+ '/group?groupId='+params.groupId+"&isTeamLead="+params.isTeamLead);
  }

}
