import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/Services/project.service';

@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply-coupon.component.html',
  styleUrl: './apply-coupon.component.css'
})
export class ApplyCouponComponent {
	applyCouponForm: UntypedFormGroup;
  isError = false;
  errorMsg = '';
  isloading = false;
  constructor(public dialog: MatDialog,private _projectService:ProjectService){
    this.applyCouponForm = new UntypedFormGroup({
      coupon : new UntypedFormControl('', [Validators.required]),

		})
  }
  onKey(){
    this.isError = false;
    this.errorMsg = '';
  }

  applyAction(){
    this.isloading = true;
    this._projectService.applyCoupon(this.applyCouponForm.value.coupon).subscribe((data: any) => {
      this.isloading = false;
      if(data || data == true || data == 'true'){
        this.dialog.closeAll()
      }
    },err=>{  
      this.isloading = false;
      this.errorMsg = err.error.message;
      this.isError = true;
    })
  }
}
