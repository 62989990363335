<form [formGroup]="createUserForm">
  <div class="container-fluid">
    <div class="modal-content">
      <div class="row d-flex align-items-center add-new-tam-container" >
        <div class="col s10 add-new-team-contain">
          <h1>
            {{ !isEdit ? "Add new team" : "Edit team" }}
          </h1>

        </div>
        <div class="col s2 close-btn-contain">
          <button
            class="btn modal-close close-btn btn-flat"
            mat-dialog-close
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
            >
              <path
                d="M20.0002 3.8335C10.7835 3.8335 3.3335 11.2835 3.3335 20.5002C3.3335 29.7168 10.7835 37.1668 20.0002 37.1668C29.2168 37.1668 36.6668 29.7168 36.6668 20.5002C36.6668 11.2835 29.2168 3.8335 20.0002 3.8335ZM28.3335 26.4835L25.9835 28.8335L20.0002 22.8502L14.0168 28.8335L11.6668 26.4835L17.6502 20.5002L11.6668 14.5168L14.0168 12.1668L20.0002 18.1502L25.9835 12.1668L28.3335 14.5168L22.3502 20.5002L28.3335 26.4835Z"
                fill="#D9D9D9"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <!-- <div class="row" style="margin-bottom: 36px">
          <div class="col s12 d-flex" style="justify-content: center">
            <div class="employee-add-avtar">
              <img
                src="../../../assets/images/199fd29184c6cff24e3445f849af463e.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="col s12 d-flex"
            style="justify-content: center; margin-top: 12px"
          >
            <button mat-raised-button class="employee-add-change-btn">
              Change
            </button>
            <button mat-stroked-button class="employee-add-remove-btn">
              Remove
            </button>
          </div>
        </div> -->

        <div class="row" style="margin-top: 15px;">
          <div class="col s12">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Team name</mat-label>
              <input
                matInput
                formControlName="title"
                placeholder="Enter team name"
              />
            </mat-form-field>
            <!-- <span *ngIf="createUserForm.get('title').errors && 
            createUserForm.get('title').hasError('required')">
              <mat-error> Screen capture time is required. </mat-error>
            </span> -->
          </div>
          <div class="col s12">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Select managers</mat-label>
              @if(isMultipleManagers) {
                <mat-select formControlName="team_manager" multiple style="text-transform: capitalize !important;">
                  @for (user of managerArr; track user.id) {
                    <mat-option (onSelectionChange)="onGroupSelect($event, user)" [value]="user.id" style="text-transform: capitalize !important;">{{ user.name }}</mat-option>                    
                  }
                </mat-select>
              } @else {                
                <mat-select formControlName="team_manager">
                  <mat-option>No team manager available</mat-option>
                </mat-select>
              }              
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Select team leads</mat-label>              
              @if(isMultipleLeads) {
                <mat-select formControlName="team_lead" multiple style="text-transform: capitalize !important;">
                  @for (user of leadArr; track user.id) {
                    <mat-option (onSelectionChange)="onGroupSelect($event, user)" [value]="user.id" style="text-transform: capitalize !important;">{{ user.name }}</mat-option>
                  }
                </mat-select>
              } @else {                
                <mat-select formControlName="team_lead">
                  <mat-option>No team leads available</mat-option>
                </mat-select>
              }
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Select team members</mat-label>
              @if(isMuleripleMember) {
                <mat-select formControlName="team_developer" multiple style="text-transform: capitalize !important;">
                  @for (user of developerArr; track user.id) {
                    <mat-option (onSelectionChange)="onGroupSelect($event, user)" [value]="user.id" style="text-transform: capitalize !important;">{{ user.name }}</mat-option>
                  }
                </mat-select>
              } @else {                
                <mat-select formControlName="team_developer">
                  <mat-option>No team leads available</mat-option>
                </mat-select>
              }
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <!-- {{createUserForm.valid}} -->
      <!-- {{createUserForm | json}} -->
      <button
        mat-raised-button
        style="margin: 0px 10px"
        type="button"
        class="btn modal-close cancel-btn darken-1"
        mat-dialog-close
        (click)="cancelAction()"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="createTeam()"
        *ngIf="!isEdit"
        [disabled]="!createUserForm.valid || !isResponseGet"
        class="btn createEmployee"
        mat-dialog-close
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="editTeam()"
        *ngIf="isEdit"
        [disabled]="!createUserForm.valid || !isResponseGet"
        class="btn createEmployee"
        mat-dialog-close
      >
        Save
      </button>
    </div>
  </div>
</form>

<app-loader *ngIf="loading"></app-loader>
