<div class="container-fluid">
	<div class="row login-page-container">
		
		<div class="col s12 m12 l12 login-container">
			
            <div class="icon-contain">
                <img src="../../assets/welcome-back 1.png" alt="" id="welcomeBackImg">
            </div>
            <div class="welcome-contain">
			<h1>to the super see </h1>
            <!-- <h4>Password reset successfully</h4> -->
			<p>Workplace productivity! with superSee, you can effortlessly measure both productive and idle time of your employees providing you with a comprehensive view of how your team is spending their time at work.</p>
            <p>
                Explore the power of superSee and take control of your team's productivity like never before!
            </p>
		    </div>
            <div class="row" style="margin: 0;">
                <div class="col s12">
                    <h4>Click to start complete tour of the supersee</h4>
                </div>
                <div class="col s12 m6">
                    <button mat-raised-button 
                    class="btn  skip-tour-btn" extended (click)="skipTour()">Skip the tour</button>
                </div>
               
                <div class="col s12 m6">
                    <button mat-raised-button 
                    class="btn  signInBtn" extended (click)="startTour()" [disabled]="isloading">Start Tour</button>
                </div>
            </div>
		</div>
       

	</div>
</div>



