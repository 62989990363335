import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { SharedService } from '../Services/sharedService';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../Services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingServiceService } from '../Services/messaging-service.service';
import swal from "sweetalert2";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeComponent } from '../welcome/welcome.component';

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
}

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css']
})

export class VerificationCodeComponent {
  isResend = false;
  isError = '';
  email = '';
  interValValue = 30;
  VarificationForm: UntypedFormGroup;
  authType = '';
  data;
  isDisableVarification = false;
  @ViewChild('code2Input') code2Input: ElementRef;
  @ViewChild('code3Input') code3Input: ElementRef;
  @ViewChild('code4Input') code4Input: ElementRef;
  private dbPath = localStorage.getItem('dbPath') ? localStorage.getItem('dbPath') : "/users";

   constructor(private _sharedServices:SharedService,private ngZone: NgZone,private _projectService:ProjectService,private _snackBar: MatSnackBar,private router: Router,private route: ActivatedRoute,private messagingService:MessagingServiceService, private db: AngularFireDatabase, public dialog: MatDialog,
    ){
    this._sharedServices.sideMenuSubject.next(false);
    this.route.queryParams.subscribe(params => {
      this.authType = params['type'];
      if(params['data']){
        this.data = JSON.parse(params['data']);
      }
    });
    if(localStorage.getItem('current_email'))
    {
      this.email = localStorage.getItem('current_email')
    }
    this.VarificationForm = new UntypedFormGroup({
      code1: new UntypedFormControl("", [Validators.required,Validators.pattern(/^[0-9]$/)]),
      code2: new UntypedFormControl("", [Validators.required,Validators.pattern(/^[0-9]$/)]),
      code3: new UntypedFormControl("", [Validators.required,Validators.pattern(/^[0-9]$/)]),
      code4: new UntypedFormControl("", [Validators.required,Validators.pattern(/^[0-9]$/)]),
    });
   }
   resendCode(){

    const varificationVal = this.VarificationForm.value;
    let code = varificationVal.code1.toString() + varificationVal.code2.toString() + varificationVal.code3.toString() + varificationVal.code4.toString();
      this
        ._projectService
        .sendVarificationCode(this.email).subscribe((user: any) => {
          if(user == true || user == 'true'){
            this._snackBar.open('The recovery code sent to your email','Undo',{
              duration: 3000
            })
           }
        },err=>{
          this.isError = err.error.message;
          setInterval(() => {
            this.ngZone.run(() => {
              this.isError = '';
            });
          }, 3000); 
            this._snackBar.open(err.error.message,'Undo',{
              duration: 3000
            })
        })
    
    this.isResend = true;
    let secondsRemaining = 30;

    const intervalId = setInterval(() => {
      this.ngZone.run(() => {
        this.interValValue = secondsRemaining;
        secondsRemaining--;

        if (secondsRemaining < 0) {
          clearInterval(intervalId);
          this.isResend = false;
        }
      });
    }, 1000);
   }

   varifyAction(){
    this.isDisableVarification = true;
    const varificationVal = this.VarificationForm.value;
    let code = varificationVal.code1.toString() + varificationVal.code2.toString() + varificationVal.code3.toString() + varificationVal.code4.toString();
    if(!this.VarificationForm.valid){
      this.isDisableVarification = false;
      return
    }else{
      this
        ._projectService
        .VarifyCode(this.email,code).subscribe((user: any) => {
          console.log(user,"user---")
          if(user == true || user == 'true'){
            if(this.authType == 'signup'){
              swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your account has been verified successfully . Please login to continue',
                timer: 2000,
                showConfirmButton: false,
              })
              this.router.navigate(["/dashboard"]);
            }
            if(this.authType == 'login'){
              swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your account has been verified successfully.',
                timer: 2000,
                showConfirmButton: false,
              })
              console.log('auth data---',this.data)
              this.loginAction()
            }

            if(this.authType == 'forgot'){
              const dataValue = {
                email : this.email,
                code:code
              }
              swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your account has been verified successfully.',
                timer: 2000,
                showConfirmButton: false,
              })
              this.router.navigate(["/new-password"], { queryParams: { data:JSON.stringify(dataValue) } });
            }
          }
          // swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: 'Successfully verfied',
          //   timer: 2000,
          //   showConfirmButton: false,
          // })
          // this._snackBar.open('Successfully verfied','Undo',{
          //   duration: 3000
          // })
          this.isDisableVarification = false;

        },err=>{
          this.isDisableVarification = false;

          if(err.error.code != 'invalid_code'){
            // setInterval(() => {
            //   this.ngZone.run(() => {
            //     this.isError = '';
            //   });
            // }, 3000); 
            console.log(err,"user---err")
              this._snackBar.open('Something went wrong','Undo',{
                duration: 3000
              })
          }else{
            this.isError = err.error.message;
            setInterval(() => {
              this.ngZone.run(() => {
                this.isError = '';
              });
            }, 3000); 
            console.log(err,"user---err")
              this._snackBar.open(err.error.message,'Undo',{
                duration: 3000
              })
          }
        })
    }
   }
  
   loginAction(){
     // console.log("====>", this.socket.);
     let check = this.data;
       localStorage.setItem("token", check["token"]);
        this._sharedServices.logInStateSubject.next(true);
        this.router.navigate([""]);
        localStorage.setItem("isLogedIn", "true");
        localStorage.setItem("logedInUser", JSON.stringify(check));
        this._projectService.updateEmployeeListData({data:true})
        this.messagingService.resetData();
        const db_path = `/users/${check.company_id}`;
        this.dbPath = db_path;

        localStorage.setItem('dbPath',db_path)
        this.messagingService.dbPath = db_path;
        this._projectService.updateFirebaseData({data:db_path})
        this._projectService.getUser().subscribe((check: any) => {
          if(check.meta.ss_tooltip_visited == '' || !check.meta.ss_tooltip_visited || check.meta.ss_tooltip_visited == 'false' || check.meta.ss_tooltip_visited == false){
              const dialogRef = this.openDialog(
                WelcomeComponent
              ).subscribe((response) => { });
            localStorage.setItem('tourStart','true')
          }else{
            localStorage.setItem('tourStart','false')
          }
        this.messagingService.requestPermission(check.id);
        this.messagingService.receiveMessage();
        this.messagingService.showNotification();
      });
   }

   create(user: User): any {
    return this.db.database.ref(this.dbPath).child(user.id).set(user);
    // return this.usersRef.push(user);
  }
  onInput(event: any, nextInput: ElementRef | null): void {
    const input = event.target;
    if (input.value.length === 1 && nextInput) {
      console.log(nextInput,nextInput.nativeElement,"next input===")
      nextInput.nativeElement.focus();
    }
  }

  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }
}
