export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBjRhQzECbMr-0GYOf_pgb716uYaEFRCjs",
        authDomain: "supersee-demo.firebaseapp.com",
        databaseURL: "https://supersee-demo-default-rtdb.firebaseio.com",
        projectId: "supersee-demo",
        storageBucket: "supersee-demo.firebasestorage.app",
        messagingSenderId: "945660284253",
        appId: "1:945660284253:web:085b4dd90416c83ba6acb4",
        measurementId: "G-YTVBC1QV6P"
    },
    ADMIN_PEER_ID: 'SuperSee',
    api: {
        baseUrl: "https://demo.api.supersee.io/wp-json/",
        baseMediaUrl: "https://demo.api.supersee.io/wp-json/",
        payment_username: "ck_086e60e087dedebe3c1920b59ce1606dc827dc08",
        payment_password: "cs_621dd184ab195d89d4da71ef889dcc2c41343ff6",
        signUp_username: 'user',
        signUp_password: 'ESbNwX3kbzxFyKMTRfmSSSim',
        razor_pay_key: "rzp_test_agUzUlOF59lKAE",
        setLimitsForNotifyUsers: 15,
        feedBackSheet: "https://script.google.com/macros/s/AKfycbxlwrqNNEU5kRJ_TaUwCivVbRTamUjY83ekloKskN6Fm5eGlFjGn4WPKJQakBkHtKR7eQ/exec"
    }
};