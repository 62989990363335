import { Component, Input, Output, EventEmitter, ElementRef, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import * as M from "materialize-css/dist/js/materialize";
import { SharedService } from "src/app/Services/sharedService";
import { ProjectService } from "src/app/Services/project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import * as _ from "lodash";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { Observable, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AddProjectComponent } from "../add-project/add-project.component";

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}

@Component({
  selector: "app-team-list",
  templateUrl: "./team-list.component.html",
  styleUrls: ["./team-list.component.css"],
})

export class TeamListComponent implements OnInit, AfterViewInit {
  _userData: any;
  searchArr: any = [];
  loader = true;
  @Input("isAdmin") isAdmin;
  @Output("goToList") goToTeam: EventEmitter<any> = new EventEmitter();
  @Input("selectedTeamData") selectedTeamData;
  errorPhotoShow: Boolean = false;
  skeletonLoader: Boolean = true;
  noDataFound: Boolean = false;

  _teamMembers: any;
  _usersList: any;
  selectedTeamManagers: any = [];
  selectedTeamDevelopers: any = [];
  selectedTeamLeaders: any = [];
  historyTabs: any = 0;
  _teamMemberListOnMode: any = [];
  usersRef: AngularFireList<User> = null;
  private dbPath = localStorage.getItem('dbPath');
  userDataSubscription: Subscription;
  currentTeamId;
  @Input()
  get groupList(): any {
    this.skeletonLoader = true;
    if (!this._userData) {
      this._userData = [];
      this.searchArr = [];
      this.noDataFound = true;
      this.skeletonLoader = false;
      this.errorPhotoShow = true;
      return this._userData;
    }
    return this._userData;
  }
  set groupList(data: any) {
    if (localStorage.getItem('selectedData')) {
      this.selectedTeamData = JSON.parse(localStorage.getItem('selectedData'));
    }

    if (data) {
      this._userData = data;
    } else {
      this._userData = [];
    }
   
    this.searchArr = this._userData
    this.loader = false
    if (!this.selectedTeamData) {
      this.goToTeam.emit(this._userData[0]);
    } else {
      this.goToTeam.emit(this.selectedTeamData);
    }
     if(!this._userData.length){
      this._userData = [];
      this.searchArr = [];
      this.noDataFound = true;
      this.skeletonLoader = false;
      this.errorPhotoShow = true;
    }
    this.setStatus()
  }
  get teamData() {
    return this._teamMembers;
  }
  set teamData(team: any) {
    if (team) {
      if (!this._usersList) {
        this._projectService.getUserList(0, 0).subscribe((data) => {
          this.getEmployeeIdOnTeamSelect(team.meta, data);
          this.onSelectUserMode({ index: this.historyTabs });
        });
      } else {
        this.getEmployeeIdOnTeamSelect(team.meta);
        this.onSelectUserMode({ index: this.historyTabs });
      }

      this._teamMembers = team;
    }
  }

  constructor(private _sharedService: SharedService,
    private _projectService: ProjectService,
    private _messagingService: MessagingServiceService,
    private db: AngularFireDatabase,
    public dialog: MatDialog
  ) {
    _messagingService.showNotification();
  }

  ngOnInit() {
    // setInterval(() => {
    //   this.errorPhotoShow = true;
    //   this.skeletonLoader = false;
    // }, 10000);
  }

  ngAfterViewInit(): void { }

  ngAfterContentInit(): void {
    // M.AutoInit();
  }

  goToTeamEmiter(team: any) {
    // console.log("THE ELEMENT REFRENCE ===========>", element);
    // let instance = M.Modal.getInstance(element);
    // instance.open();    
    this.goToTeam.emit(team);
  }

  getTotalMemberCount(teamMembers) {    
    if (typeof teamMembers !== 'object' || teamMembers === null) {
      return 0; // or handle the case based on your requirement
    }
    
    const concatenatedString = Object.values(teamMembers).join('');
    
    if (concatenatedString.trim() === '' || concatenatedString === 'false') {
      return 0; // Return an empty array if there are no numbers
    }
  
    const uniqueNumbersSet = new Set(concatenatedString.match(/\d+/g).map(Number));
        
    const uniqueNumbersArray = [...uniqueNumbersSet];

    return uniqueNumbersArray.length;    
  }

  searchTeams(val: any) {
    let svalue = val.toUpperCase();
    this._userData = this.searchArr.filter((ele) => {
      return (ele.title.rendered.toUpperCase().includes(svalue));
    });
    if (this._userData.length <= 0) {
      // console.log("this._userData.length not");
      this.errorPhotoShow = true;
      this.skeletonLoader = false;
    }
  }

  openTeamEditModal(teamId) {
    // this.currentTeamId = teamId;
    // this._sharedService.teamModalinstance = M.Modal.init(elementRef);
    // this._sharedService.teamModalinstance.open();
    let obj = {
      teamId: teamId,
    };
    let data = obj;
    this._userData.find((team) => {
      if (team.id == teamId) {
        team.isMenuOpened = false;
      }
    });
    const dialogRef = this.openDialog(
      AddProjectComponent,
      data
    ).subscribe((response) => { });
  }

  getEmployeeIdOnTeamSelect(metaData: any, users?: any) {
    if (users) {
      this._usersList = users;
    }
    this.selectedTeamManagers = [];
    this.selectedTeamDevelopers = [];
    this.selectedTeamLeaders = [];
    let managers = metaData.team_manager
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");
    let developers = metaData.team_developer
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");
    let leaders = metaData.team_lead
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");

    let obj = {};
    obj["manager"] = managers;
    obj["developer"] = developers;
    obj["leaders"] = leaders;

    // this.getEmployee(obj);
    this.filteringData(this._usersList, obj);
  }

  filteringData(userList: any, data: any) {
    let onlyUsersId = [];
    let key;
    let uniqeId = [];
    let selectedUser = [];
    for (key in data) {
      data[key].map((ele) => {
        onlyUsersId.push(ele);
      });
    }
    uniqeId = Array.from(new Set(onlyUsersId));

    uniqeId.map((ele) => {
      userList.filter((userId) => {
        if (parseInt(ele) == parseInt(userId.id)) {
          selectedUser.push(userId);
        }
      });
    });

    for (key in data) {
      switch (key) {
        case "manager":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamManagers.push(element);
              }
            });
          });
          break;
        case "developer":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamDevelopers.push(element);
              }
            });
          });
          break;
        case "leaders":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamLeaders.push(element);
              }
            });
          });
          break;
      }
    }
  }

  onSelectUserMode(mode: any) {
    this.historyTabs = mode.index;
    switch (mode.index) {
      case 0:
        this._teamMemberListOnMode = this.selectedTeamManagers;
        this.setStatus();
        break;
      case 1:
        this._teamMemberListOnMode = this.selectedTeamLeaders;
        this.setStatus();
        break;
      case 2:
        this._teamMemberListOnMode = this.selectedTeamDevelopers;
        this.setStatus();
        break;
    }
  }
  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData()
  }
  setStatus() {
    console.log(this._teamMemberListOnMode ,"_teamMemberListOnMode")
    this.usersRef = this.db.list(this.dbPath);

    // this.getAll()
    //   .snapshotChanges()
    //   .pipe(
    //     map((changes) =>
    //       changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
    //     )
    //   )
    this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((o) => {
        if (this._teamMemberListOnMode) {
          let index = _.findIndex(
            this._teamMemberListOnMode,
            function (obj: any) {
              return obj.id == o.id;
            }
          );
          if (index > -1) {
            if (o.lastOnlineAtTz) {
              this._teamMemberListOnMode[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
            } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
              this._teamMemberListOnMode[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
            } else {
              this._teamMemberListOnMode[index]["status"] = "not-using";
            }
            if (
              o.ssRequest != true &&
              o.ssRequest != false &&
              o.ssRequest != undefined &&
              o.ssRequest != null
            ) {
              this._teamMemberListOnMode[index]["ssRequest"] =
                o.ssRequest["createdAt"];
            }
          }
        }
      });
    });
  }

  deleteTeam(team) {
    let data = {
      id: team.id,
      type: "team",
      name: team.title.rendered
    };
    team.isMenuOpened = false;
    this.openDialog(ConfirmationDialogComponent, data).subscribe((response) => {
      if (response != undefined) {
        this._projectService.deleteTeam(data.id).subscribe((response: any) => {
          this.groupList.splice(_.findIndex(this.groupList, function (o) { return o.id == data.id }), 1);
          this._projectService.updateTeamDeletedData(response)
        })
      }
    });
  }
  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }

  openMenu(team) {
    this._userData.find((singleTeam) => {
      if (singleTeam.id != team.id) {
        singleTeam.isMenuOpened = false;
      }
    });
    if (team.isMenuOpened) {
      team.isMenuOpened = false;
    } else {
      team.isMenuOpened = true;
    }
    return team;
  }
}
