<div class="container-fluid " [ngStyle]="{'padding':isProfile ? '0':'16px 36px'}"
  *ngIf="(!isTrialBanner || !isExpiredBanner) && isAdmin ">
  <div class="trialVersionDiv" *ngIf="!isTrialBanner">
    <div class="info_section">
      <div class="icon_section">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0002 0.25C8.07188 0.25 6.18682 0.821828 4.58344 1.89317C2.98006 2.96451 1.73038 4.48726 0.992423 6.26884C0.254469 8.05042 0.0613861 10.0108 0.437592 11.9021C0.813798 13.7934 1.7424 15.5307 3.10596 16.8943C4.46952 18.2579 6.2068 19.1865 8.09812 19.5627C9.98943 19.9389 11.9498 19.7458 13.7314 19.0078C15.513 18.2699 17.0357 17.0202 18.1071 15.4168C19.1784 13.8134 19.7502 11.9284 19.7502 10C19.7473 7.41504 18.7192 4.93679 16.8913 3.10894C15.0635 1.28109 12.5852 0.252926 10.0002 0.25ZM9.8128 4.75003C10.0353 4.75003 10.2528 4.81601 10.4378 4.93963C10.6228 5.06325 10.767 5.23895 10.8522 5.44452C10.9373 5.65008 10.9596 5.87628 10.9162 6.09451C10.8728 6.31274 10.7656 6.5132 10.6083 6.67053C10.451 6.82786 10.2505 6.93501 10.0323 6.97842C9.81405 7.02183 9.58785 6.99955 9.38228 6.9144C9.17671 6.82925 9.00101 6.68506 8.8774 6.50005C8.75378 6.31505 8.6878 6.09754 8.6878 5.87503C8.6878 5.57667 8.80633 5.29052 9.0173 5.07954C9.22828 4.86856 9.51443 4.75003 9.8128 4.75003ZM10.7503 15.25H10.0003C9.80134 15.25 9.61059 15.171 9.46994 15.0303C9.32928 14.8897 9.25026 14.6989 9.25025 14.5V10C9.05134 10 8.86058 9.92098 8.71992 9.78033C8.57927 9.63968 8.50025 9.44891 8.50025 9.25C8.50025 9.05109 8.57927 8.86032 8.71992 8.71967C8.86058 8.57902 9.05134 8.5 9.25025 8.5H10.0003C10.1992 8.50001 10.3899 8.57903 10.5306 8.71968C10.6712 8.86033 10.7502 9.05109 10.7503 9.25V13.75C10.9492 13.75 11.1399 13.829 11.2806 13.9697C11.4212 14.1103 11.5003 14.3011 11.5003 14.5C11.5003 14.6989 11.4212 14.8897 11.2806 15.0303C11.1399 15.171 10.9492 15.25 10.7503 15.25Z"
            fill="#21BC68" />
        </svg>
      </div>
      <div class="trialLabel">
        <p style="margin: 0px;" *ngIf="quotaStatus=='trial'">Trial version</p>
        <p style="margin: 0px;" *ngIf="quotaStatus=='active'">Your current plan will expire in {{daysRemaining}}
          day{{daysRemaining !== 1 ? 's' : ''}}</p>
        <div class="trialSubLabel" *ngIf="quotaStatus=='trial'">
          {{ daysRemaining}} day{{daysRemaining !== 1 ? 's' : ''}} remaining
          <span>({{availableUser}} of {{totalUser}} user{{totalUser !== 1 ? 's' : ''}} remaining)</span>
        </div>
        <div class="trialSubLabel" *ngIf="quotaStatus=='active'">
          renew it to continue usage
        </div>
      </div>
    </div>
    <div class="btn_group_trial">
      @if (isShowButton) {
      <button class="trialBtnIncreaseLimit" (click)="renewPlanAction()" *ngIf="isAdmin && quotaStatus=='active'">Renew plan</button>
      <button class="trialBtnIncreaseLimit" (click)="renewPlanAction()" *ngIf="isAdmin && quotaStatus=='trial'">Buy plan</button>
      }

      <svg xmlns="http://www.w3.org/2000/svg" class="close_icon" viewBox="0 0 30 30" width="30px" height="30px"
        (click)="trialCloseBanner()" *ngIf="!closeButton">
        <path
          d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
      </svg>
    </div>
  </div>

  <div class="expVersionDiv" *ngIf="!isExpiredBanner">
    <div class="info_section">
      <div class="icon_section">
        <i class="material-icons warn_icon">warning</i>
      </div>
      <div class="trialLabel">
        <p style="margin: 0px;" *ngIf="quotaStatus=='trial-expired'">Your free trial has expired</p>
        <p style="margin: 0px;" *ngIf="quotaStatus=='expired'">Your plan has expired</p>
        <div class="trialSubLabel" *ngIf="quotaStatus=='trial-expired'">Renew it to regain access</div>
        <div class="trialSubLabel" *ngIf="quotaStatus=='expired'">Renew it to regain access</div>
      </div>
    </div>
    <div class="btn_group_trial">
      @if (isShowButton) {
        <p class="warn_section" style="margin: 0px;">Click to <a (click)="renewPlanAction()" *ngIf="isAdmin">Renew plan</a></p>
      }
      <svg xmlns="http://www.w3.org/2000/svg" class="close_icon" viewBox="0 0 30 30" width="30px" height="30px"
        (click)="trialCloseBanner()" *ngIf="!closeButton">
        <path
          d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
      </svg>
      <!-- <i class="material-icons close_icon">close_small</i> -->
    </div>
  </div>
</div>