import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import * as moment from "moment";
import * as _ from "lodash";

import { LogsDisplayModalComponent } from "../logs-display-modal/logs-display-modal.component";
import { LoginService } from "../../Services/login.service";
import { ProjectService } from "../../Services/project.service";
import { element } from "protractor";

declare var $;
@Component({
  selector: "app-time-logs",
  templateUrl: "./time-logs.component.html",
  styleUrls: ["./time-logs.component.css"],
})
export class TimeLogsComponent implements OnInit {
  @Input("user") user;
  @Input("usedFor") usedFor;
  @Output() name = new EventEmitter<any>();

  userLog: any = [];
  userLogDuplicate: any;

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  currentPage: any = 0;
  loading = false;
  maxDate = moment();
  usedApp: any = [];
  usedAppDuplicate: any;
  displayedColumns2: string[];
  dataSource2: MatTableDataSource<any>;
  @ViewChild("sort1", { static: true }) sort1: MatSort;
  @ViewChild("sort2", { static: true }) sort2: MatSort;
  @ViewChild("paginator1", { static: true }) paginator1: MatPaginator;
  @ViewChild("paginator2", { static: true }) paginator2: MatPaginator;

  pieChartData: any = [];
  pieChartLabels: any = [];
  ischartDataLoaded: boolean = false;
  constructor(
    public _loginService: LoginService,
    public _projectService: ProjectService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator1;
    // this.dataSource2.paginator = this.paginator2;
  }

  ngOnChanges(changes: SimpleChanges) {
 
    if (changes.user && changes.user.currentValue) {
      this.user = changes.user.currentValue;
    }
    if (this.user) {
      if (this.usedFor === "timeLogs") this.getUserTimeLog();
      else if (this.usedFor === "appUsages") this.getAppUsage();
    }
  }

  getAppUsage() {
    this.loading = true;
    // this.user means userId

    // this.dataSource

    this._projectService
      .getUserAppUsage(this.user, { perPage: 1000, offset: 0 })
      .subscribe(
        async (res: any) => {
          res.data.forEach((element: any) => {
            if (element.usageTime > 60) {
              // console.log("element.idealTime", element.idealTime);
              element.idealTime =
                element.idealTime > 60 ? element.idealTime : 0;
              this.usedApp.push(element);
            }
          });

          const dup = this.usedApp;
          this.usedAppDuplicate = JSON.parse(JSON.stringify(dup));
          // await this.formatDate(this.userLog);
          this.displayedColumns2 = [
            "appName",
            "usageTime",
            // "idealTime",
            "openCounts",
            "action2",
          ];
          this.loading = false;

          this.pieChartData = [];
          this.pieChartLabels = [];

          let chartData = JSON.parse(JSON.stringify(this.usedApp));

          chartData = chartData.sort(function (a, b) {
            return a.usageTime - b.usageTime;
          });

          // Create for chart

          chartData = chartData.reverse();

          // Create for chart
          chartData.forEach((element, index) => {
            if (index < 10) {
              this.pieChartLabels.push(element.appName);
              this.pieChartData.push(
                (element.usageTime / (60 * 60)).toFixed(2)
              );
            }
          });

          this.ischartDataLoaded = true;
          this.dataSource2 = new MatTableDataSource<any>(this.usedApp);
          this.dataSource2.sort = this.sort2;
          this.dataSource2.paginator = this.paginator2;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  getUserTimeLog() {
    this.loading = true;
    // console.log("getUserTimeLog");
    let objToSend = {
      userId: this.user,
    };
     this._loginService.getLogsById(objToSend).subscribe(
      async (res) => {
        this.userLog = res;
        await this.formatDate(this.userLog);

        const dup = this.userLog;
        this.userLogDuplicate = JSON.parse(JSON.stringify(dup));

        this.displayedColumns = [
          "date",
          "difference",
          // "inActivityTime",
          "action",
        ];

        this.dataSource = new MatTableDataSource<any>(this.userLog);
        // this.dataSource.sort = this.sort1;
        // this.dataSource.paginator = this.paginator1;
      //  this.currentMonth();
        this.loading = false;
        this.dataSource.sort = this.sort1;
        this.dataSource.paginator = this.paginator1;
        // this.dataSource.sort = this.sort;
      },
      (err) => {
        this.loading = false;
      }
    );
    // this._loginService.getLogsById(objToSend).subscribe(
    //   async (res) => {
    //     this.userLog = res;

    //     await this.formatDate(this.userLog);

    //     const dup = this.userLog;
    //     this.userLogDuplicate = JSON.parse(JSON.stringify(dup));

    //     this.displayedColumns = [
    //       "date",
    //       "difference",
    //       // "inActivityTime",
    //       "action",
    //     ];

    //     this.dataSource = new MatTableDataSource<any>(this.userLog);
    //     // this.dataSource.sort = this.sort1;
    //     // this.dataSource.paginator = this.paginator1;
    //     this.currentMonth();
    //     this.loading = false;
    //     this.dataSource.sort = this.sort1;
    //     this.dataSource.paginator = this.paginator1;
    //     // this.dataSource.sort = this.sort;
    //   },
    //   (err) => {
    //     this.loading = false;
    //     console.log("error ==>", err);
    //   }
    // );
  }
  currentMonth() {
   
    var date = new Date(new Date().setHours(0, 0, 0, 0));
   
    var month = date.getMonth();
    date.setDate(1);
    var all_days = [];
    while (date.getMonth() == month) {
      var d =
        date.getDate().toString().padStart(2, "0") +
        "-" +
        month.toString().padStart(2, "0") +
        "-" +
        date.getFullYear();
      all_days.push(d);
      date.setDate(date.getDate() + 1);
    }
    this.filterScreenShot({ dateRange: all_days });
  }

  selectDate(event: any) {
    if (event.end) {
      let startDate = event.start._d;
      let endDate = event.end._d;
   
      let date = [];
      while (moment(startDate) <= moment(endDate)) {
        startDate = moment(startDate).utcOffset("+5:30").format("YYYY-MM-DD");
        let reverse = startDate.split("-").reverse().join("-");
        date.push(reverse);
        startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
      }
      this.filterScreenShot({ dateRange: date });
    }
  }

  formatDate(date) {
    if (this.usedFor === "timeLogs") {
      _.forEach(date, (singleDate, index) => {
        singleDate.date = moment(singleDate.date)
          .subtract(1, "days")
          .format("DD-MM-YYYY");
        singleDate.inActivityTime = moment("1900-01-01 00:00:00")
          .add(singleDate.inActivityTime, "seconds")
          .format("HH:mm:ss");
        // console.log(moment("1900-01-01 00:00:00").add(singleDate.inActivityTime, 'seconds').format("HH:mm:ss"));
      });
      return;
    } else {
      _.forEach(date, (singleDate, index) => {
        //   singleDate.date = moment(singleDate.date)
        //     .subtract(1, "days")
        //     .format("DD-MM-YYYY");
        //   singleDate.inActivityTime = moment("1900-01-01 00:00:00")
        //     .add(singleDate.inActivityTime, "seconds")
        //     .format("HH:mm:ss");
        // console.log(moment("1900-01-01 00:00:00").add(singleDate.inActivityTime, 'seconds').format("HH:mm:ss"));
      });
      return;
    }
  }

  showLogs(index, type) {
    let infoData = [];
    if (type == "appInfo") {
  

      this.usedApp[index].appInfo.forEach((element) => {
        if (element.usageTime > 60) {
          element.idealTime =
            element.idealTime >= 60 ? element.idealTime : "N/A";
          infoData.push(element);
        }
      });

    }

    index = this.currentPage * 5 + index;
    
    const dialogRef = this.dialog.open(LogsDisplayModalComponent, {
      width: type !== "appInfo" ? "500px" : "750px",
      maxHeight: "calc(100vh - 90px)",
      height: "auto",
      data: {
        user: this.userLog[index] || {},
        type: type,
        appInfo: infoData,
        appName:
          this.usedApp[index] && this.usedApp[index].appName
            ? this.usedApp[index].appName
            : {},
      },
    });
  }

  filterScreenShot(params) {
    // console.log("filterScreenShot");
    // console.log("params ==>", params.dateRange);
    let filteredSS = [];
    _.forEach(this.userLogDuplicate, (singleLog, ssIndex) => {
      //   console.log("singleLog", singleLog);
      _.forEach(params.dateRange, (singleDate, dateIndex) => {
        // console.log("singleDate", singleDate);
        // console.log(singleLog.includes(singleDate));
        if (singleLog.date == singleDate) {
          filteredSS.push(singleLog);
        }
      });
    });
    this.userLog = filteredSS;
    this.dataSource = new MatTableDataSource(this.userLog);
  }

  clearDatePicker() {
    this.userLog = this.userLogDuplicate;
    this.dataSource = new MatTableDataSource(this.userLog);
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator1;

    // console.log($('#dateRangePicker').val());
    $("#dateRangePicker").val(null);
  }

  onPaginateChange(event) {
    this.currentPage = event.pageIndex;
  }

  //   makeCloseAt(startTime, usedTimeSecond){
  // 	console.log(startTime)
  // 	console.log(usedTimeSecond);

  //   }

  getUsageTime(usageTime) {
    usageTime = parseInt(usageTime);
    if (!usageTime) {
      return "N/A";
    } else if (usageTime > 0 && usageTime < 60) {
      // return "< minute";
      return usageTime + " Seconds";
      // Return in Seconds
    } else if (usageTime >= 60 && usageTime <= 3600) {
      // Return in minutes
      return Math.floor(usageTime / 60) == 1
        ? "1 Minute"
        : Math.floor(usageTime / 60) + " Minutes";
      // return "< hour";
    } else {
      const text = this.convertSecondsToHoursMinutes(usageTime);
      if (text.minutes > 0) return `${text.hours} Hrs ${text.minutes} Minutes`;
      else return `${text.hours} Hrs`;
    }
  }

  convertSecondsToHoursMinutes(seconds) {
    const hours = Math.floor(seconds / 3600); // Calculate the number of hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate the remaining minutes

    return {
      hours: hours,
      minutes: minutes,
    };
  }

  sortDataUsed(sort: Sort) {
    const data = this.usedApp.slice();
    if (!sort.active || sort.direction === "") {
      this.usedApp = data;
      return;
    }

    this.usedApp = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "openAt":
          return this.compare(a.openAt, b.openAt, isAsc);
        case "appName":
          return this.compare(a.appName, b.appName, isAsc);
        case "appScreen":
          return this.compare(a.appScreen, b.appScreen, isAsc);
        case "idealTime":
          return this.compare(a.idealTime, b.idealTime, isAsc);
        case "usageTime":
          return this.compare(a.usageTime, b.usageTime, isAsc);
        case "openCounts":
          return this.compare(a.openCounts, b.openCounts, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource2 = new MatTableDataSource<any>(this.usedApp);
    this.dataSource2.sort = this.sort2;
    this.dataSource2.paginator = this.paginator2;
  }

  sortDataLogs(sort: Sort) {
    const data = this.userLog.slice();
    if (!sort.active || sort.direction === "") {
      this.userLog = data;
      return;
    }

    this.userLog = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "difference":
          return this.compare(a.difference, b.difference, isAsc);
        case "inActivityTime":
          return this.compare(a.inActivityTime, b.inActivityTime, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource<any>(this.userLog);
    this.dataSource.sort = this.sort1;
    this.dataSource.paginator = this.paginator1;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
