import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from '../Services/sharedService';
import { ProjectService } from '../Services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";
import { passwordValidator } from '../password-validators';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent {

  resetPasswordForm: UntypedFormGroup;
  hide = true;
  email = '';
  code = '';
  isDisable = false;
  constructor(private _sharedServices: SharedService, private _projectService: ProjectService, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    this._sharedServices.sideMenuSubject.next(false);
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        let data = JSON.parse(params['data'])
        this.email = data.email;
        this.code = data.code;
      }
    });
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(20), passwordValidator()]),
      confirmpassword: new UntypedFormControl("", [Validators.required]),
    }, { validators: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: UntypedFormGroup) {
    return form.get('password').value === form.get('confirmpassword').value ? null : { 'mismatch': true };
  }

  resetPassAction() {
    this.isDisable = true;

    if (!this.resetPasswordForm.valid || this.resetPasswordForm.get('password').value !=
      this.resetPasswordForm.get('confirmpassword').value) {
      this.isDisable = false;
      return
    } else {
      this
        ._projectService
        .resetPasword(this.resetPasswordForm.value.password, this.email, this.code).subscribe((user: any) => {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Your password has been successfully reset.Please login to continue',
            timer: 2000,
            showConfirmButton: false,
          })
          this.router.navigate(["/reset-sucessfully"]);
          this.isDisable = false;
        }, err => {
          this.isDisable = false;
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        })
    }
  }
}
