import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class LoginGuard  {
	constructor(private router: Router){
	}
	
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(!JSON.parse(localStorage.getItem('isLogedIn'))){
			// alert('unauthorize access');
			this.router.navigate(['/login']);
			return false;
		}
		return true;
	}
}