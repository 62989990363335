<a (click)="openEmployee()" style="cursor: pointer" class="toast-class">
  <div class="row toast-body">
    <div class="col" *ngIf="toastData.imgURL">
      <img [src]="openImage()" class="toast-img" />
    </div>
    <div class="col-10 toast-right">
      <h6 class="toast-user">{{toastData.userName}}</h6>
      <span class="toast-desc">{{toastData.type}}</span> 
      <span *ngIf="toastData.appName" class="toast-desc"><span class="toast-desc-divider">|</span>{{toastData.appName}}</span>
    </div>
  </div>
</a>
