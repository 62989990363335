import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { trigger, transition, query, style, animate, group } from '@angular/animations';

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: "app-logs-display-modal",
  templateUrl: "./logs-display-modal.component.html",
  styleUrls: ["./logs-display-modal.component.css"],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
})
export class LogsDisplayModalComponent implements OnInit {
  counter: number = 0;
  dataSource: any;
  displayedColumns: any[];
  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorPageSize", { static: true })
  paginatorPageSize: MatPaginator;
  @ViewChild("sort", { static: true }) sort: MatSort;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.type === "appInfo") {

      // console.log("Data are comes in modal:" , this.data);

      this.displayedColumns = [
        "windowName",
        "usage",
        "idle",
        "in",
        "out",
      ];

      this.sortDataUsed({ active: "usageTime", direction: "desc" });

      // this.dataSource = new MatTableDataSource(this.data.appInfo);
      this.dataSource = new MatTableDataSource(this.data.appInfo[0].sessions);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

    if (this.data.type === "timeline") {

      // console.log("Data are comes in modal:" , this.data);

      this.displayedColumns = [
        "appName",
        "windowName",
        "usage",
        "idle",
        "in",
        "out",
      ];

      this.sortDataUsed({ active: "usageTime", direction: "desc" });

      this.dataSource = new MatTableDataSource(this.data.appInfo);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close();
  }

  getUsageTime(usageTime) {
    // console.log("DATA : " , usageTime);
    usageTime = parseInt(usageTime);
    if (!usageTime) {
      return "N/A";
    } else if (usageTime > 0 && usageTime < 60) {
      return usageTime + " Sec";
    } else if (usageTime > 60 && usageTime < 3600) {
      return Math.floor(usageTime / 60) + " Minutes";
    } else {
      const text = this.convertSecondsToHoursMinutes(usageTime);
      if (text.minutes > 0) return `${text.hours} Hrs ${text.minutes} Minutes`;
      else return `${text.hours} Hrs`;
    }
  }

  convertSecondsToHoursMinutes(seconds) {
    const hours = Math.floor(seconds / 3600); // Calculate the number of hours
    const minutes = Math.floor((seconds % 3600) / 60); // Calculate the remaining minutes

    return {
      hours: hours,
      minutes: minutes,
    };
  }

  sortDataUsed(sort: Sort) {
    console.log(sort);
    const data = this.data.appInfo.slice();
    if (!sort.active || sort.direction === "") {
      this.data.appInfo = data;
      return;
    }
    this.data.appInfo = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "windowName":
          return this.compare(a.windowName, b.windowName, isAsc);
        case "usage":
          return this.compare(a.usage, b.usage, isAsc);
        case "idle":
          return this.compare(a.idle, b.idle, isAsc);
        case "in":
          return this.compare(a.in, b.in, isAsc);
        case "out":
          return this.compare(a.out, b.out, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource<any>(this.data.appInfo);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  onNext() {
    if (this.counter != this.data.appInfo.length - 1) {
      this.counter++;
      this.dataSource = new MatTableDataSource(this.data.appInfo[this.counter].sessions);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  onPrevious() {
    if (this.counter > 0) {
      this.counter--;
      this.dataSource = new MatTableDataSource(this.data.appInfo[this.counter].sessions);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
}
