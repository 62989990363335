<!-- <div class="row teamAnalysis" *ngIf="searchArr && searchArr.length; else searchBox">
    <div class="col s4">
        <p class="totalTeam">Total Team ({{_userData.length}})</p>
    </div>
    <div class="col s8">
        <mat-form-field class="mat-form">
            <mat-label>Search Team</mat-label>
            <input matInput placeholder="Enter Team Name" #searchInp (keyup)="searchTeams(searchInp.value)">
        </mat-form-field>
    </div>
</div> -->

<ng-template #searchBox>
  <ngx-skeleton-loader
    *ngIf="!noDataFound"
    count="1"
    [theme]="{ 'border-radius': '2', height: '50px' }"
  ></ngx-skeleton-loader>
</ng-template>
<mat-card>
  <div class="row teams-row">
    <div class="col s12 teams-contain">
      <h4>Teams</h4>
    </div>
  </div>
  <div *ngIf="!loader">
    <div
      class="row teams-row single-team dropdown-hover"
      *ngFor="let team of _userData"
      (click)="goToTeamEmiter(team)"
    >
      <div class="col s7 d-flex avtar-container">
        <div class="avtar">
          <ngx-avatars name="{{ team.title.rendered }}" size="38"></ngx-avatars>
        </div>

        <div class="">
          <h6 class="text-wrap"
            style="text-transform: capitalize"
            [innerHTML]="team.title.rendered"
          ></h6>
          <p>
            <span style="font-weight: 500"
              >Created At:<span> {{ team.date | date }}</span></span
            >
          </p>
        </div>
      </div>
      <div class="col s5 badge-container">
        <div class="badge">
          <span>
            {{ getTotalMemberCount(team.meta) }}
            {{ getTotalMemberCount(team.meta) > 1 ? "Members" : "Member" }}
          </span>
        </div>
        <span (click)="openMenu(team)" style="cursor: pointer" *ngIf="isAdmin"
          ><span class="material-symbols-outlined"> more_horiz </span></span
        >
        <div class="more-button-dropdowns" *ngIf="team.isMenuOpened && isAdmin">

          <div class="row dropdown-hover" (click)="openTeamEditModal(team.id)">
            <div class="col s12">
              <p>
                <span
                [ngClass]="'online'"                
                class="material-symbols-outlined"
                >Edit</span
              >
                Edit
          
              </p>
            </div>
          </div>

          <div class="row dropdown-hover" (click)="deleteTeam(team)">
            <div class="col s12">
              <p class="delete">
                <span
                [ngClass]="'online'"                
                class="material-symbols-outlined"
                >delete</span
              >
                delete
          
              </p>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <!-- <div class="row">
      <div class="col s12 view-all-col">
        <span>View All Teams</span>
      </div>
    </div> -->
  </div>
</mat-card>

<!-- <div class="row teamsList" *ngIf="_userData && _userData.length; else elseBlock">
    <div *ngIf="!loader">
        <div class="col s12 m12">
            <div class="card horizontal" *ngFor="let team of _userData" (click)="goToTeamEmiter(team)"
                style="cursor: pointer;">
                <div class="card-stacked">
                    <div class="card-content">
                       
                        <div class="row">
                            <div class="col s8">
                                <h5 style="text-transform:capitalize;" [innerHTML]="team.title.rendered"></h5>
                            </div>
                            <div class="col s4">
                                <div class="col feature" style="margin-top: 8px;">
                                    <span><i style="font-size: 19px;" class="large material-icons">groups</i>
                                        {{getTotalMemberCount(team.meta)}} {{(getTotalMemberCount(team.meta) > 1 ? 'Members' : 'Member')}}
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span style="font-weight: 500;">Created At :<span>{{team.date |
                                    timeago:true}}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div *ngIf="!_userData.length">
  <div *ngIf="skeletonLoader">
    <ngx-skeleton-loader
      count="5"
      [theme]="{ 'border-radius': '2', height: '100px' }"
    ></ngx-skeleton-loader>
  </div>
  <div *ngIf="errorPhotoShow && !loader && !skeletonLoader">
    <div class="empty_list z-depth-2">
      <img src="assets/images/empty_list.png" alt="empty list image" />
    </div>
  </div>
</div>

<!-- <div #teamModal class="modal">
  <app-add-project [teamData]="_teamMembers"></app-add-project>
</div> -->

<!-- <div #userModal class="modal">
    <app-add-project [teamData]="updatingData"></app-add-project>    
</div> -->
