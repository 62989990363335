<div class="container-fluid">
	<div class="row login-page-container">

		<div class="col s12 m12 l12 login-container">
			<div class="welcome-contain">
				<h1>Enter 4-digit verification code</h1>
				<p>The verification code sent to your registered email, please enter the code</p>
			</div>
			<div class="">
				<form [formGroup]='VarificationForm'>
					<div class="row" style="margin-bottom: 0;">
						<div class="col s12">
							<h4>Verification Code</h4>
						</div>
						<div class="col s12 code-input-container">
							<input matInput #code1Input type="number" formControlName="code1" class="code-input"
								style="height: 56px!important;" onkeypress="if (this.value.length > 0) return false;"
								(keyup)="(code1Input.value.length > 0) ? code2Input.focus() : ''">
							<input #code2Input type="number" formControlName="code2" class="code-input"
								style="height: 56px!important;" onkeypress="if (this.value.length > 0) return false;"
								(keyup)="(code2Input.value.length > 0) ? code3Input.focus() : ''">
							<input #code3Input type="number" formControlName="code3" class="code-input"
								style="height: 56px!important;" onkeypress="if (this.value.length > 0) return false;"
								(keyup)="(code3Input.value.length > 0) ? code4Input.focus() : ''">
							<input #code4Input type="number" formControlName="code4" class="code-input"
								style="height: 56px!important;" onkeypress="if (this.value.length > 0) return false;">
						</div>
						<mat-error *ngIf="
									(VarificationForm.get('code1')?.hasError('required') &&
									 (VarificationForm.get('code1')?.dirty ||
									 VarificationForm.get('code1')?.touched)) && (VarificationForm.get('code2')?.hasError('required') &&
									 (VarificationForm.get('code2')?.dirty ||
									 VarificationForm.get('code2')?.touched)) && (VarificationForm.get('code3')?.hasError('required') &&
									 (VarificationForm.get('code3')?.dirty ||
									 VarificationForm.get('code3')?.touched)) && (VarificationForm.get('code4')?.hasError('required') &&
									 (VarificationForm.get('code4')?.dirty ||
									 VarificationForm.get('code4')?.touched))">
							Please enter verification code </mat-error>
						<mat-error *ngIf="isError">{{isError}}</mat-error>
						<div class="col s12">
							<button mat-raised-button class="btn  signInBtn"
								[disabled]="!VarificationForm.valid || isDisableVarification" (click)="varifyAction()"
								extended>Verify</button>
						</div>
						<div class="col s12 resendCode" *ngIf="isResend">
							<span>Resend code after {{interValValue}} Sec</span>
						</div>
						<div class="col s12 resendCode">
							<button class="resendCodebtn" [disabled]="isResend" (click)="resendCode()">Resend
								code</button>
						</div>

						<!-- <div class="col s12 divider-contain">
									<div class="divider"><mat-divider></mat-divider></div>
									<div class="or"><p>Or</p></div>
									<div class="divider"><mat-divider></mat-divider></div>
								</div>
								<div class="col s12">
									<p class="sign-up">Dont have an account?<span> Sign Up</span></p>
								</div> -->

					</div>
				</form>
			</div>
		</div>
	</div>
</div>