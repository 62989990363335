import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/Services/project.service';
import { WindowRefService } from 'src/app/Services/window-ref.service';
import { ApplyCouponComponent } from '../apply-coupon/apply-coupon.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-user-plans',
  templateUrl: './user-plans.component.html',
  styleUrl: './user-plans.component.css',
  providers: [DatePipe],
})
export class UserPlansComponent {
  totalUser = 0;
  totalUserCurrent = 0;
  name = '';
  email = '';
  phone_number = '';
  user = 1;
  monthlyBackground = 'monthly-btn-bg-color';
  yearlyBackground = '';

  moreUserBackground = 'monthly-btn-bg-color';
  payAcdvanceBackground = '';
  planVal = 0;
  selected_plan_type = 'main';
  plan_id = 0;
  plan_data;
  plan_type = 'Monthly';
  current_plan_type = 'Monthly';

  order_Id = '';
  monthly_price = 0;
  yearly_price = 0;
  selected_price = 0;
  min = 1;
  today_date;
  expire_date;
  loading = false;
  strPlan = ''
  isButtonDisable = false;
  isAdvancePayment = false;
  isToggle = false;
  strCaption = '';
  strSubCaption = '';
  quota_status = '';
  strDateCption = '';
  strSubDateCaption = '';
  isGST = false;
  quota_allowed = 1;
  oneUserPrice = 1;
  isHint = false;
  txtGst = '';
  isGSTVerified = false;
  gstPrice = 0;
  isAdmin = true;
  useUser = 0;
  availableUser = 0;
  quotaStatus = '';
  quota_expired_date;
  currentSelectedPlant: any = "";

  constructor(private router: Router, public _dialog: MatDialog, private _projectService: ProjectService, private _wrs: WindowRefService, private _snackBar: MatSnackBar, private datePipe: DatePipe) {
    this.isAdvancePayment = false;
    this.getPlanDetail();
  }

  sendPaymentRequest() {
    this.checkPlanDetail()
  }

  getUser() {
    this.loading = true;
    // this.isHint = false;
    let roleArr = [];
    this._projectService.getUser().subscribe((data: any) => {
      this.currentSelectedPlant = data.meta.plan_name ? data.meta.plan_name : '';
      this.quota_status = data.meta.quota_status;
      this.name = data.name;
      this.email = data.email;
      this.phone_number = data.meta.phone_number;
      this.expire_date = data.meta.expires_at;
      this.today_date = data.meta.today_date;
      let adv_pay = data.meta.adv_pay ? Number(data.meta.adv_pay) : 0;
      let checkDayDiff = this.expiringDate(this.expire_date, this.today_date)

      // if(checkDayDiff < 5 && checkDayDiff >= -3){
      //   console.log(checkDayDiff,"checkDayDiff----")
      // }
      roleArr = data.role;
      let user_role = roleArr.length ? roleArr[0] == 'employee' ? roleArr[1] : roleArr[0] : '';
      let availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 1 : Number(data.meta.quota_allowed) - Number(data.meta.quota_remaining);
      availableUser = availableUser <= 0 ? 1 : availableUser;

      this.user = Number(data.meta.quota_allowed) > 0 ? Number(data.meta.quota_allowed) : 1;
      this.quota_allowed = Number(data.meta.quota_allowed) > 0 ? Number(data.meta.quota_allowed) : 1;
      this.min = Number(availableUser);
      // this.totalUser = this.user * Number(this.selected_price)

      this.totalUserCurrent = data.meta.quota_allowed < 0 ? 0 : data.meta.quota_allowed;
      this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
      this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;
      this.useUser = (data.meta.quota_allowed - this.availableUser) < 0 ? 0 : (data.meta.quota_allowed - this.availableUser)

      let quota_Status = data.meta.quota_status;
      if (quota_Status == 'active' || quota_Status == 'Active' || quota_Status == 'trial' || quota_Status == 'Trial') {
        this.quotaStatus = 'active';
        if (data.meta.expires_at) {
          let day_left = this.getRemainingDate(data.meta.expires_at)
          if (day_left < 0) {
            this.quotaStatus = 'expired';
          }
        }
      }
      if (quota_Status == 'trial-expired' || quota_Status == 'Trial-expired' || quota_Status == 'expired' || quota_Status == 'Expired') {
        this.quotaStatus = 'expired';
      }

      if (data.meta.expires_at) {
        this.quota_expired_date = this.formattedDate(data.meta.expires_at);
      }

      if (data.meta.quota_status == 'active') {
        if (checkDayDiff <= adv_pay) {
          this.isAdvancePayment = true;
        }
        if (this.isToggle == false) {
          this.strPlan = 'Increase User Limit';
          this.selected_plan_type = 'addon';
          this.user = 1;
          this.min = 1;
        } else {
          this.strPlan = 'Advance Pay';
          this.selected_plan_type = 'main';
        }
        this.selectPlanTypeAction((data.meta.plan_name).toLowerCase());
      } else {
        this.selected_plan_type = 'main'
        this.strPlan = 'Buy now';
      }

      let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if (adminRole.length == 0) {
        this.isAdmin = false;
      } else {
        this.isAdmin = true;
      }

      this.calculateTotalAmount();
      this.current_plan_type = data.meta.plan_name ? data.meta.plan_name : '-';

      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }
  async getPlanDetail() {
    this.loading = true;
    await this._projectService.checkUserPlan().subscribe((data: any) => {
      this.plan_data = data;
      let selectedData = this.plan_data.filter(val => val.name == this.plan_type)
      const objMonth = this.plan_data.filter(val => val.name == 'Monthly')
      const objYearly = this.plan_data.filter(val => val.name == 'Yearly')

      this.monthly_price = Number(objMonth[0].price);
      this.yearly_price = Number(objYearly[0].price);


      this.plan_id = selectedData[0].id;
      // this.totalUser = this.user * Number(selectedData[0].price)
      this.selected_price = Number(selectedData[0].price);
      this.loading = false;
      this.getUser();


    }, (err) => {
      this.loading = false;
    })

  }
  checkPlanDetail() {
    this.isButtonDisable = true;
    this._projectService.purchasePlan(this.plan_id, this.user, this.selected_plan_type, this.txtGst).subscribe((data: any) => {
      // this.totalUser = data.amount;
      this.order_Id = data.order_id;
      this._wrs.payWithRazor(this.name, this.email, this.phone_number, data.order_id, data.amount, data.callback_url);
      this.isButtonDisable = false;

    }, err => {
      this.isButtonDisable = false;

      // this._snackBar.open(err.error.message, 'Undo', {
      //   duration: 3000
      // })

      swal.fire({
        title: 'Error!',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'Delete Employees'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/employees']);
        }
      })
    })
    // this.totalUser = this.user * 200;
  }

  AdvancePaymentRequest() {

  }
  isToggleChange(event) {
    this.isToggle = event.checked;
    if (this.isToggle) {
      this.strPlan = "Advance Pay"
    }
    this.getPlanDetail();
  }
  onKey(value?: any) {
    if (value >= this.min) {
      this.user = Math.trunc(Number(value));
      // this.totalUser = this.user * this.selected_price;
      this.calculateTotalAmount();
      // this.isHint = false;
    } else {
      this.totalUser = 0;
      // this.user = Math.trunc(Number(value));
      // this.calculateTotalAmount();

      // this.isHint = true;
      if (value <= 0 && value != '') {
        this.user = 1;
        this.calculateTotalAmount();
      } else {
        if (value != '') {
          this.user = Math.trunc(Number(value));
          this.calculateTotalAmount();
        }

      }
    }


  }

  selectPlanAction(strplan) {
    if (this.selected_plan_type != 'addon') {
      this.selectPlanTypeAction(strplan);
    }
  }

  selectPlanTypeAction(strplan) {
    if (strplan == 'monthly') {
      this.monthlyBackground = 'monthly-btn-bg-color';
      this.yearlyBackground = '';
      this.plan_type = 'Monthly'
    } else {
      this.monthlyBackground = '';
      this.yearlyBackground = 'monthly-btn-bg-color';
      this.plan_type = 'Yearly'
    }
    let selectedData = this.plan_data.filter(val => val.name == this.plan_type)
    this.plan_id = selectedData[0].id;
    // this.totalUser = this.user * Number(selectedData[0].price)
    this.selected_price = Number(selectedData[0].price);
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    let total_Day;
    let current = this.plan_type.toLocaleLowerCase();
    this.strSubCaption = '';
    if (current == 'monthly') {
      total_Day = 30;
    } else {
      total_Day = 365;
    }
    let day_diff;
    if (this.expire_date) {
      day_diff = this.expiringDate(this.expire_date, this.today_date)
    }
    if (this.selected_plan_type == 'main') {
      this.totalUser = this.user * this.selected_price;
      this.strDateCption = this.formatDuration(total_Day, current)
      // this.strCaption = this.user + ' users for ' +total_Day+'days'
      this.strCaption = this.user + ' Users'
      if (this.isAdvancePayment && this.isToggle) {
        if (this.user > this.quota_allowed) {
          let more_user_price = this.adonePriceCalculation(this.user - this.quota_allowed, day_diff, total_Day)
          if ((this.user - this.quota_allowed) == 0) {
            // this.strCaption = this.user +  ' users for ' +total_Day+'days'
            this.strCaption = this.user + ' Users'
            this.strDateCption = this.formatDuration(total_Day, current)

          } else {
            // this.strCaption = this.quota_allowed + ' users for ' +total_Day+'days'+' and ' + (this.user - this.quota_allowed) + ' users for '+ (total_Day + day_diff) + 'days';
            this.strCaption = this.quota_allowed + ' Users';
            this.strDateCption = this.formatDuration(total_Day, current)
            this.strSubCaption = (this.user - this.quota_allowed) + ' New user'
            this.strSubDateCaption = this.formatDuration((total_Day + day_diff), current)

          }
          this.totalUser = this.totalUser + more_user_price;
        }
      }
    } else {
      if (!(day_diff < 0)) {
        this.adonePriceCalculation(this.user, day_diff, total_Day)
        // this.strCaption = this.user + ' users for' + day_diff +  'days'
        this.strCaption = this.user + ' Users';
        this.strDateCption = this.formatDuration(day_diff, current)
      }
    }
  }

  adonePriceCalculation(user, day_diff, total_Day) {
    let dayPrice = Number((this.selected_price / total_Day).toFixed(2));
    let per_user_price = Number((day_diff * dayPrice).toFixed(0));
    if (this.isAdvancePayment && this.isToggle) {
      return Math.trunc(per_user_price * user);
    } else {
      this.totalUser = Math.trunc(per_user_price * user)
    }
  }
  advancePayAction(strplan) {
    if (strplan == 'add_more') {
      this.moreUserBackground = 'monthly-btn-bg-color';
      this.payAcdvanceBackground = '';
      this.isToggle = false;
    } else {
      this.moreUserBackground = '';
      this.payAcdvanceBackground = 'monthly-btn-bg-color';
      this.strPlan = "Advance Pay"
      this.isToggle = true;
    }
    this.getPlanDetail();
  }

  expiringDate(strExpiredDate, strTodayDate) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = expirationDate.getTime() - new Date(strTodayDate).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference + 1;
  }

  formattedDate(expiresAt): string {
    const parsedDate = new Date(expiresAt);
    const formattedDate = this.datePipe.transform(parsedDate, 'MMM dd, yyyy');

    return formattedDate || '';
  }

  formatDuration(days: number, plan: string): string {
    if (plan === 'yearly') {
      const years = Math.floor(days / 365);
      const remainingDays = days % 365;
      if (years != 0) {
        if (remainingDays != 0) {
          return `${years} Year${years !== 1 ? 's' : ''}, ${remainingDays} Day${remainingDays !== 1 ? 's' : ''}`;
        } else {
          return `1 Year`;
        }
      } else {
        if (remainingDays != 0) {
          return `${days} Day${days !== 1 ? 's' : ''}`;
        } else {
          return `1 Year`;
        }
      }
    } else if (plan === 'monthly') {
      const months = Math.floor(days / 30);
      const remainingDays = days % 30;
      console.log(months, remainingDays, "months")
      if (months != 0) {
        if (remainingDays != 0) {
          return `${months} Month${months !== 1 ? 's' : ''}, ${remainingDays} Day${remainingDays !== 1 ? 's' : ''}`;
        } else {
          return `1 Month`;
        }
      } else {
        if (remainingDays != 0) {
          return `${days} Day${days !== 1 ? 's' : ''}`;
        } else {
          return `1 Month`;
        }
      }
    } else {
      return `${days} Day${days !== 1 ? 's' : ''}`;

    }
  }
  varifyAction() {
    this.isGSTVerified = true;

    // this.isGSTVerified = this.validateGST(this.txtGst);
    console.log("blur", this.isGSTVerified, this.txtGst)
  }

  checkGST() {
    if (this.isGST) {
      if (this.isGSTVerified) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
  validateGST(gstNumber) {
    const gstinPattern = /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([0-9]{1})([A-Z]{2})$/;
    const gstinPattern2 = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/

    // Check if the GSTIN matches the pattern
    if (gstNumber.match(gstinPattern) || gstNumber.match(gstinPattern2)) {
      // Additional checks like state code validation, checksum validation, etc. can be added here
      return true;
    } else {
      return false;
    }
  }

  calculateTotalPriceWithGST(): number {
    const gstRate = 18;
    const gstAmount = (this.totalUser * gstRate) / 100;
    this.gstPrice = Number(gstAmount.toFixed(2));
    return this.gstPrice;
  }

  calculateTotal(): number {
    let totalBill = this.totalUser + this.gstPrice;
    return Number(totalBill.toFixed(2));
  }

  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  applyCouponAction() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.minWidth = 400;

    const dialogRef = this._dialog.open(ApplyCouponComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, "result")
      if (result) {
      } else {
        this._snackBar.open('Coupon apply successfully', 'Undo', {
          duration: 3000
        })
        this.getUser();
      }

    });
  }

  getRemainingDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    let daysRemaining = Math.round(daysDifference);
    return daysRemaining;
  }

}
