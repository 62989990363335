import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from "moment";

@Pipe({
    name: 'timeAgo'
})
@Injectable({
    providedIn: "root",
})
export class CustomTimeAgoPipe implements PipeTransform {

    transform(value: any, from?: any, args?: any): any {
        if (value) {
            let seconds;
            if (from && from == 'lastActive') {
                seconds = Math.floor((+new Date().getTime() - +new Date(value + ' UTC').getTime()) / 1000);
            }
            else {
                seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            }
            if (seconds < 60) // less than 30 seconds ago will show as 'just now'
                return 'Just now';
            const intervals: { [key: string]: number } = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago';
                    } else {
                        return counter + ' ' + i + 's ago';
                    }
            }
        }
        return value;
    }

}
