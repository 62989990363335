import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ProjectService } from 'src/app/Services/project.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrl: './payment-history.component.css',
  providers: [DatePipe]
})
export class PaymentHistoryComponent {
  loadWaiter = false;
  orderList:any = [];
  
constructor(private _projectService:ProjectService,private datePipe: DatePipe){
this.getInvoicedData();
}
sortData(sort: Sort) {
  if (this.orderList.length > 0) {
    const data = this.orderList.slice();
    if (!sort.active || sort.direction === "") {
      this.orderList = data;
      return;
    }

    this.orderList = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "Id":
          return this.compare(a.invoice_no, b.invoice_no, isAsc);
          case "Amount":
            return this.compare(Math.trunc(a.total), Math.trunc(b.total), isAsc);
        case 'Date':
          return this.compare(a.date, b.date, isAsc);
        case 'Users':
          return this.compare(Number(a.plan_data[0].users), Number(b.plan_data[0].users), isAsc);
          case 'Plan':
            return this.compare(a.plan_data[0].title, b.plan_data[0].title, isAsc);
        default:
          return 0;
      }
    });

  }
  
}
compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

onPaginateChange(event) {
   
  // this.pageSize = this.EmpPaginator["_pageSize"];
  // this.page = this.EmpPaginator["_pageIndex"] + 1;
  // this.getDevList();
}

getInvoicedData(){
  this.loadWaiter = true;
  this._projectService.getInvoice().subscribe((data:any)=>{
    this.orderList = [];
    if(data)
    {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          this.orderList.push(data[key]);
        }
      }
    }
    console.log(this.orderList,"data-----")
    this.loadWaiter = false;
  },err=>{
    console.log(err,"data-----")
    this.orderList = [];
    this.loadWaiter = false;
  })
}

formattedDate(expiresAt: any): string {
  if (!expiresAt) {
    return ''; // Handle null or undefined input
  }

  const dateParts = expiresAt.split('/');
  // Construct the date in the format: year, month (0-based), day
  const parsedDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date:', expiresAt);
    return ''; // Handle invalid date input
  }

  const formattedDate = this.datePipe.transform(parsedDate, 'MMM dd, yyyy');
  return formattedDate || '';
}

formatValue(value: any): number | string {
  if (Number.isInteger(Number(value))) {
    return Math.floor(value);
  } else {
    return value; // Convert to string with two decimal places
  }
}
}
