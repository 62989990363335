import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusFilter'
})
export class StatusFilterPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (!value || !args || args == 'total') {
      return value;
    }
    return value.filter(item => item.status.indexOf(args) !== -1);
  }

}
