import { AbstractControl, ValidatorFn } from '@angular/forms';

export function time24FormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null; // Handle empty value separately if needed
    }

    const timePattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;
    if (!timePattern.test(value)) {
      return { 'timeFormat': true };
    }

    return null;
  };
}
