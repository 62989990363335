import { Component, ElementRef, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ProjectService } from '../Services/project.service';
import * as Chart from 'chart.js';
import { ChartToolTipServiceService } from '../Services/chart-tool-tip-service.service';
import { LogsDisplayModalComponent } from '../Admin/logs-display-modal/logs-display-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../Services/sharedService';
import { log } from 'console';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.css'],
  providers:[DatePipe]
})
export class StackedChartComponent {
  @ViewChild("myChart", { static: true })
  myChart: ElementRef<HTMLCanvasElement>;
  chart: Chart;
  @Input("type") type;
  @Input("chartdata") chartdata;
  @Input("weekArr") weekArr;
  @Input("minValue") minValue;
  @Input("maxValue") maxValue:any = 23.59;
  currentId;
  totalWorkingArr: any;
  inOut: any;
  @Input()
  get totalWorkingArray() {
    return this.totalWorkingArr;
  }
  set totalWorkingArray(data) {
    this.totalWorkingArr = data;
  }

  isdata = true;
  toolTipInterval: any;
  tooltipTitle: any;
  tooltipContent: any;
  tooltipTime;
  index = 0;
  @Input("data") data;
  @Input("sessionData") sessionData = [];
  @Input("labels") labels;
  @Input("id") id;
  @Input("tempDataTooltip") tempDataTooltip;
  @Input("newSessionData") newSessionData;

  barChartTipsHorizontal: any;
  barChartTipsBar: any;
  @ViewChild('horizontalBarChartDiv') horizontalBarChartDiv!: ElementRef;
  @ViewChild('app_usage_tooltip_horizontal1',{static: false}) app_usage_tooltip_horizontal1: ElementRef;
  newData :any;
  isstartPage = true;
  totalAppUsageHours: any
  totalIdleHours: any
  // totalWorkingHours: any;
  clockIn: any
  clockOut: any
  singleDayLog: any
  hoverIndexval;
   currentRoute ;
   userName;
   sortedArr;
   totalProductive = '00hr 00min'
   totalIdle = '00hr 00min'

  constructor(private renderer: Renderer2, private _projectService: ProjectService, private _chartService: ChartToolTipServiceService, public dialog: MatDialog, private _sharedService: SharedService,private _datepipe:DatePipe,private router: Router) {
    this.currentRoute = this.router.url;
    this.currentRoute = this.router.url;
  
    this.currentRoute = this.router.url;  
  
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.sessionData.length){
      this.getMaxWorkingTime();
    }
    if(localStorage.getItem('stackArr')){
      this.sortedArr = JSON.parse(localStorage.getItem('stackArr'));
    }
    this.createBarChart();
  }

  getMaxWorkingTime(){
    let maxWorkingTime = "00:00:00"; // Initialize with a minimum time
    for (const item of this.sessionData) {
      const currentWorkingTime = item.working;
      
      // Compare the current working time with the maximum working time
      if (currentWorkingTime > maxWorkingTime) {
        maxWorkingTime = currentWorkingTime;
      }
    }
    
    const time = new Date(`2000-01-01T${maxWorkingTime}`);

    const inputMinutes = this.getMinutesFromMidnight(maxWorkingTime);
    const targetTimes = ["08:00", "16:00", "23:59"];
    const targetMinutes = targetTimes.map(this.getMinutesFromMidnight);

    // Find the index of the first target time that is greater than or equal to the input time
    const index = targetMinutes.findIndex(target => inputMinutes <= target);
    const newtime = new Date(`2000-01-01T${targetTimes[index]}`);
    const newformattedTime = this._datepipe.transform(newtime, 'HH.mm');
    this.maxValue = newformattedTime;
  }
  removePlaceholderElements() {
    if (this.horizontalBarChartDiv) {
      const placeholders = this.horizontalBarChartDiv.nativeElement.querySelectorAll('canvas');
      for (var i = 0; i < placeholders.length; i++) {
        this.renderer.removeChild(this.horizontalBarChartDiv.nativeElement, placeholders[i]);
      }
    }
  }
 getMinutesFromMidnight(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}
  ngAfterViewInit() {
    this.createBarChart();
    if (this.type == "bar") {

    }
  }

  createBarChart() {
    this.removePlaceholderElements();
    const canvas = this.renderer.createElement("canvas");
    
    if (this.type !== 'horizontal') {
      this.renderer.appendChild(
        document.getElementById("chartContainer2"),
        canvas
      );
      canvas.width = 50;
      canvas.height = 40;
      
    } else {
      this.renderer.appendChild(
        document.getElementById("chartContainer"),
        canvas
      );
    }

    // const backgroundColors = this.generateRandomColors(this.data.length);

    const ctx = canvas.getContext("2d");
    
    if(this.type !== 'horizontal'){
      ctx.scale(50 / 40, 40 / 50);   
    }
    let ticks = {};
    let backgroundColors = [];
  
    ticks = {
      min: this.minValue ? this.minValue : 0.0,
      max: this.maxValue  ?  this.maxValue : 23.59,
      beginAtZero: false,
      callback: function (value, index, values) {
        // You can format the label here, for example, add a dollar sign and round to 2 decimal places
        if (typeof value == 'number') {
          var hours = Math.floor(value);
          var minutes = (value - hours) * 60;
          // Format the hours and minutes as strings with leading zeros if necessary
          var hoursString = hours.toString().padStart(2, '0');
          var minutesString = Math.round(minutes).toString().padStart(2, '0');
          // Create the time string
          var timeString = hoursString + ':' + minutesString + '';
          return timeString
          // perform some operation with number.
        }
        else {
          return value
        }
      }
    };

    backgroundColors = [
      "#ef9a9a",
      "#f48fb1",
      "#ce93d8",
      "#b39ddb",
      "#9fa8da",
      "#90caf9",
      "#81d4fa",
      "#80deea",
      "#80cbc4",
    ];
    this.chart = new Chart(ctx, {
      type: this.type == 'horizontal' ? "horizontalBar" : 'bar',
      
      data: {
        labels: this.labels, // Replace with your labels
        datasets: this.data,
      },
      options: {
        events: this.type !== 'horizontal' ? ["touchstart", "touchmove", "click"] : ["click"],
        responsive: true,
        legend:{
          display:false
         },
        tooltips: {
          enabled: false,
          mode: 'index',
          custom:
            (this.type !== 'horizontal') ?
              ((tooltipModel) => {
                // this tooltip will show for the bar chart
                // Working / Idle / Productive Hours

                const tooltipEl: any = document.getElementById('tooltipBarChart');
                const dataPoints: any = tooltipModel.dataPoints;

                if (dataPoints && dataPoints.length > 0) {
                  const hoveredIndex = dataPoints[0].index;
                   this.hoverIndexval = hoveredIndex;

                  const hours = Math.floor(dataPoints[0].value);
                  const minutes = Math.floor((dataPoints[0].value - hours) * 60);
                  const seconds = Math.floor(((dataPoints[0].value - hours) * 60 - minutes) * 60);

                  const formattedHours = hours.toString().padStart(2, '0');
                  const formattedMinutes = minutes.toString().padStart(2, '0');
                  const formattedSeconds = seconds.toString().padStart(2, '0');

                  let timeFunCalls = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                  this.tooltipTitle = dataPoints[0].xLabel;
                  if (dataPoints[0].datasetIndex == 1) {
                    this.tooltipContent = "Productive Hours";
                    this.tooltipTime = timeFunCalls
                  }

                  else {
                    this.tooltipContent = "Idle Hours";
                    this.tooltipTime = timeFunCalls
                  }
                }

                if (this.toolTipInterval > 0) {
                  clearInterval(this.toolTipInterval);
                }

                if(!dataPoints) {
                  tooltipEl.style.display = 'none';
                } else {
                  tooltipEl.style.display = 'block';
                  this.toolTipInterval = setInterval(() => {
                    tooltipEl.style.display = 'none';
                    clearInterval(this.toolTipInterval);
                  }, 5000000);
                }


                const chartArea = this.chart.chartArea;
                const xPosition = tooltipModel.caretX; // X position of the tooltip
                const yPosition = tooltipModel.caretY; // Y position of the tooltip

                // Adjust the tooltip position as needed
                if ((xPosition + chartArea.left + 200) > window.innerWidth) {
                  tooltipEl.style.left = (xPosition + chartArea.left - 150) + 'px';
                }
                // else {
                //   tooltipEl.style.left = (xPosition + chartArea.left + 200) + 'px';
                // }
                if ((yPosition + chartArea.top + 200) > window.innerHeight) {
                  tooltipEl.style.top = (yPosition + chartArea.top - 150) + 'px';
                } else {
                  tooltipEl.style.top = (yPosition + chartArea.top) + 'px';
                }
                // tooltipEl.style.top = (yPosition + chartArea.top) + 'px';
              }) :
              ((customtooltip) => {
                let tooltipEl: any = document.getElementById('app_usage_tooltip_horizontal');
                const dataPoints: any = customtooltip.dataPoints;

                if (this.toolTipInterval > 0) {
                  clearInterval(this.toolTipInterval);
                }

                if(!dataPoints) {
                  this.renderer.setStyle(this.app_usage_tooltip_horizontal1.nativeElement, 'display', 'none');
                } else {
                  this.renderer.setStyle(this.app_usage_tooltip_horizontal1.nativeElement, 'display', 'block');
                  this.toolTipInterval = setInterval(() => {
                    tooltipEl.style.display = 'none';
                    clearInterval(this.toolTipInterval);
                  }, 5000000);
                }

                const chartArea = this.chart.chartArea;
                const xPosition = customtooltip.caretX; // X position of the tooltip
                const yPosition = customtooltip.caretY; // X position of the tooltip
                if (xPosition + 650 > chartArea.right) {
                  /**
                   * @note host click listener still gives a negative value for original X or Y chart position while still in view.  
                   * @note if still breaks in some condition then change to original popup width value of 650
                   */
                  const leftVal = chartArea.right - 325; 
                  tooltipEl.style.left = leftVal < 0 ? 0 : leftVal + 'px';
                } else {
                  tooltipEl.style.left = xPosition < 0 ? 0 : xPosition + 'px';
                }
                if (yPosition + 380 > chartArea.bottom) {
                  /**
                   * @note host click listener still gives a negative value for original X or Y chart position while still in view.  
                   * @note if still breaks in some condition then change to original popup width value of 380
                   */
                  const topVal = chartArea.bottom - 190;
                  tooltipEl.style.top = topVal < 0 ? 0 : topVal + 'px';
                } else {
                  tooltipEl.style.top = yPosition < 0 ? 0 : yPosition + 'px';
                }
              })
        },
        onHover: (async (evt, item) => {
          if (this.type == 'horizontal') {
            const activePoint = this.chart.getElementAtEvent(evt)[0];
           if (activePoint) {
              let index = activePoint['_index'];
              // console.log("DATASET INDEX", datasetIndex, this.tempDataTooltip[index])
            
              this.logCount(this.newSessionData[index])
              if(this.id){
                this.currentId = this.id[index];
              }
              
              // const logsData: any = await this.calculateHours(this.newSessionData[index]);
              const logsData: any = await this.groupJsonByAppName(this.newSessionData[index]);


              if (logsData) {
                logsData.appUsageList.sort((a, b) => (a.totalUsage < b.totalUsage) ? 1 : -1);
                this.inOut = logsData.appUsageList;
                this.totalAppUsageHours = logsData.totalUsageHours;
                this.totalIdleHours = logsData.totalIdleHours;
                // this.totalWorkingHours = logsData.totalWorkingHours;
                this.userName = activePoint['_model'].label;
              }
            }
          } else {
            const activePoint = this.chart.getElementAtEvent(evt)[0];

            if(activePoint) {
              let index = activePoint['_index']
              const logsData = this.calculateEmployeeHours(this.totalWorkingArray)
              let singleUserLogs = logsData[index]
              this.index = index;

              if (singleUserLogs) {
                this.singleDayLog = null;
                for (const key in singleUserLogs) {
                  this.singleDayLog = singleUserLogs[key]
                  const workingTime = this.singleDayLog.working.split(':')
                  const idleTime = this.singleDayLog.idle.split(':')
                  const productiveTime = this.singleDayLog.productive.split(':');
                  this.singleDayLog['displayProductive'] = productiveTime[0] + " hr " + productiveTime[1] + " min"
                  this.singleDayLog['displayWorking'] = workingTime[0] + " hr " + workingTime[1] + " min"
                  this.singleDayLog['displayIdle'] = idleTime[0] + " hr " + idleTime[1] + " min"
                }
  
              } else {
                this.singleDayLog = null
              }
            }
          }
        }),
        scales: {
          xAxes: [
            {
              ticks: ticks,
              gridLines: {
                display: this.type !== 'horizontal' ? false : true,
              },
              stacked: this.type !== 'horizontal' ? true : false,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: ticks,
              gridLines: {
                display: this.type !== 'horizontal' ? true : false,
              },
              stacked: true,
              scaleLabel: {
                display: true,
              }
            },
          ],
        },
      
        // onClick: function(event, elements) {
        //   const yLabel = event.target as HTMLElement;
        //   console.log("labels----",event.offsetY,elements)
        //    if (yLabel.classList.contains('y-axis-label')) {
        //   }
        // }
        
      }
    });
    // document.getElementById('chartContainer').addEventListener('click', function(event) {
    //   const target = event.target as HTMLElement;
    //   console.log('labels----', target);
    //   if (target.classList.contains('y-axis-label')) {
    //     const dataIndex = target.getAttribute('data-index');
    //     console.log('labels----', dataIndex);
    //     // Implement your navigation logic here
    //   }
    // })
  }

  calculateEmployeeHours(data) {
    let arr = [];
    for (let key in data) {
      let obj = {};
      obj[key] = data[key];
      arr.push(obj);
    }
    return arr
  }
 logCount(logs){
  let totalUsageSeconds = 0;
  let totalIdleSeconds = 0;

  if (logs && logs.length) {
    for (let i = 0; i < logs.length; i++) {
      if (i === 0) this.clockIn = logs[0].in
      // let subArray = logs[i]
      // let subArrayLength = subArray.length
      if (i === (logs.length - 1)) {
        this.clockOut = logs[logs.length - 1].out
      }
      const usageTimeParts = logs[i].usage.split(':');
      const idleTimeParts = logs[i].idle.split(':');

      // Convert hours, minutes, and seconds into seconds
      const usageSeconds = parseInt(usageTimeParts[0]) * 3600 + parseInt(usageTimeParts[1]) * 60 + parseInt(usageTimeParts[2]);
      const idleSeconds = parseInt(idleTimeParts[0]) * 3600 + parseInt(idleTimeParts[1]) * 60 + parseInt(idleTimeParts[2]);

      // Accumulate the seconds
      if(logs[i].appName != ''){
        totalUsageSeconds += usageSeconds;
        totalIdleSeconds += idleSeconds;

      }
    }
  


  // Convert total seconds back to hours, minutes, and seconds
  let totalUsageHours = Math.floor(totalUsageSeconds / 3600);
  const totalUsageMinutes = Math.floor((totalUsageSeconds % 3600) / 60);
  const totalUsageSecondsRemainder = totalUsageSeconds % 60;

  const totalIdleHours = Math.floor(totalIdleSeconds / 3600);
  const totalIdleMinutes = Math.floor((totalIdleSeconds % 3600) / 60);

  // Convert the appNameUsageCounts object to an array of objects
  this.totalProductive = `${totalUsageHours < 10 ? '0' + totalUsageHours : totalUsageHours} ${totalUsageHours === 1 ? 'hr' : 'hrs'} ${totalUsageMinutes < 10 ? '0' + totalUsageMinutes : totalUsageMinutes} ${totalUsageMinutes === 1 ? 'min' : 'mins'}`;
  this.totalIdle = `${totalIdleHours < 10 ? '0' + totalIdleHours : totalIdleHours} ${totalIdleHours === 1 ? 'hr' : 'hrs'} ${totalIdleMinutes < 10 ? '0' + totalIdleMinutes : totalIdleMinutes} ${totalIdleMinutes === 1 ? 'min' : 'mins'}`;

    
  }else{
    this.totalProductive = '00hr 00min';
  }

 }
  async calculateHours(logs) {
    let totalUsageSeconds = 0;
    let totalIdleSeconds = 0;
    let finalObj = {}
    if (logs && logs.length) {
      const logsLength = logs.length
      // Create an object to store appName usage counts
      const appNameUsageCounts = {};
      for (let i = 0; i <= logsLength-1; i++) {
        if (i === 0) this.clockIn = logs[0].in
        // let subArray = logs[i]
        // let subArrayLength = subArray.length
        if (i === (logsLength - 1)) {
          this.clockOut = logs[logs.length - 1].out
        }
        // for (let j = 0; j < subArrayLength; j++) {
          let obj = logs[i]
          const appName = obj.appName;
          const usage = obj.usage;

          const usageTimeParts = obj.usage.split(':');
          const idleTimeParts = obj.idle.split(':');

          // Convert hours, minutes, and seconds into seconds
          const usageSeconds = parseInt(usageTimeParts[0]) * 3600 + parseInt(usageTimeParts[1]) * 60 + parseInt(usageTimeParts[2]);
          const idleSeconds = parseInt(idleTimeParts[0]) * 3600 + parseInt(idleTimeParts[1]) * 60 + parseInt(idleTimeParts[2]);

          // Accumulate the seconds
          if(appName != ''){
            totalUsageSeconds += usageSeconds;
            totalIdleSeconds += idleSeconds;
          }

          if (appName in appNameUsageCounts) {
            appNameUsageCounts[appName] += await this.timeToMilliseconds(usage);
          } else {
            appNameUsageCounts[appName] = await this.timeToMilliseconds(usage);
          }
       // }
      }


      // Convert total seconds back to hours, minutes, and seconds
      let totalUsageHours = Math.floor(totalUsageSeconds / 3600);
      const totalUsageMinutes = Math.floor((totalUsageSeconds % 3600) / 60);
      const totalUsageSecondsRemainder = totalUsageSeconds % 60;

      const totalIdleHours = Math.floor(totalIdleSeconds / 3600);
      const totalIdleMinutes = Math.floor((totalIdleSeconds % 3600) / 60);
      const totalIdleSecondsRemainder = totalIdleSeconds % 60;

      // Convert the appNameUsageCounts object to an array of objects
      const resultArray = Object.keys(appNameUsageCounts).map(appName => ({
        appName: appName,
        totalUsage: this.millisecondsToHours(appNameUsageCounts[appName])
      }));

      // Log the result
      finalObj['appUsageList'] = resultArray
      // finalObj['totalUsageHours'] = totalUsageHours + ' hr ' + totalUsageMinutes + ' min';
      finalObj['totalUsageHours'] = `${totalUsageHours} ${totalUsageHours <= 1 ? 'hr' : 'hrs'} ${totalUsageMinutes} ${totalUsageMinutes <= 1 ? 'min': 'mins'}`;
      // finalObj['totalIdleHours'] = totalIdleHours + ' hr ' + totalIdleMinutes + ' min';
      finalObj['totalIdleHours'] = `${totalIdleHours} ${totalIdleHours <= 1 ? 'hr' : 'hrs'} ${totalIdleMinutes} ${totalIdleMinutes <= 1 ? 'min': 'mins'}`;;
      // const totalWorkingHours = totalUsageHours + totalIdleHours;
      // const totalWorkingMinutes = totalUsageMinutes + totalIdleMinutes;
      // finalObj['totalWorkingHours'] = `${totalWorkingHours} ${totalWorkingHours <= 1 ? 'hr' : 'hrs'} ${totalWorkingMinutes} ${totalWorkingMinutes <= 1 ? 'min': 'mins'}`;;
      return finalObj
    }
    return finalObj;


  }


  // Function to convert "hh:mm:ss" to milliseconds
  timeToMilliseconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = hours * 3600000 + minutes * 60000 + seconds * 1000;
    return totalMilliseconds;
  }


  // Function to convert milliseconds to hours as a string in "hh:mm:ss" format
  millisecondsToHours(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  timeSlotEdit(event, array) {
    let activeElement = this.chart.getElementAtEvent(event);
    var activePoint: any = this.chart.getElementAtEvent(event)[0];
    var data = activePoint._chart.data;

    var datasetIndex = activePoint._datasetIndex;
    var label = data.datasets[datasetIndex].label;
    var value = data.datasets[datasetIndex].data[activePoint._index];
  }

  updateChartMustErrors(error) {
    const sessionData = [
      {
        label: `${error.error.message}`,
        data: [], // Replaced
        backgroundColor: '',
        borderColor: '',
        borderWidth: 0,
        barThickness: 40
      },
      {
        label: `${error.error.message}`,
        data: [], // Replaced
        backgroundColor: '',
        borderColor: '',
        borderWidth: 0,
        barThickness: 40,
      },
    ];
    this.chart.data.labels = [];
    this.chart.data.datasets = sessionData;
    this.chart.update();
  }

  openModalHoursLogs(date: any) {
    if (this.totalWorkingArr) {
      let correctSessions = this._sharedService.gettingTheCorrectSession(this.totalWorkingArr[date].sessions);
      let type = "timeLog";
      let data: any = {};
      data.date = date;
      data.working_hours = this.totalWorkingArr[date].working;
      data.timeLog = correctSessions;
      data.type = type;
      this.dialog.open(LogsDisplayModalComponent, {
        width: type !== "appInfo" ? "1000px" : "750px",
        maxHeight: "calc(100vh - 90px)",
        height: "auto",
        data: {
          date: date,
          working_hours: this.totalWorkingArr[date].working,
          timeLog: correctSessions,
          type: type,
        },
      });
    }
  }

  makeDataForChartTicks(data: any, type: any) {
    if (type == "horizontal") {
      let arr = [];
      data.forEach((element) => {
        let minVal: any = Math.min(...element.data);
        arr.push(minVal);
      });
      return Math.min(...arr);
    }
    else {
      let arr = [];
      data.forEach((element) => {
        let minVal: any = Math.min(...element.data);
        arr.push(minVal);
      });
      return Math.floor(Math.min(...arr));
    }
  }

  timeToNumber(timeString) {
    // Your time value
    // var timeString = "08:30:00";

    // Split the time string into hours, minutes, and seconds
    var timeParts = timeString.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Calculate the decimal representation
    var decimalValue = hours + minutes / 60;

    // Round the decimal value to two decimal places
    decimalValue = Math.round(decimalValue * 100) / 100;
    return decimalValue;
  }

  showCustomTooltip(event: MouseEvent, content: string) {
    const tooltip = document.getElementById('app_usage_tooltip_horizontal');
    tooltip.style.display = "block";
    tooltip.style.opacity = '1';
    tooltip.style.left = event.pageX + 'px';
    tooltip.style.top = "10px";
    tooltip.innerHTML = content;
  }


  handleButtonClick(inOut) {
    // Handle button click here

    // Open Application Analysis modal from here
    let type = "timeline";

    this.dialog.open(LogsDisplayModalComponent, {
      width: type !== "timeline" ? "1000px" : "calc(100vw - 20vw)",
      maxHeight: "calc(100vh - 90px)",
      height: "auto",
      data: {
        type: type,
        appInfo: inOut.details,
        in: inOut.in,
        out: inOut.out
      },
    });
  }

  closeTooltip(){
    this.renderer.setStyle(this.app_usage_tooltip_horizontal1.nativeElement, 'display', 'none');
  }

  closeBarChartTooltip(){
    const tooltipEl: any = document.getElementById('tooltipBarChart');
    tooltipEl.style.display = 'none';
  }

  groupJsonByAppName(data) {
    let groupedData = {};
    // Iterate over each entry in the JSON data
    data.forEach(entry => {
        let { appName, usage, idle } = entry;

        // If the app name doesn't exist in the groupedData object, create it
        if (!groupedData[appName]) {
            groupedData[appName] = {
                totalUsage: '00:00:00',
                totalIdle: '00:00:00'
            };
        }

        // Add the usage and idle time of the current entry to the corresponding app
        groupedData[appName].totalUsage = this.addTimes(groupedData[appName].totalUsage, usage);
        groupedData[appName].totalIdle = this.addTimes(groupedData[appName].totalIdle, idle);
    });

    // Convert groupedData to the desired output format
    let appUsageList = [];
    let totalUsage = '00:00:00';
    let totalIdle = '00:00:00';

    for (let appName in groupedData) {
        let { totalUsage: appTotalUsage, totalIdle: appTotalIdle } = groupedData[appName];
        appUsageList.push({ appName, totalUsage: appTotalUsage });

        totalUsage = this.addTimes(totalUsage, appTotalUsage);
        totalIdle = this.addTimes(totalIdle, appTotalIdle);
    }

    // Calculate total usage and idle hours
    let totalUsageHours = this.calculateHoursCount(totalUsage);
    let totalIdleHours = this.calculateHoursCount(totalIdle);

    return { appUsageList, totalUsageHours, totalIdleHours };
}
calculateHoursCount(time) {
  let [hours, minutes] = time.split(':').map(Number);
  return `${hours} hr ${minutes} mins`;
}
// Function to add two time durations in HH:mm:ss format
 addTimes(time1, time2) {
    let [h1, m1, s1] = time1.split(':').map(Number);
    let [h2, m2, s2] = time2.split(':').map(Number);

    let totalSeconds = s1 + s2;
    let carryMinutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    let totalMinutes = m1 + m2 + carryMinutes;
    let carryHours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;

    let hours = h1 + h2 + carryHours;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
}

// Function to pad single-digit numbers with a leading zero
 pad(num) {
    return num.toString().padStart(2, '0');
}
}