import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ProjectService } from '../Services/project.service';
// import * as Chart from 'chart.js';
import * as Chart from 'chart.js';
import { ChartToolTipServiceService } from '../Services/chart-tool-tip-service.service';
import { JsonPipe } from '@angular/common';

// import 'chartjs-plugin-zoom'; // Import the zoom plugin

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent {
  @ViewChild("myChart", { static: true })
  myChart: ElementRef<HTMLCanvasElement>;
  chart: Chart;
  @Input("data") data;
  @Input("labels") labels;
  @Input("chartdata") chartdata:any = [];
  _chart: Chart;
  isdata = true;
  tooltipData;
  isData = true;
  constructor(private renderer: Renderer2, private _projectService: ProjectService, private _chartService: ChartToolTipServiceService) {
    this._projectService.donutChart.subscribe((value) => {
      this.chartdata = value.data;
      this.tooltipData = value.data;
      this.updateChart();
    });
    this._projectService.chardData.subscribe((value) => {
      this.data = JSON.parse(value.data);
      if (this.data.length == 0) {
        this.isData = false;
      } else {
        this.isData = true;
      }
    });
    this._projectService.ChartLabel.subscribe((value) => {
      this.labels = JSON.parse(value.data);
      this.updateChart();
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.createPieChart();
  }

  createPieChart() {
    const canvas = this.renderer.createElement("canvas");
    this.renderer.appendChild(
      document.getElementById("donutChartContainer"),
      canvas
    );
    const ctx = canvas.getContext("2d");
    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Idle Working", "Productive", "Total Working"],
        datasets: [
          {
            data: [20, 80],
            backgroundColor: [
              "rgba(117, 117, 117, 0.6)",
              "rgba(126, 87, 194, 0.6)",
              "rgb(0, 151, 167, 0.6)",
            ],
            borderWidth: 1,
          },
          {
            data: [0, 0, 100],
            backgroundColor: [
              "rgba(117, 117, 117, 0.6)",
              "rgba(126, 87, 194, 0.6)",
              "rgb(0, 151, 167, 0.6)",
            ],
            borderWidth: 0,
          },
        ],
      },

      options: {
        cutoutPercentage: 85,
        responsive: true,
        tooltips: {
          enabled: false,
          custom: this.customChartToolTip = (e: any) => {
            // Tooltip Element Refrence
            const tooltipModel = e;
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Tooltip table style
            let tableStyle = `
      background-color: rgba(33, 33, 33, 0.8);
      color: #ffffff; 
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 14px;
    `;

            // Tooltip table content style
            let contentStyle = `
      font-size: 14px !important;
      margin: 3px;
    `;

            // First Time the element is not available it will create it.
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = `<table style="${tableStyle}"></table>`;
              document.body.appendChild(tooltipEl);
            }

            // when mouse over the tooltip are disable to shown
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set content 
            if (tooltipModel.body) {

              // if title are availabel that are set the table 
              var titleLines = tooltipModel.title || [];

              // get the body data :

              var bodyLines = tooltipModel.body.map((bodyItem) => {
                return bodyItem.lines;
              });

              var innerHtml = '<thead><tr>';
              let dataindex;
              if (tooltipModel.dataPoints) {
                dataindex = tooltipModel.dataPoints[0].index;
              }
              let displayData;
              // let tool = JSON.parse(localStorage.getItem("donutChartData"));
              let tool = this.chartdata;

              if (tool) {
                if (dataindex == 0) {
                  displayData = tool.idle_time;
                } else if (dataindex == 1) {
                  displayData = tool.productive_time;
                } else {
                  displayData = tool.usage_time;
                }
              }

              titleLines.forEach(function (title: any, i) {
                if (title.includes(",")) {

                  let splitedTitle = title.split(",");
                  innerHtml += `
            <th>
              <span style="${contentStyle}">${splitedTitle[0]}</span>
            </th>
            <th>
              <span style="${contentStyle}">${displayData}</span>
            </th>`;
                }
                else {
                  innerHtml += `
            <th>
              <span style="${contentStyle}">${title}</span>
            </th>`;
                }
              });

              innerHtml += '</tr></thead><tbody>';

              bodyLines.forEach(function (body, i) {
                let splitedData = body[0].split(":");
                if(splitedData[0] != "No used application" && splitedData[0] != "undefined"){
                  innerHtml += `
                  <tr style="margin: 0px 10px !important;">
                    <td><span style="${contentStyle}">${splitedData[0]}</span></td>
                    <td><span style="${contentStyle}">${displayData.split(":")[0] != 0 ? displayData.split(":")[0] + "h" : ""} ${displayData.split(":")[1]  != 0 ? displayData.split(":")[1] + "m" : ""}</span></td>
                  </tr>`;
                }else{
                  innerHtml += `
                <tr style="margin: 0px 10px !important;">
                <td><span style="${contentStyle}">No used application</span></td>
               </tr>`;
                }
                
              });

              innerHtml += '</tbody>';

              var tableRoot = tooltipEl.querySelector('table');

              tableRoot.innerHTML = innerHtml;
            }

            // set the position on mouse Hover
            var position = this.chart.canvas.getBoundingClientRect();
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },
        },
        // legend: {
        //   display: false
        // },
        // tooltips: {
        //   enabled: false,
        //   custom: this._chartTootip.customChartToolTip,
        // },
        plugins: {
          afterDraw: function (chart) {

            var width = chart.chart.width;
            var height = chart.chart.height;
            var ctx = chart.chart.ctx;

            ctx.restore();
            var fontSize = (height / 150).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#000000";

            var text = "Avg: " + 7.5;
            var textX = Math.round((width - ctx.measureText(text).width) / 2);
            var textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
          }
        }
        // title: {
        //   display: true,
        //   text: "Current Employee Status",
        //   position: "top",
        //   fontSize: 18
        // },
      },
    });
  }

  dispalyBackground() {
    if (this.isData) {
      return [
        "#ef9a9a",
        "#f48fb1",
        "#ce93d8",
        "#b39ddb",
        "#9fa8da",
        "#90caf9",
        "#81d4fa",
        "#80deea",
        "#80cbc4",
      ]
    } else {
      return ["#cfd8dc"];
    }
  }

  updateChart() {
    if (this.chartdata) {
      this.isdata = true;
      this.chart.data.labels = ["Idle Working", "Productive", "Total Working"];
      this.chart.data.datasets[0].data = [this.convertToMilliseconds(this.chartdata.idle_time), this.convertToMilliseconds(this.chartdata.productive_time), 0];
      this.chart.data.datasets[1].data = [0, 0, this.convertToMilliseconds(this.chartdata.usage_time)]
    } else {
      this.isdata = false;
      this.chart.data.labels = ["No used application"];
      this.chart.data.datasets[0].data = [100];
    }
    this.chart.update();
  }

  convertToMilliseconds(timeString) {
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);

    // Calculate milliseconds
    return (hours * 3600 + minutes * 60 + seconds) * 1000;
  }

  customChartToolTip(tooltipModel: any) {
  }

  convertMillisecondsToTime(ms: number): string {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
}
