import { Component, Input } from '@angular/core';
import { ProjectService } from 'src/app/Services/project.service';

@Component({
  selector: 'progressbar',
  templateUrl: './application-usage-progressbar.component.html',
  styleUrls: ['./application-usage-progressbar.component.css']
})
export class ApplicationUSageProgressbarComponent {
  @Input("data") data: any;
  @Input("isDashboard") isDashboard: any;
  @Input("displayForWebsiteUsageChart") displayForWebsiteUsageChart: any = [];
  @Input("display") display: any = [];
  @Input("ArrFirstLabelsWebsiteUsageChart") ArrFirstLabelsWebsiteUsageChart: any = [];
  @Input("labels") ArrFirstlabels: any = [];
  @Input("ArrSecondLabelsWebsiteUsageChart") ArrSecondLabelsWebsiteUsageChart: any = [];
  @Input("labels") ArrSecondlabels: any = [];
  @Input("isDataWebsiteUsage") isDataWebsiteUsage: Boolean;
  @Input("chartdata") chartdata: any = [];
  @Input("chartDataForWebsiteUsage") chartDataForWebsiteUsage: any;
  @Input("starDate") starDate: any;
  @Input("endDate") endDate: any;
  @Input("starDateWebsiteUsage") starDateWebsiteUsage: any;
  @Input("endDateWebsiteUsage") endDateWebsiteUsage: any;
  @Input("gridTemplate") gridTemplate: Boolean;
  @Input("grid") grid: Boolean;
  // time = ['20%','30%','40%','20%','30%','40%','20%','30%','50%','40%']
  maxTime;
  maxTimeWebsiteUsage: any;
  BarColor = ['#2143BC', '#2172BC', '#BC6B21', '#F9C80E', '#A621BC', '#21BC68', '#BC214F', '#6521BC', '#000000', '#E84646']
  BarColor1 = ['#2143BC', '#2172BC', '#BC6B21', '#F9C80E', '#A621BC'];
  BarColor2 = ['#21BC68', '#BC214F', '#6521BC', '#000000', '#E84646'];
  applicationData: any = {
    appName: "Google Chrome",
    usersApplication: [
      {
        user: "Dhrumil Gandhi",
        applicationUsage: "10:00:00"
      },
      {
        user: "Keval Vala",
        applicationUsage: "09:00:00"
      },
      {
        user: "Ashish Paramar",
        applicationUsage: "06:00:00"
      },
      {
        user: "Rinku Mam",
        applicationUsage: "02:00:00"
      },
    ]
  }
  constructor(private _projectService: ProjectService) {
    this._projectService.ApplicationUSage.subscribe((value: any) => {
      this.chartdata = value.data;
      this.starDate = value.start_date;
      this.endDate = value.end_date;
      if (value.display) {
        this.display = value.display;
        this.maxTime = this.getTotalTime(value.data)
        if (value.display.length >= 4) {
          this.ArrFirstlabels = value.display.slice(0, 5);
          this.ArrSecondlabels = value.display.slice(5, 10);
        } else {
          this.ArrFirstlabels = value.display;
          this.ArrSecondlabels = [];
        }
      } else {
        this.display = [];
        this.ArrSecondlabels = [];
        this.ArrFirstlabels = [];
      }
    });

    this._projectService.webSiteUsage.subscribe((value: any) => {
      this.chartDataForWebsiteUsage = value.data;
      this.starDateWebsiteUsage = value.start_date;
      this.endDateWebsiteUsage = value.end_date;
      if (value.display) {
        this.displayForWebsiteUsageChart = value.display;
        this.maxTimeWebsiteUsage = this.getTotalTime(value.data)
        if (value.display.length >= 4) {
          this.ArrFirstLabelsWebsiteUsageChart = value.display.slice(0, 5);
          this.ArrSecondLabelsWebsiteUsageChart = value.display.slice(5, 10);
        } else {
          this.ArrFirstLabelsWebsiteUsageChart = value.display;
          this.ArrSecondLabelsWebsiteUsageChart = [];
        }
      } else {
        this.displayForWebsiteUsageChart = [];
        this.ArrFirstLabelsWebsiteUsageChart = [];
        this.ArrSecondLabelsWebsiteUsageChart = [];
      }
    });
  }

  timeToMilliseconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  }

  timeToDecimalHours(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return (hours + minutes / 60 + seconds / 3600).toFixed(2);
  }

  timeToHours(timestr) {
    const [hours, minutes, seconds] = timestr.split(":").map(Number);
    return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}` + ' h';
  }

  timeToPercentage(timeString, maxTime) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const maxTotalSeconds = maxTime.split(":").reduce((acc, val, i) => acc + val * (i === 0 ? 3600 : i === 1 ? 60 : 1), 0);
    if (maxTotalSeconds === 0) {
      return 0;
    }
    const percentage = (totalSeconds / maxTotalSeconds) * 100;
    return percentage.toFixed(2) + "%";
  }

  getTotalTime(timeArray) {
    const totalSeconds = timeArray.reduce((total, time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return total + hours * 3600 + minutes * 60 + seconds;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}:${this.padZeroes(remainingSeconds)}`;
  }

  padZeroes(value: number) {
    return value.toString().padStart(2, '0');
  }

  findMaxTime(timeArray) {
    if (timeArray.length === 0) {
      return "00:00:00";
    }
    let maxTime = "00:00:00";
    for (const timeString of timeArray) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      const [maxHours, maxMinutes, maxSeconds] = maxTime.split(":").map(Number);
      const maxTotalSeconds = maxHours * 3600 + maxMinutes * 60 + maxSeconds;
      if (totalSeconds > maxTotalSeconds) {
        maxTime = timeString;
      }
    }
    return maxTime;
  }
}
