import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/Services/login.service';
import { ProjectService } from 'src/app/Services/project.service';
import { Peer, DataConnection, MediaConnection } from "peerjs";
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-screencast-action-model',
  templateUrl: './screencast-action-model.component.html',
  styleUrls: ['./screencast-action-model.component.css']
})
export class ScreencastActionModelComponent {
  @ViewChild('remoteStream', { static: false }) videoElement: ElementRef;
  userDetails: any;
  userId: any;
  private peer: Peer;
  myStream: MediaStream;
  currentCall: MediaConnection;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public _projectService: ProjectService, @Inject(MAT_DIALOG_DATA) public data: any, private _loginService: LoginService, private cdr: ChangeDetectorRef) {
    // this.screenCastImage = this.data.screenCastImage;
    this.userDetails = this.data.userDetails;
    this.userId = this.data.userId;
    if (sessionStorage.getItem('peerId')) {
      if ((sessionStorage.getItem('peerExpire') && moment().isAfter(moment(sessionStorage.getItem('peerExpire')))) || !sessionStorage.getItem('peerExpire')) {
        sessionStorage.setItem('peerId', `${environment.ADMIN_PEER_ID}-${this.generateUUID()}`);
        this.peer = new Peer(sessionStorage.getItem('peerId')); //SuperSee-58f702c257128d4771f43119b1eddac9
        sessionStorage.setItem('peerExpire', moment().add(1, 'hours').format());
      }
      else {
        this.peer = new Peer(sessionStorage.getItem('peerId'));
      }
    }
    else {
      sessionStorage.setItem('peerId', `${environment.ADMIN_PEER_ID}-${this.generateUUID()}`);
      this.peer = new Peer(sessionStorage.getItem('peerId'));
      sessionStorage.setItem('peerExpire', moment().add(1, 'hours').format()); this.peer = new Peer(sessionStorage.getItem('peerId'));
    }
    this.requestStreamFromPeer(this.userDetails.meta.callingid);
  }

  ngAfterViewInit() {
    // this.requestStreamFromPeer(this.userDetails.meta.callingid);
  }


  generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = ((d + Math.random() * 16) % 16) | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }
  ngOnInit() {


    if (!this.peer || this.peer.destroyed) {
      if (sessionStorage.getItem('peerId')) {
        if ((sessionStorage.getItem('peerExpire') && moment().isAfter(moment(sessionStorage.getItem('peerExpire')))) || !sessionStorage.getItem('peerExpire')) {
          sessionStorage.setItem('peerId', `${environment.ADMIN_PEER_ID}-${this.generateUUID()}`);
          this.peer = new Peer(sessionStorage.getItem('peerId'));
          this.requestStreamFromPeer(this.userId);
          sessionStorage.setItem('peerExpire', moment().add(1, 'hours').format());
        }
        else {
          this.peer = new Peer(sessionStorage.getItem('peerId'));
          this.requestStreamFromPeer(this.userId);
        }
      }
      else {
        sessionStorage.setItem('peerId', `${environment.ADMIN_PEER_ID}-${this.generateUUID()}`);
        this.peer = new Peer(sessionStorage.getItem('peerId'));

        sessionStorage.setItem('peerExpire', moment().add(1, 'hours').format());
        this.requestStreamFromPeer(this.userId);
      }
    }
  }

  ngOnDestroy() {
    if (this.currentCall) {
      this.currentCall.close();
    }
    if (this.peer) {
      this.peer.disconnect();
    }
  }

  async requestStreamFromPeer(peerId: string) {
    console.log("requestStreamFromPeer", peerId)
    this.checkPeerAndCreate();

    this.peer.on('open', (id) => {
      console.log('peer open', id);

      let IncorrectAlert = false;
      // Initiate a call to the remote peer
      //dummys stream

      const canvas = document.createElement('canvas');
      canvas.width = 640;
      canvas.height = 480;
      const videoStream = canvas.captureStream(30);
      // Create a dummy audio track with silence
      const audioContext = new AudioContext();
      const audioStream = audioContext.createMediaStreamDestination().stream;

      // Combine the video and audio tracks into a single media stream
      try {
        const stream = new MediaStream([videoStream.getVideoTracks()[0], audioStream.getAudioTracks()[0]]);
        console.log('stream', stream);
        // this.myStream = await navigator.mediaDevices.getUserMedia({
        //   video: true,
        //   audio: true,
        // });
        if (stream) {
          this.currentCall = this.peer.call(peerId.toString(), stream);

          this.currentCall.on('stream', (stream) => {
            this.displayStream(stream);
          });

          this.currentCall.on('error', (error) => {
            console.error('peer error', error.message);
            console.error('peer error', JSON.stringify(error));
            if (!IncorrectAlert) {
              alert('connection is not active');
              IncorrectAlert = true;
            }
          });
        }
        if (!stream) {
        }
      } catch (error) {
        console.error('call failed', error, error.message);
      }
    });
  }
  displayStream(stream: MediaStream) {
    this.videoElement.nativeElement.srcObject = stream;
  }
  stopScreenCast() {
    if (this.currentCall) {
      this.currentCall.close();
    }
    if (this.peer) {
      this.peer.on('close', () => {
      })
      this.currentCall.close();
      this.peer.disconnect();
    }
    if (this.myStream) {
      this.myStream.getTracks().forEach((track) => track.stop());
    }
    this.dialogRef.close()
  }

  continueScreenCast() {
    console.log("continueScreenCast")
    this.checkPeerAndCreate();
    this.requestStreamFromPeer(this.userId);
  }

  closeModal() {
    this.dialogRef.close();
  }

  checkPeerAndCreate() {
    // Return early if a peer exists and is neither destroyed nor disconnected
    if (this.peer && !this.peer.destroyed && !this.peer.disconnected) {
      console.log("Peer is already active:", this.peer);
      return;
    }

    // Create a new peer if none exists or if it has been destroyed
    if (!this.peer || this.peer.destroyed) {
      this.createPeerWithFallback();
    }

    // Reconnect if peer is disconnected
    if (this.peer && this.peer.disconnected) {
      this.reconnectPeer();
    }
  }

  // Helper function to create a peer with a fallback to custom STUN/TURN config
  createPeerWithFallback() {
    const peerId = `${environment.ADMIN_PEER_ID}-${this.generateUUID()}`;

    try {
      // First attempt: use Google STUN server (default)
      this.peer = new Peer(peerId);
      console.log("Created a peer with default STUN:", this.peer);

      // Attach event listener for connection failure
      this.peer.on('error', (err) => {
        if (err.type === 'server-error' || err.type === 'network') {
          console.error("Default STUN failed, switching to custom STUN/TURN:", err);
          // Switch to custom STUN/TURN if default fails
          this.createPeerWithCustomConfig(peerId);
        }
      });
    } catch (error) {
      console.error("Failed to create peer with default STUN, using custom config:", error);
      this.createPeerWithCustomConfig(peerId);
    }
  }

  // Helper function to create a peer with custom STUN/TURN servers
  createPeerWithCustomConfig(peerId: string) {
    this.peer = new Peer(peerId, {
      config: {
        'iceServers': [
          { url: 'stun:stun.raoinfo.tech:3478' },
          {
            url: 'turn:stun.raoinfo.tech:3478',
            username: 'raoinfotech',
            credential: 'Raoinfotech123'
          }
        ]
      }
    });
    console.log("Created a peer with custom STUN/TURN:", this.peer);
  }

  // Helper function to reconnect a peer
  reconnectPeer() {
    console.log("Reconnecting peer:", this.peer);
    this.peer.reconnect();
  }

}
