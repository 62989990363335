import { ChangeDetectorRef, Component, ElementRef, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { GroupService } from "../../Services/group.service";
import { SharedService } from "../../Services/sharedService";
import { promise } from "protractor";
import { ProjectService } from "src/app/Services/project.service";
import * as M from "materialize-css/dist/js/materialize";
import { TourConfigService } from "src/app/Services/tour-config.service";


declare var $;
@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
})
export class ProjectsComponent implements OnInit {
  isAdmin = false;
  groupList: any;
  loading = false;
  currentUser = JSON.parse(localStorage.getItem("logedInUser"));
  teamData;
  selectedTeam:any;
  searchArr: any = [];
  isdata = false;
  selectData:any;
  isTeamModal = false;
  constructor(
    private _groupService: GroupService,
    public _projectService: ProjectService,
    private _router: Router,
    private _sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private _tourService:TourConfigService
  ) {
    this.isTeamModal = false;
   this.getUSer();
    this.getTeam();
    _projectService.dashboardTourActive.subscribe((value:any)=>{
       this.getTeam();
    })
    this._projectService.EmployeeList.subscribe((value) => {
      this.getTeam();
  });
  this._projectService.deleteTeamList.subscribe((data)=>{
    this.getTeam();
  })
    $(document).ready(function () {
      $(".fixed-action-btn").floatingActionButton();
      $(".tooltipped").tooltip();
      let promise = Notification.requestPermission()
        .then((res) => {
          // this.loading=false;
        })
        .catch((err) => { });
      // wait for permission
    });
    this._projectService.selsectedTeam.subscribe((res)=>{
        this.teamData = res.data;
        this.selectData = res.data;
        this.getTeam();
      // this.cdr.detectChanges();
    })
  }
  getUSer(){
    let roleArr = [];
    this._projectService.getUser().subscribe((data:any)=>{
      localStorage.setItem('user',JSON.stringify(data))
      let isExpired = this._sharedService.checkPlanExpired(data)
      if (isExpired) {
         this._router.navigateByUrl('/plan-expired'); 
      }
      roleArr =  data.role;
      
     let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if(adminRole.length == 0){
        this.isAdmin = false;
      }else{
        this.isAdmin = true;
      }
    })
  }
  ngOnInit() {    
  }
  ngAfterViewInit(){
  }
  ngAfterContentInit(): void {
    M.AutoInit();
  }

  async getTeam() {
    this.loading = true;
    if(this.isTourActive()){
    this._projectService.getTeamList().subscribe(
      (team: any) => {
        this.loading = false;
        if(team.length == 0){
          this.teamData = [];
        }
        this.groupList = team;
        this.searchArr = team;
      },
      (err) => {
        this.loading = false;
      }
    );
    }else{
      let team = this._tourService.getTeamProjectJsonData();
      this.teamData = [];
      this.groupList = team;
        this.searchArr = team;
        this.loading = false;
    }
  }
  isTourActive(){
    if(localStorage.getItem('isTour')){
      if(localStorage.getItem('isTour') == 'false'){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }
  openUserModal(element : ElementRef) {
    this.isTeamModal = true;

    this._sharedService.newTeamModalinstance = M.Modal.init(element);
    this._sharedService.newTeamModalinstance.open();
  }

  handleDataFromTeamMem(data: any) {
    if(!data){
      this.teamData = [];
    }else{
      this.teamData = data;
    }
  }



  searchTeams(val: any) {
    let svalue = val.toUpperCase();
    this.groupList = this.searchArr.filter((ele) => {
      return (ele.title.rendered.toUpperCase().includes(svalue));
    });
  }
}
