import { Injectable } from "@angular/core";
import { map, mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject, Observable, Subscription, observable } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { ProjectService } from "./project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { SharedService } from "./sharedService";
@Injectable({
  providedIn: "root",
})
// class User {
//   id: string;
//   email: string;
//   ssRequest: boolean;
//   scRequestStart: boolean;
//   scRequestStop: boolean;
// }
export class MessagingServiceService {
  usersRef: AngularFireList<any> = null;
  currentMessage = new BehaviorSubject(null);
  public dbPath;
  isLoggedIn: any;
  userList: Observable<any>;
  userDataSubscription: Subscription;
  notificationScription: Subscription;

  constructor(
    private db: AngularFireDatabase,
    private angularFireMessaging: AngularFireMessaging,
    private _projectService: ProjectService,
    private _sharedService: SharedService
  ) {
    // this.usersRef = db.list(this.dbPath);
    _projectService.isFirebaseRefresh.subscribe(value=>{
      this.dbPath = value
    })
    if (localStorage.getItem('dbPath')) {
      this.showNotification();
    }
    // this.angularFireMessaging.onMessage.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )

    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging: any) => {
    //     alert("Hello Service")
    //     console.log("mesaging" , _messaging)
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  ngDestroy() {
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    let tokenData = {
      meta: {
        ss_device_token: token,
      },
    };
    this._projectService
      .SendAccessToken(tokenData, userId)
      .subscribe((data) => {
      });
    // we can change this function to request our backend service
    // WordPress API call
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      alert("hello Re");
      this.currentMessage.next(payload);
    });
  }

  getUserData() {
    //  this.dbPath = localStorage.getItem('dbPath') ? localStorage.getItem('dbPath') : "/users";
    if (localStorage.getItem('dbPath')) {
      this.dbPath = localStorage.getItem('dbPath');
      this.usersRef = this.db.list(localStorage.getItem('dbPath'));
      return new Observable<any>((observer) => {
        this.usersRef.valueChanges().subscribe((res) => {
          observer.next(res);
        });
        // // console.log("new Observable called");
        // this.userDataSubscription = this.usersRef
        //   .snapshotChanges()
        //   .pipe(
        //     map((changes) =>
        //       changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        //     )
        //   )
        //   .subscribe((res: any) => {
        //     if (localStorage.getItem('dbPath')) {
        //       console.log("user ref === snapshot changes", res);
        //       observer.next(res);
        //     }
        //   });
      });
    }else {
      return new Observable<any>();
  }
  }
  showNotification() {
    if (localStorage.getItem('dbPath')) {
    this.notificationScription = this.getUserData().subscribe((res) => {
      this.isLoggedIn = localStorage.getItem("isLogedIn");
      // console.log("in showNotification(): ", res);
      res.forEach((element) => {
        // console.log(element, "user ref === element===")
        // optimize by using same key to pass image data and recognize the type by ss<type> key's status
        if (
          element.ssRequest != false &&
          element.ssRequest != null &&
          element.isreceive == false
        ) {
          let data = {
            appName: element.ssRequest["applicationName"],
            imgURL: element.ssRequest["imgURL"],
            createdAt: element.ssRequest["createdAt"],
            name: element.email,
            type: "Request",
            userName: element.ssRequest["userName"],
            userId: element.ssRequest["userId"],
          };
          if (this.isLoggedIn == "true")
            this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id,'ssRequest');
          // this.db.database
          //   .ref(`${this.dbPath}`)
          //   .child(`${element.id}`)
          //   .update({
          //     ssRequest: false,
          //     isreceive: true,
          //   })
            // .then((res) => {
            //   console.log("set ssreq: false response: ", res);
            // })
            // .catch((err) => {
            //   console.log("set ssreq: false error: ", err);
            // });
        } else if (
          element.ssPeriodic != false &&
          element.ssPeriodic != null &&
          element.isreceive == false
        ) {
          let data = {
            appName: element.ssPeriodic["applicationName"],
            imgURL: element.ssPeriodic["imgURL"],
            createdAt: element.ssPeriodic["createdAt"],
            name: element.email,
            type: "Periodic",
            userName: element.ssPeriodic["userName"],
            userId: element.ssPeriodic["userId"],
          };
          if (this.isLoggedIn == "true")
            this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id,'ssPeriodic');

          // this.db.database
          //   .ref(`${this.dbPath}`)
          //   .child(`${element.id}`)
          //   .update({
          //     ssPeriodic: false,
          //     isreceive: true,
          //   })
          //   .then((res) => {
          //     console.log("set ssPeriodic: false response: ", res);
          //   })
          //   .catch((err) => {
          //     console.log("set ssPeriodic: false error: ", err);
          //   });
        } else if (
          element.ssAppChanged != false &&
          element.ssAppChanged != null &&
          element.isreceive == false
        ) {
          let data = {
            appName: element.ssAppChanged["applicationName"],
            imgURL: element.ssAppChanged["imgURL"],
            createdAt: element.ssAppChanged["createdAt"],
            name: element.email,
            type: "App Changed",
            userName: element.ssAppChanged["userName"],
            userId: element.ssAppChanged["userId"],
          };
          if (this.isLoggedIn == "true")
            this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id,'ssAppChanged');

          // this.db.database
          //   .ref(`${this.dbPath}`)
          //   .child(`${element.id}`)
          //   .update({
          //     ssAppChanged: false,
          //     isreceive: true,
          //   })
          //   .then((res) => {
          //     // console.log("set ssAppChanged: false response: ", res);
          //   })
          //   .catch((err) => {
          //     console.log("set ssAppChanged: false error: ", err);
          //   });
        }
      });
    });
  }else{
    this.resetData();
  }
  }

  resetData() {
    // this.usersRef.remove();
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.notificationScription) {
      this.notificationScription.unsubscribe();
    }
  }
}