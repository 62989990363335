import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrl: './add-email.component.css'
})
export class AddEmailComponent {
  createUserForm: UntypedFormGroup;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

  constructor(){
    this.createUserForm = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
		});
  }
}
