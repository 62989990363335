<div class="delete-container">
    <div class="container-fluid">
        <div class="row delete-row">
            <div class="col s10">
                <h5>Delete</h5>
                <p *ngIf="data && data.type == 'team'">Are you sure you want to delete {{data.name | titlecase}}?</p>
                <p *ngIf="data && data.type != 'team'">Are you sure you want to delete {{data.name | titlecase}}?</p>
            </div>
            <div class="col s2">
                <span (click)="closeDialog()" class="close-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path
                            d="M20.0002 3.33398C10.7835 3.33398 3.3335 10.784 3.3335 20.0007C3.3335 29.2173 10.7835 36.6673 20.0002 36.6673C29.2168 36.6673 36.6668 29.2173 36.6668 20.0007C36.6668 10.784 29.2168 3.33398 20.0002 3.33398ZM28.3335 25.984L25.9835 28.334L20.0002 22.3507L14.0168 28.334L11.6668 25.984L17.6502 20.0007L11.6668 14.0173L14.0168 11.6673L20.0002 17.6507L25.9835 11.6673L28.3335 14.0173L22.3502 20.0007L28.3335 25.984Z"
                            fill="#D9D9D9" />
                    </svg>
                </span>
            </div>
        </div>

        <div class="row btns-row">
            <div class="col s12 btns-contain">



                <button mat-stroked-button class="cancel-btn" (click)="closeDialog()">Cancel
                </button>

                <button mat-raised-button class="delete-btn" (click)="deleteUser()">Delete
                </button>
            </div>
        </div>


    </div>
</div>