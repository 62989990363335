<policy-header *ngIf="!isLogin"/>
<div class="container-fluid">
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1 text-center">Terms & Conditions</h1>
        </div>
    </div>
    <hr>
    <div class="row m-0">
        <div class="col s12">
            <h3 class="my-1">Overview</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                This website is operated by <b class="bold-text">Rao Information Technology</b>. Throughout the website,
                the terms “we”, “us” and
                “our” refer to <b class="bold-text">Rao Information Technology</b>. Rao Information Technology offers
                this website, other
                products and services, including all information, tools and services available from this website and/or
                our other products to you, the user, conditioned upon your acceptance of all terms, conditions, policies
                and notices stated here.
            </p>
            <p class="text my-0-50">
                Throughout the website, the terms “products”, “products/services” and “services” may be used
                interchangeably, which refer to our products, which may or may not also be offered as SAAS
                (Software As A Service). And by visiting/using our website/our products and/or purchasing something from
                us, you engage in one of our “Services” and agree to be bound by the following terms and conditions.
            </p>
            <p class="text my-0-50">
                (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced
                herein and/or available by hyperlink. These Terms of Service apply to all users of all of our products,
                including without limitation users who are browsers, vendors, customers, merchants, and/or contributors
                of content.
            </p>
            <p class="text my-0-50">
                Please read these Terms of Service carefully before accessing or using our website and/or products. By
                accessing or using any part of the website and/or our products, you agree to be bound by these Terms of
                Service. If you do not agree to all the terms and conditions of this agreement, then you may not access
                the website, products or use any services.
            </p>
            <p class="text my-0-50">
                If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of
                Service.
            </p>
            <p class="text my-0-50">
                Any new products, features or tools which are added by us shall also be subject to these Terms of
                Service unless stated otherwise.
            </p>
            <p class="text my-0-50">
                You can review the most current version of the Terms of Service at any time on this page. We reserve the
                right to update, change or replace any part of these Terms of Service by posting updates and/or changes
                to our website. It is your responsibility to check this page periodically for changes. Your continued
                use of or access to the website and/or products following the posting of any changes constitutes
                acceptance of those changes.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Online Store Items</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your
                state or province of residence, or that you are the age of majority in your state or province of
                residence and you have given us your consent to allow any of your minor dependents to use this site.
            </p>
            <p class="text my-0-50">
                You may not use our products for any illegal or unauthorised purpose nor may you, in the use of the
                Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
            </p>
            <p class="text my-0-50">
                You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of
                any of the Terms will result in an immediate termination of your Services.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">General Conditions</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                We reserve the right to refuse service to anyone for any reason at any time. You understand that your
                content (not including credit card information), may be transferred encrypted (“https”) and involve:
            </p>
            <ul>
                <li>transmissions over various networks and changes to conform and adapt to technical requirements of
                    connecting networks or devices.</li>
                <li>Credit card information is always encrypted during transfer over networks.</li>
            </ul>
            <p class="text my-0-50">
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of
                the Service, or access to the Service or any contact on the website and/or products through which the
                service is provided, without express written permission by us.
            </p>
            <p class="text my-0-50">
                The headings used in this agreement are included for convenience only and will not limit or otherwise
                affect these Terms.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                We are not responsible if information made available on this website and/or our other products is not
                accurate, complete or current. The material on this site is provided for general information only and
                should not be relied upon or used as the sole basis for making decisions without consulting primary,
                more accurate, more complete or more timely sources of information.
            </p>
            <p class="text my-0-50">
                Any reliance on the material on this site is at your own risk. This website and/or our other products
                may contain certain historical information. Historical information, necessarily, is not current and is
                provided for your reference only. We reserve the right to modify the contents of this website as well as
                our products at any time, but we have no obligation to update any information on any of our
                products/services including this website. You agree that it is your responsibility to monitor changes to
                our products/services, including this website.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">MODIFICATIONS TO THE SERVICE AND PRICES</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Prices for our products and/or services are subject to change without notice. We reserve the right at
                any time to modify or discontinue the Service (or any part or content thereof) without notice at any
                time.
            </p>
            <p class="text my-0-50">
                We shall not be liable to you or to any third-party for any modification, price change, suspension or
                discontinuance of the Service.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">PRODUCTS OR SERVICES</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Certain products or services may be available exclusively online through the website or our
                applications. These products or services may have limited quantities and are subject to return or
                exchange only according to our Returns Policy.
            </p>
            <p class="text my-0-50">
                We have made every effort to display as accurately as possible all the information including but not
                limited to the images, demos of our products that appear in our store.
            </p>
            <p class="text my-0-50">
                We reserve the right, but are not obligated, to limit the sales of our products or Services to any
                person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.
            </p>
            <p class="text my-0-50">
                We reserve the right to limit the quantities of any products or services that we offer. All descriptions
                of products or product pricing are subject to change at any time without notice, at our sole discretion.
                We reserve the right to discontinue any product or service at any time. Any offer for any product or
                service made on this website as well as on our other products is void where prohibited.
            </p>
            <p class="link my-0-50">
                [We do not warrant that the products, services, information, or other material purchased or obtained by
                you will meet your expectations, or that any errors in the Service will be corrected.]
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">ACCURACY OF BILLING AND ACCOUNT INFORMATION</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or
                cancel quantities purchased per person, per household or per order. These restrictions may include
                orders placed by or under the same customer account, the same credit card, and/or orders that use the
                same billing and/or shipping address.
            </p>
            <p class="text my-0-50">
                In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the
                e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right
                to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers, resellers or
                distributors.
            </p>
            <p class="text my-0-50">
                You agree to provide current, complete and accurate purchase and account information for all purchases
                made at our store. You agree to promptly update your account and other information, including your email
                address and credit card numbers and expiration dates, so that we can complete your transactions and
                contact you as needed.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">OPTIONAL TOOLS</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                We may provide you with access to third-party tools over which we neither monitor nor have any control
                nor input. You acknowledge and agree that we provide access to such tools ”as is” and “as available”
                without any warranties, representations or conditions of any kind and without any endorsement.
            </p>
            <p class="text my-0-50">
                We shall have no liability whatsoever arising from or relating to your use of optional third-party
                tools.
            </p>
            <p class="text my-0-50">
                Any use by you of optional tools offered through this website and/or our products and services, is
                entirely at your own risk and discretion and you should ensure that you are familiar with and approve of
                the terms on which tools are provided by the relevant third-party provider(s).
            </p>
            <p class="text my-0-50">
                We may also, in the future, offer new services and/or features (including, the release of new products,
                tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">THIRD-PARTY LINKS</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Certain content, products and services available via our Service may include materials from
                third-parties. Third-party links on this site may direct you to third-party websites that are not
                affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do
                not warrant and will not have any liability or responsibility for any third-party materials or websites,
                or for any other materials, products, or services of third-parties.
            </p>
            <p class="text my-0-50">
                We are not liable for any harm or damages related to the purchase or use of goods, services, resources,
                content, or any other transactions made in connection with any third-party websites. Please review
                carefully the third-party’s policies and practices and make sure you understand them before you engage
                in any transaction.
            </p>
            <p class="text my-0-50">
                Complaints, claims, concerns, or questions regarding third-party products should be directed to the
                third-party.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                If, at our request, you send certain specific submissions (for example contest entries) or without a
                request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether
                online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any
                time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium
                any comments that you forward to us.
            </p>
            <p class="text my-0-50">
                We are not and shall be under no obligation:
            </p>
            <ul>
                <li>To maintain any comments in confidence,</li>
                <li>To pay compensation for any comments, or </li>
                <li>To respond to any comments.</li>
            </ul>
            <p class="text my-0-50">
                We may, but have no obligation to, monitor, edit or remove content that we determine in our sole
                discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or
                otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
            </p>
            <p class="text my-0-50">
                You agree that your comments will not violate any right of any third-party, including copyright,
                trademark, privacy, personality or other personal or proprietary right. You further agree that your
                comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any
                computer virus or other malware that could in any way affect the operation of the Service or any related
                website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise
                mislead us or third-parties as to the origin of any comments.
            </p>
            <p class="text my-0-50">
                You are solely responsible for any comments you make and their accuracy. We take no responsibility and
                assume no liability for any comments posted by you or any third-party.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">PERSONAL INFORMATION</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Your submission of personal information through the store is governed by our <a class="link" [routerLink]="['/privacy-policy']" >Privacy
                    Policy</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">ERRORS, INACCURACIES AND OMISSIONS</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Occasionally there may be information on our website or in the Service that contains typographical
                errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers,
                product shipping charges, transit times and availability.
            </p>
            <p class="text my-0-50">
                We reserve the right to correct any errors, inaccuracies or omissions, and to change or update
                information or cancel orders if any information in the Service or on ny related website is inaccurate at
                any time without prior notice (including after you have submitted your
                order).
            </p>
            <p class="text my-0-50">
                We undertake no obligation to update, amend or clarify information in the Service or on any related
                website, including without limitation, pricing information, except as required by law. No specified
                update or refresh date applied in the Service or on any related website, should be taken to indicate
                that all information in the Service or on any related website has been modified or updated.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">PROHIBITED USES</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using
                the website, our products/services and/or their content:
            </p>
            <ul>
                <li>For any unlawful purpose.</li>
                <li>To solicit others to perform or participate in any unlawful acts.</li>
                <li>To violate any international, federal, provincial or state regulations, rules, laws, or local
                    ordinances.</li>
                <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of
                    others.</li>
                <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on
                    gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability. </li>
                <li>To submit false or misleading information.</li>
                <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any
                    way that will affect the functionality or operation of the Service or of any related website, other
                    websites, or the Internet. </li>
                <li>To collect or track the personal information of others.</li>
                <li>To spam, phish, pharm, pretext, spider, crawl, or scrape.</li>
                <li>For any obscene or immoral purpose.</li>
                <li>To interfere with or circumvent the security features of any of our Service or any related website,
                    other websites, or the Internet. We reserve the right to terminate your use of that Service or any
                    related website for violating any of the prohibited uses.
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">DISCLAIMER OF WARRANTIES, LIMITATION OF LIABILITY</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                We do not guarantee, represent or warrant that your use of our products/services will be uninterrupted,
                timely or error-free. We do not warrant that the results that may be obtained from the use of the
                products/services will be accurate or reliable.
            </p>
            <p class="text my-0-50">
                You agree that from time to time we may remove the products/services for indefinite periods of time or
                cancel the products/services at any time, without notice to you.
            </p>
        </div>
    </div>
</div>