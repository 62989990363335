<div class="main_section">
    <div class="plan_header_section">
        <mat-card class="system-config-card" *ngIf="isAdmin && isTourActive()">
            <div class="row setting-row">
                <div class="col s8 m6">
                    <h2>Billing and plan</h2>
                </div>
            </div>
            <div class="setting-section system-config">
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col m12 s12 l12" *ngIf="!loading">
                        <quota-banner [closeButton]="true" [isProfile]="true" [isShowButton]="false"></quota-banner>
                    </div>
                    <div class="col m12 s12 l12" *ngIf="loading">
                        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="1"
                            [theme]="{ 'border-radius': '2', height: '80px', width: '100%' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col s12 m4 input-box" *ngIf="!loading">
                        <div class="billing_card1">
                            <div class="billing_card_title">
                                {{ totalUserCurrent }}
                            </div>
                            <div class="billing_card_sub_title">
                                Total users with current plan
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m4 input-box" *ngIf="!loading">
                        <div class="billing_card2">
                            <div class="billing_card_title">
                                {{ useUser }}
                            </div>
                            <div class="billing_card_sub_title">Active users</div>
                        </div>
                    </div>
                    <div class="col s12 m4 input-box" *ngIf="!loading">
                        <div class="billing_card3">
                            <div class="billing_card_title">
                                {{ availableUser }}
                            </div>
                            <div class="billing_card_sub_title">Available users</div>
                        </div>
                    </div>
                    <div class="col s12 m4 input-box" *ngIf="loading">
                        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="1"
                            [theme]="{ 'border-radius': '2', height: '80px', width: '100%' }"></ngx-skeleton-loader>
                    </div>
                    <div class="col s12 m4 input-box" *ngIf="loading">
                        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="1"
                            [theme]="{ 'border-radius': '2', height: '80px', width: '100%' }"></ngx-skeleton-loader>
                    </div>
                    <div class="col s12 m4 input-box" *ngIf="loading">
                        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="1"
                            [theme]="{ 'border-radius': '2', height: '80px', width: '100%' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 14px">
                    <!-- <div class="col s12 m6 input-box">
                </div> -->
                    <div class="col s12 input-box" *ngIf="!loading">
                        <div class="plan_card row" style="margin: 0px;">
                            <div class="plan_card_title col s12 m12" *ngIf="!isAdmin">
                                <p>
                                    {{
                                    quotaStatus == "active" ? "Active until" : "Plan expired"
                                    }}
                                </p>
                                <div class="chips_section">
                                    <span *ngIf="quota_expired_date">
                                        <div class="custom-chip">
                                            {{ quota_expired_date }}
                                        </div>

                                        <!-- <mat-chip class="custom-chip">Custom Chip</mat-chip> -->
                                    </span>
                                    <span *ngIf="currentSelectedPlant != ''">
                                        <div class="plan-type-chip">
                                            {{ currentSelectedPlant }}
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="plan_card_title col s12 m7" *ngIf="isAdmin">
                                <p>
                                    {{
                                    quotaStatus == "active" ? "Active until" : "Plan expired"
                                    }}
                                </p>
                                <div class="chips_section">
                                    <span *ngIf="quota_expired_date">
                                        <div class="custom-chip">
                                            {{ quota_expired_date }}
                                        </div>

                                        <!-- <mat-chip class="custom-chip">Custom Chip</mat-chip> -->
                                    </span>
                                    <span *ngIf="currentSelectedPlant != ''">
                                        <div class="plan-type-chip">
                                            {{ currentSelectedPlant }}
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col s3" *ngIf="currentQuotaStatus == 'active'">
                      <div class="btn_group_trial">
                        <button class="trialBtnIncreaseLimit" [routerLink]="['/billings']">Buy more user</button>
                      </div>
                    </div> -->
                            <!-- <div class="col s3" *ngIf="currentQuotaStatus != 'active'"> -->
                            <!-- <div class="col s12 m5" *ngIf="isAdmin"> -->
                            <div class="col s3 btn_group_trial" *ngIf="isAdmin">
                                <a (click)="applyCouponAction()">Apply coupon?</a>
                            </div>
                            <!-- </div> -->
                            <div class="plan_card_sub_title col s12" *ngIf="!isAdmin">
                                {{
                                quotaStatus == "active"
                                ? "We will send you a notification upon Subscription expiration"
                                : "Please renew your plan as the current one has expired."
                                }}
                            </div>
                            <div class="plan_card_sub_title col s9" *ngIf="isAdmin">
                                {{
                                quotaStatus == "active"
                                ? "We will send you a notification upon Subscription expiration"
                                : "Please renew your plan as the current one has expired."
                                }}
                            </div>
                            <!-- <div class="col s3 btn_group_trial">
                     <a [routerLink]="['/payment-history']">payment history</a>
                   </div> -->
                            <div></div>
                        </div>
                    </div>
                    <div class="col s12 input-box" *ngIf="loading">
                        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="1"
                            [theme]="{ 'border-radius': '2', height: '80px', width: '100%' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="box_section width_loader" *ngIf="loading">
            <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="6"
                [theme]="{ 'border-radius': '2', height: '50px', width: '100%' }"></ngx-skeleton-loader>
        </div>
        <div class="box_section" *ngIf="!loading">
            <div class="content_section">
                <h3>Manage Your Plan</h3>
            </div>
            <!-- THIS IS THE TOGGLE BUTTONS FOR CHANGING USERS QUOTAS. -->
            <div class="row" *ngIf="isAdvancePayment">
                <div class="col s12 plan_header">
                    <button mat-stroked-button class="button_margin monthly-btn {{ moreUserBackground }}"
                        (click)="advancePayAction('add_more')">
                        Increase Quota
                    </button>
                    <button mat-stroked-button class="button_margin monthly-btn {{ payAcdvanceBackground }}"
                        (click)="advancePayAction('pay_advance')">
                        Advance Pay
                    </button>
                </div>
            </div>

            <!-- THIS IS PLAN TIME SELECTION SECTION. -->
            <div class="row plan_selection_section">
                <div class="col s12 m6 btn_section_plan">
                    <div class="btn_track">
                        <button mat-stroked-button class="monthly-btn {{ monthlyBackground }}"
                            (click)="selectPlanAction('monthly')" [disabled]="selected_plan_type == 'addon'"
                            [matTooltip]="
                selected_plan_type == 'addon'
                  ? 'You cannot change your plan type while the current plan is active'
                  : null
              ">
                            Monthly
                        </button>
                        <button mat-stroked-button class="monthly-btn {{ yearlyBackground }}"
                            (click)="selectPlanAction('yearly')" [disabled]="selected_plan_type == 'addon'"
                            [matTooltip]="
                selected_plan_type == 'addon'
                  ? 'You cannot change your plan type while the current plan is active'
                  : null
              ">
                            Annually
                        </button>
                    </div>
                </div>
                <div class="col s12 m6 amount_section_plan">
                    <div class="price_show_section" *ngIf="plan_type == 'Monthly'">
                        <span>₹{{ monthly_price }}</span>
                        <div>
                            <p>Per user</p>
                            <p>Per month</p>
                        </div>
                    </div>
                    <div class="price_show_section" *ngIf="plan_type == 'Yearly'">
                        <span>₹{{ yearly_price }}</span>
                        <div>
                            <p>Per user</p>
                            <p>Per year</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row plan_section_body">
                <div class="col s12 m6 feature_lists">
                    <ul>
                        <li>
                            <i class="material-icons">check</i>
                            <span>Unlimited users and groups</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i>
                            <span>Team & individual dashboard</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i> <span>Silent tracking</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i>
                            <span>Unlimited screenshots</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i> <span>Activity tracking</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i> <span>Live screencast</span>
                        </li>
                        <li>
                            <i class="material-icons">check</i>
                            <span>Personalized dashboard</span>
                        </li>
                    </ul>
                </div>
                <div class="col s12 m6 payment_config">
                    <div class="row payment_inputs">
                        <div class="col s6">
                            <input matInput type="number" [min]="min" #users placeholder="user" [(ngModel)]="user"
                                (keyup)="onKey(users.value)" (change)="onKey(users.value)" />
                        </div>
                        <div class="col s6">
                            <span>₹{{ totalUser }}</span>
                        </div>
                        <div class="col s12">
                            <mat-hint *ngIf="isHint" class="mat-hint">Hint: Minimum {{ min }} user{{ min !== 1 ? "s" :
                                "" }}
                                required
                            </mat-hint>
                        </div>
                    </div>
                    <div class="row payment_label">
                        <div class="col s6">
                            <span># of Users</span>
                        </div>
                        <div class="col s6">
                            <span>Billed {{ plan_type }}</span>
                        </div>
                    </div>
                    <div class="row payment_details">
                        <div class="col s12" style="padding: 0px">
                            <p class="info_line">
                                <img src="../../../assets/icons/crown.svg" alt="" />
                                <span>Access to all SuperSee features</span>
                            </p>
                        </div>
                        <div class="col s6">
                            <span class="user_details_label">{{ strCaption }}</span>
                        </div>
                        <div class="col s6" style="text-align: right">
                            <span class="user_details_label">{{ strDateCption }}</span>
                        </div>
                        <div class="col s6" *ngIf="strSubCaption != ''">
                            <span class="user_details_label">{{ strSubCaption }}</span>
                        </div>
                        <div class="col s6" style="text-align: right" *ngIf="strSubCaption != ''">
                            <span class="user_details_label">{{ strSubDateCaption }}</span>
                        </div>
                    </div>

                    <div class="row border_bottom">
                        <div class="col s12">
                            <label>Billing:</label>
                        </div>
                        <div class="col s12">
                            <span>Amount :
                                <span style="float: right">₹{{ totalUser }}</span></span>
                        </div>
                        <div class="col s12">
                            <span>GST 18% :
                                <span style="float: right">₹{{ calculateTotalPriceWithGST() }}</span></span>
                        </div>
                        <div class="col s12">
                            <span>Total:
                                <span style="float: right">₹{{ calculateTotal() }}</span></span>
                        </div>
                    </div>
                    <div class="row border_bottom" *ngIf="quota_status != 'trial' && quota_status != 'trial-expired'">
                        <div class="col s12">
                            <label>Current plan:</label>
                        </div>
                        <div class="col s4">
                            <span>Plan :
                                <b style="color: #21bc68">{{
                                    current_plan_type | lowercase
                                    }}</b></span>
                        </div>
                        <div class="col s4">
                            <span>Users : <b style="color: #21bc68">{{ quota_allowed }}</b></span>
                        </div>
                        <div class="col s4" style="text-align: right">
                            <span>Valid till:
                                <b style="color: #21bc68">{{
                                    expire_date ? formattedDate(expire_date) : "-"
                                    }}</b></span>
                        </div>
                    </div>

                    <div class="row border_bottom" *ngIf="isToggle">
                        <div class="col s12">
                            <label>New plan:</label>
                        </div>
                        <div class="col s6">
                            <span>Plan :
                                <b style="color: #21bc68">{{ plan_type | lowercase }}</b></span>
                        </div>
                        <div class="col s6" style="text-align: right">
                            <span>Users : <b style="color: #21bc68">{{ user }}</b></span>
                        </div>
                        <!-- <div class="col s4" style="text-align: right;">
                            <span>Valid till: <b style="color: #21BC68;">{{expire_date ? formattedDate(expire_date) : '-'}}</b></span>
                        </div> -->
                    </div>

                    <div class="row txt_gst">
                        <div class="col s10">
                            <span style="color: #9ca09e">Have a GST number?</span>
                        </div>
                        <div class="col s2" style="text-align: right">
                            <mat-checkbox class="example-margin" color="#21BC68" [(ngModel)]="isGST"></mat-checkbox>
                        </div>
                    </div>
                    <div class="row txt_gst" *ngIf="isGST">
                        <div class="col s12 padding-auto">
                            <mat-form-field class="mat-form" appearance="outline">
                                <mat-label>Enter GST number</mat-label>

                                <input matInput type="text" #contact_Val [(ngModel)]="txtGst"
                                    placeholder="Enter your contact number" (blur)="varifyAction()" />
                            </mat-form-field>
                        </div>
                        <!-- <div class="col s2"  style="text-align: right;">
                            <a 
                            (click)="varifyAction($event)" 
                            [ngClass]="{'disabled': txtGst == '' ? true : false}">
                            verify
                         </a>				
                    </div> -->
                    </div>
                    <div class="row">
                        <div class="col s12 btn_section">
                            <button class="buy_now" (click)="sendPaymentRequest()"
                                [disabled]="totalUser == 0 || isButtonDisable || checkGST()">
                                {{ strPlan }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col s12 Plan_section_row ">
                            <button mat-stroked-button class="monthly-btn {{ monthlyBackground }}"
                                (click)="selectPlanAction('monthly')" [disabled]="selected_plan_type == 'addon'"
                                [matTooltip]="selected_plan_type == 'addon' ? 'Tooltip Text' : null">
                                <div class="plan-time">Monthly</div>
                                <div class="price-view">
                                    <span class="pricecard-dollar">₹</span>
                                    <span class="pricecard-price">{{monthly_price}}</span>
                                    <span class="pricecard-time">per user</span>
                                </div>
                            </button>
                            <button mat-stroked-button class="monthly-btn {{ yearlyBackground }}"
                                (click)="selectPlanAction('yearly')" [disabled]="selected_plan_type == 'addon'"
                                [matTooltip]="selected_plan_type == 'addon' ? 'Tooltip Text' : null">
                                <div class="plan-time">Annually</div>

                                <div class="price-view">
                                    <span class="pricecard-dollar">₹</span>
                                    <span class="pricecard-price">{{yearly_price}}</span>
                                    <span class="pricecard-time">per user</span>
                                </div>
                            </button>
                        </div> -->
        </div>

        <div class="contact-us container mt-2">
            <div class="row">
                <div class="col s6 callUs">
                    <h5><a href="tel:+919016310001">Call us: +91 9016310001 </a></h5>
                </div>
                <!-- Create a 'chat on whatsapp' button which redirects to a link -->
                <div class="col s6 contact-wp">
                    <a href="https://api.whatsapp.com/send?phone=916354719703&text=Hi,%20I%20got%20this%20number%20from%20SuperSee%20Dashboard"
                        target="_blank" class="chat-on-whatsapp"><img style="margin-right: 2%;" width="7%"
                            src="./assets/icons/wp-icon.svg"> Chat
                        on
                        WhatsApp</a>
                </div>
            </div>
        </div>
        <!-- <div class="row">
                        <div class=" col s12 user-selection">
                            <h6>Number of users:</h6>
                            <div class="userPlan">
                                <input matInput type="number" [min]="min" #users placeholder="user" [(ngModel)]="user"
                                    (keyup)="onKey(users.value)" (change)="onKey(users.value)">
                            </div>

                        </div>
                        <div class="pricing_section col s12">
                            <h6 class="mr-20">Total:</h6>
                            <h1>&#8377; {{totalUser}}</h1>
                            <h6 class="mr-20">{{strCaption}}</h6>
                        </div>
                    </div> -->
        <!-- <div class="card_body">
                    <div class="feature_lists">
                        <div>
                            <div class="billing-invoice-detail">
                                <table>
                                    <tr>
                                        <td colspan="2" class="section-heading">Plan Detail</td>
                                    </tr>
                                    <tr>
                                        <td class="detail-label">Plan Type:</td>
                                        <td class="detail-value">{{current_plan_type | lowercase}}</td>
                                    </tr>
                                    <tr>
                                        <td class="detail-label">Users:</td>
                                        <td class="detail-value">{{quota_allowed}}</td>
                                    </tr>
                                    <tr>
                                        <td class="detail-label">Expiration Date:</td>
                                        <td class="detail-value">{{expire_date ? formattedDate(expire_date) : '-'}}</td>
                                    </tr>
                                </table>
                            </div>


                        </div>

                    </div>

                </div> -->
    </div>
</div>