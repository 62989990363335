import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as Chart from "chart.js";
import { ProjectService } from "../Services/project.service";
import { ChartToolTipServiceService } from "../Services/chart-tool-tip-service.service";
import { MatDialog } from "@angular/material/dialog";
import { LogsDisplayModalComponent } from "../Admin/logs-display-modal/logs-display-modal.component";
import 'chartjs-plugin-colorschemes';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.css"],
})
export class PieChartComponent implements OnInit {
  @ViewChild("myChart", { static: true })
  myChart: ElementRef<HTMLCanvasElement>;
  @ViewChild('pieChartDiv') pieChartDiv!: ElementRef;
  chart: Chart;
  @Input("data") data;
  @Input("labels") labels;
  @Input("chartData") chartData;
  @Input("display") display;
  @Input('chartType') displayType;
  @Input('_isLabel') _isLabel;
  @Input('colorScheme') chartColotrScheme;
  @Input("viewMore") viewMore;
  @Input('isDashboard') isDashboard

  _chart: Chart;
  toolTipInterval: any;
  customTooltip: any = {};
  pieChartErrNumber: any = 200;
  isdata = false
  @Output() totalHours = new EventEmitter<any>();

  constructor(private renderer: Renderer2, private _projectService: ProjectService, private _chartService: ChartToolTipServiceService, public dialog: MatDialog, public _cd: ChangeDetectorRef) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.createPieChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createPieChart();
  }

  removePlaceholderElements() {
    const placeholders = this.pieChartDiv.nativeElement.querySelectorAll('canvas');
    for (var i = 0; i < placeholders.length; i++) {
      this.renderer.removeChild(this.pieChartDiv.nativeElement, placeholders[i]);
    }
  }

  createPieChart() {
    

    this._cd.detectChanges();
    this.data = this.labels[0] == 'No data Found' ? this.data : this.convertToMilliseconds(this.data);


    if (this.data && this.data.length > 0) {
      this.isdata = true
    } else {
      this.isdata = false
    }
    this.removePlaceholderElements();

    const canvas = this.renderer.createElement("canvas");
    // this.renderer.appendChild(
    //   document.getElementById("pieChartContainer"),
    //   canvas
    // );

    this.renderer.appendChild(
      this.pieChartDiv.nativeElement,
      canvas
    );

    // const valuesArray = [];

    // for (let i = 0; i < this.labels.length; i++) {
    //   const randomValue = Math.floor(Math.random() * 140) + 3; // Generate random value between 3 and 9
    //   valuesArray.push(randomValue);
    // }

    // this.data = []
    const ctx = canvas.getContext("2d");
    this.chart = new Chart(ctx, {
      type: this.displayType,
      data: {
        labels: this.labels.map((item) => item.substring(0,20)),
        // labels: this.labels,
        datasets: [
          {
            data: this.data,
            borderWidth: 0,
            backgroundColor: this.chartColotrScheme
          },

        ],
      },
      options: {
        plugins: {
          colorschemes: {
            backgroundColor: [
              // "#d97979",
              // "#d67898",
              // "#d885e6",
              // "#9a77d9",
              // "#818fdb",
              // "#78acd6",
              // "#b881f7",
              // "#76d7e3",
              // "#80cbc4",
              '#ff6f61', // Red
              '#ffa07a', // Light Salmon
              '#ffcc5c', // Gold
              '#ffeb99', // Pale Goldenrod
              '#98fb98', // Pale Green
              '#00ced1', // Dark Turquoise
              '#87ceeb', // Sky Blue
              '#6495ed', // Cornflower Blue
              '#9370db', // Medium Purple
              '#c71585', // Medium Violet Red
            ]
          }
        },
        responsive: true,
        // events: ["touchstart", "touchmove", "click", "mouseover"],
        tooltips: {
          mode: 'index',
          enabled: true,
          intersect: false,
          // custom: (tooltipModel) => {
          //   if (this.pieChartErrNumber == 200) {
          //     const tooltipEl: any = document.getElementById('chart-tooltip');
          //     if (tooltipEl.style.display = 'none') {
          //       tooltipEl.style.display = 'block';
          //     }
          //     const dataPoints = tooltipModel.dataPoints;
          //     // console.log(dataPoints)
          //     if (dataPoints.length > 0) {
          //       const hoveredIndex = dataPoints[0].index;
          //       // console.log(" hoveredIndex ", hoveredIndex)
          //       // console.log('Data :', dataPoints[0], tooltipModel);
          //       let tool = this.display;
          //       let toolTipModalTitle = tooltipModel.body[0].lines[0].split(":")[0];
          //       let dataindex = dataPoints[0].index;
          //       let displayData = tool[dataindex];
          //       // console.log("display Data : ", displayData, toolTipModalTitle);
          //       this.customTooltip.title = toolTipModalTitle;
          //       this.customTooltip.data = JSON.parse(JSON.stringify(displayData))
          //     }

          //     if (this.toolTipInterval > 0) {
          //       clearInterval(this.toolTipInterval);
          //     }

          //     if (tooltipEl.style.display = 'none') {
          //       tooltipEl.style.display = 'block';
          //       this.toolTipInterval = setInterval(() => {
          //         tooltipEl.style.display = 'none';
          //         console.log("Interval : ", this.toolTipInterval);
          //         clearInterval(this.toolTipInterval);
          //       }, 2500);

          //     }
          //     else {
          //       tooltipEl.style.display = 'block';
          //       this.toolTipInterval = setInterval(() => {
          //         tooltipEl.style.display = 'none';
          //         // tooltipEl.style.opacity = 0;
          //         console.log("Interval : ", this.toolTipInterval);
          //         clearInterval(this.toolTipInterval);
          //       }, 2500);
          //     }

          //     // Set the tooltip position as needed
          //     // You can use tooltipModel.caretX and tooltipModel.caretY to position it
          //     const chartArea = this.chart.chartArea;
          //     const xPosition = tooltipModel.caretX; // X position of the tooltip
          //     const yPosition = tooltipModel.caretY; // Y position of the tooltip

          //     // Adjust the tooltip position as needed
          //     tooltipEl.style.left = (xPosition + chartArea.left) + 'px';
          //     tooltipEl.style.top = (yPosition + chartArea.top) + 'px';
          //   }
          // }
        },
        legend: {
          display: this._isLabel,
          position: "top",
        },
      },

    });

  }



  convertToMilliseconds(timeArr) {
    let timeData = [];
    let totalHours = 0;
    for (let i = 0; i <= timeArr.length - 1; i++) {
      // if (this.isDashboard) {

      //   const timeParts = timeArr[i].split(':');
      //   const hours = parseInt(timeParts[0]);
      //   const minutes = parseInt(timeParts[1]);
      //   const seconds = parseInt(timeParts[2]);
      //   console.log('hours ---- ', hours, typeof hours)
      //   timeData.push(Number(hours.toFixed(2)));
      //   totalHours += hours;
      // } else {
      //   const milliseconds = this.timeToMilliseconds(timeArr[i]);
      //   const hours = this.millisecondsToHours(milliseconds);
      //   console.log('hours ---- ', hours, typeof hours)
      //   timeData.push(Number(hours.toFixed(2)));
      //   totalHours += hours;
      // }

      const milliseconds = this.timeToMilliseconds(timeArr[i]);
      const hours = this.millisecondsToHours(milliseconds);
      timeData.push(Number(hours.toFixed(2)));
      totalHours += hours;

      // timeData.push((hours * 3600 + minutes * 60 + seconds) * 1000);
      // timeData.push(hours);

    }
    this.totalHours.emit(totalHours.toFixed(2).replace(".00", ""));
    // Calculate milliseconds
    return timeData;
  }


  timeToMilliseconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  }

  // Function to convert milliseconds to hours
  millisecondsToHours(milliseconds) {
    const hours = milliseconds / 3600000;
    return hours;
  }

  customChartToolTip(tooltipModel: any) {
  }

  openLogsModal() {
    this.dialog.open(LogsDisplayModalComponent, {
      width: "calc(100vw - 20vw)",
      maxHeight: "calc(100vh - 90px)",
      height: "auto",
      // data: {
      //   user: this.userLog[index] || {},
      //   type: type,
      //   appInfo: infoData,
      //   appName:
      //     this.usedApp[index] && this.usedApp[index].appName
      //       ? this.usedApp[index].appName
      //       : {},
      // },
    });
  }

  handleButtonClick(logs, appName) {
    // Handle button click here
    // Open Application Analysis modal from here

    let type = "appInfo";

    this.dialog.open(LogsDisplayModalComponent, {
      width: type !== "appInfo" ? "1000px" : "calc(100vw - 20vw)",
      maxHeight: "calc(100vh - 90px)",
      height: "auto",
      data: {
        user: logs.logs || {},
        type: type,
        // appInfo: logs.logs[0].sessions,
        appInfo: logs.logs,
        appName: appName
      },
    });

    // alert('Open Your Modal from here');
  }

  displayFormate(time) {
    if (time) {
      if (time.split(":")[0] !== '00') {
        // When some hours
        return time.split(":")[1] !== '00' ? time.split(":")[0] + " h " + time.split(":")[1] + " m" : time.split(":")[0] + "h"
      }
      else if (time.split(":")[1] !== '00') {
        // When only minutes
        return time.split(":")[1] + " m "
      }
      else {
        return "N/A"
      }
    }
    else {
      return
    }
  }

}
