import { Injectable } from '@angular/core';
import { config } from "../config";

function _window(): any {

  return window;

}
@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  constructor() { }

  get nativeWindow(): any {

    return _window();
}

payWithRazor(username,email,contact,order_id,amount,callback_url) {
  var options = {
    "key": config.razor_pay_key, // Enter the Key ID generated from the Dashboard
    // "key": "rzp_test_j5JEtfOo0TdQyg", // Enter the Key ID generated from the Dashboard
    "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Supersee",
    "description": "Test Transaction",
    "image": "https://i.ibb.co/zZ11XS2/image.png",
    "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "callback_url": callback_url,
    "prefill": {
        "name": username,
        "email": email,
        "contact": contact
    },
    "notes": {
        "address": "Rao information technology Office"
    },
    "theme": {
        "color": "#FEE139"
    }
  };
    
  
      const rzp = new this.nativeWindow.Razorpay(options);
  
      rzp.open();
  
    }
}
